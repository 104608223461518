<template>
  <div>
    <div class="breadcrumb_header">
      <div class="breadcrumb ms-2">
        <h4 style="margin: 0; padding-left: 10px">NeXa</h4>
        <span class="divider"></span>
        <h6 class="breadcrumb-text">
          Use Cases / Deep Research / {{ this.$route.params.id }} / Detail /
          {{ this.$route.params.docId }}
        </h6>
      </div>
      <div>
        <button @click="toggleSidebar" class="open_sidebar me-2">
          <span class="material-symbols-rounded icon"
            >keyboard_double_arrow_left</span
          >
          <p>Open Sidebar</p>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1 mt-3 p-0">
        <div
          class="d-flex justify-content-end cursor-pointer back"
          @click="goBack"
        >
          <span class="material-symbols-rounded" style="margin: 0"
            >arrow_back</span
          >
          <span style="margin: 0 0 0 8px">Back</span>
        </div>
      </div>
      <div class="col-md-10 mt-5 p-0">
        <PageLoader
          class="mt-5"
          v-if="loading"
          :loading="loading"
          :extra_msg="loading_message"
          :completedPercentage="duration"
        />
        <div v-else>
          <section
            class="claims-container"
            v-for="q in questions"
            :key="`questions-${q.id}`"
            :id="`q-${q.id}`"
          >
            <FollowUpQuestionBox :q="q" />
          </section>
          <main class="content mt-3">
            <section class="answer-section">
              <div class="answer-header mt-2">
                <div class="tab d-flex gap-2 align-items-center">
                  <h6 class="answer-title">
                    Full Summary for document {{ documentBrief.name }}
                  </h6>
                </div>
                <div class="buttons">
                  <button class="button" @click="copy">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/a5fc1aca50cb553e4e813d1e66a4f62c328c94fc60417f9c7fb438b58331a1a1?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
                      alt="Copy Icon"
                      class="icon"
                    />
                    <span class="button-title">{{
                      isCopied ? "Copied" : "Copy"
                    }}</span>
                  </button>
                  <b-dropdown
                    size="sm"
                    id="dropdown-right"
                    right
                    offset="-9"
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                    @click="toggleDropdown"
                  >
                    <template #button-content>
                      <button class="action-btn export-btn">
                        <span class="button-label">Export</span>
                        <img
                          :class="{ 'rotate-180': isDropdown }"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/d12668b437286b44ce443817869f8a85013f194276d09cf30b58481266006963?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
                          alt="Export Icon"
                          class="icon"
                        />
                      </button>
                    </template>

                    <b-dropdown-item class="ms-1 me-1" @click="exportToPDF">
                      <img class="me-2" src="@/assets/icon/pdf-icon.svg" />
                      <button class="dropdown-text">Export as PDF</button>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </section>
            <article class="mt-5 marked-style">
              <vue-marked>{{ values.summary }}</vue-marked>
            </article>
            <!-- <section class="intro mt-4">
              <div>
                <h6 class="title">Document {{ this.$route.params.docId }}:</h6>
                <h6>Case Summary</h6>
              </div>

              <b-container class="bv-summary-row mb-3 mt-4">
                <b-row cols="2">
                  <b-col class="question mb-3"
                    >Case Title<br />
                    <p class="answer-text">Smith v. Johnson Contract Dispute</p>
                  </b-col>
                  <b-col class="question mb-3"
                    >Case Number
                    <p class="answer-text">2023-CV-145</p>
                  </b-col>
                  <b-col class="question mb-3"
                    >Court
                    <p class="answer-text">
                      Superior Court of California, Los Angeles County
                    </p>
                  </b-col>
                  <b-col class="question mb-3"
                    >Presiding Judge
                    <p class="answer-text">Hon. Susan Taylor</p>
                  </b-col>
                  <b-col class="question mb-3"
                    >Plaintiff
                    <p class="answer-text">John Smith</p>
                  </b-col>
                  <b-col class="question mb-3"
                    >Defendant
                    <p class="answer-text">Mary Johnson</p>
                  </b-col>
                </b-row>
              </b-container>
              <hr class="separator" />
            </section>

            <div class="tabs">
              <h6 class="title">Key Points</h6>
              <div class="tab d-flex gap-2 align-items-center">
                <h6 class="question mt-3">Legal Issues</h6>
              </div>
              <div class="pt-2">
                <ul>
                  <li class="mb-2">
                    Did the force majeure clause in the contract excuse
                    Johnson’s delayed performance?
                  </li>
                  <li>
                    Did the force majeure clause in the contract excuse
                    Johnson’s delayed performance?
                  </li>
                </ul>

                <div>
                  <h6 class="question mt-4 mb-3">Factual Disputes</h6>
                  <p class="answer-text mt-1">
                    Dispute:<span class="ms-2" style="color: #383a3e !important"
                      >When did Johnson notify Smith of the delivery
                      delay?</span
                    >
                  </p>
                  <p class="answer-text mt-1">
                    Suggestion Action:<span
                      class="ms-2"
                      style="color: #383a3e !important"
                      >Further discovery might be needed to clarify the timeline
                      and any communication gaps. Look into email or phone
                      records to establish whether the delay notice was provided
                      within a reasonable timeframe.</span
                    >
                  </p>
                  <p class="answer-text mt-1">
                    Dispute:<span class="ms-2" style="color: #383a3e !important"
                      >When did Johnson notify Smith of the delivery
                      delay?</span
                    >
                  </p>
                  <p class="answer-text mt-1">
                    Suggestion Action:<span
                      class="ms-2"
                      style="color: #383a3e !important"
                      >Further discovery might be needed to clarify the timeline
                      and any communication gaps. Look into email or phone
                      records to establish whether the delay notice was provided
                      within a reasonable timeframe.</span
                    >
                  </p>
                </div>

                <div>
                  <h6 class="question mt-4 mb-3">Key Evidence</h6>
                  <p class="answer-text mt-1">
                    Dispute:<span class="ms-2" style="color: #383a3e !important"
                      >When did Johnson notify Smith of the delivery
                      delay?</span
                    >
                  </p>
                  <p class="answer-text mt-1">
                    Suggestion Action:<span
                      class="ms-2"
                      style="color: black#383A3E !important"
                      >Further discovery might be needed to clarify the timeline
                      and any communication gaps. Look into email or phone
                      records to establish whether the delay notice was provided
                      within a reasonable timeframe.</span
                    >
                  </p>
                  <p class="answer-text mt-1">
                    Dispute:<span class="ms-2" style="color: #383a3e !important"
                      >When did Johnson notify Smith of the delivery
                      delay?</span
                    >
                  </p>
                  <p class="answer-text mt-1">
                    Suggestion Action:<span
                      class="ms-2"
                      style="color: #383a3e !important"
                      >Further discovery might be needed to clarify the timeline
                      and any communication gaps. Look into email or phone
                      records to establish whether the delay notice was provided
                      within a reasonable timeframe.</span
                    >
                  </p>
                </div>
              </div>
            </div> -->
          </main>
        </div>
        <!-- {{ files }} -->
      </div>
      <div class="col-md-1"></div>
    </div>
    <div v-if="!loading" class="wrapper-chatbox">
      <ChatBox @chat-submit="submitQuery" />
    </div>
    <sidebar
      v-clickaway="toggleSidebar"
      v-if="sidebarCollapsed"
      @toggle-sidebar="toggleSidebar"
      :uploadFiles="files"
    ></sidebar>
  </div>
</template>
<script>
// import { sanitizeHTML } from "../../../store/utils";
import ChatBox from "../../../components/input/ChatBox.vue";
import countryImages from "../../../assets/country/index";
import nexa from "@/store/nexa.js";
import sidebar from "../sidebar.vue";
import usecase from "@/store/usecase.js";
import { ExportDataPDF } from "@/store/utils";
import VueMarked from "vue-marked";
import PageLoader from "../../PageLoader.vue";
import FollowUpQuestionBox from "../../../components/input/FollowUpQuestionBox.vue";
export default {
  components: {
    PageLoader,
    sidebar,
    VueMarked,
    ChatBox,
    FollowUpQuestionBox,
  },
  data() {
    return {
      loading_message: "Please wait a moment while we process it.",
      loading: true,
      duration: 0,
      interval: null,
      sidebarCollapsed: false,
      lawbotVisible: false,
      isCountry: false,
      showSummary: false,
      countryImages,
      values: {},
      isCopied: false,
      isDropdown: false,
      documentBrief: {},
      questions: [],
    };
  },
  methods: {
    toggleDropdown() {
      this.isDropdown = !this.isDropdown;
    },
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    goBack() {
      this.$router.push({ name: "DeepResearchResult" });
    },
    copy() {
      const contentElement = document.querySelector("article").innerText;

      navigator.clipboard
        .writeText(contentElement)
        .then(() => {
          this.$toast.success("Copied as plain text successfully!");
          this.isCopied = true;
        })
        .catch(() => {
          this.$toast.error("Error copying to clipboard");
        })
        .finally(() => {
          setTimeout(() => {
            this.isCopied = false;
          }, 2000);
        });
    },

    exportToPDF() {
      try {
        this.$nextTick(() => {
          const followUpQuestion = `<h1 style="font-size: 24px; font-weight: bold;">Deep Research: ${this.$route.params.id} - Summarized Document : ${this.$route.params.docId}</h1><br><hr>`;
          const tempDiv = document.createElement("div");

          // Style the temporary div
          tempDiv.style.width = "65%";
          tempDiv.style.maxWidth = "800px";
          tempDiv.style.margin = "20px auto";
          tempDiv.style.padding = "20px";
          tempDiv.style.border = "1px solid #ccc";
          tempDiv.style.borderRadius = "10px";
          tempDiv.style.backgroundColor = "#f9f9f9";
          tempDiv.style.boxShadow = "0 2px 10px rgba(0, 0, 0, 0.1)";
          tempDiv.style.fontFamily = "Arial, sans-serif";

          const contentElement = document.querySelector("article").innerHTML;
          if (!contentElement) {
            this.$toast.error("Content not found");
            return;
          }

          tempDiv.innerHTML = followUpQuestion + contentElement;

          document.body.appendChild(tempDiv);

          ExportDataPDF(
            tempDiv.innerHTML,
            false,
            `Deep Research: ${this.$route.params.id} - Summarized Document : ${this.$route.params.docId}`
          );

          this.$toast.success("Successfully downloaded PDF");

          document.body.removeChild(tempDiv);
        });
      } catch (err) {
        this.$toast.error("Error exporting to PDF");
        console.error(err);
      }
    },
    async refetchAnswers() {
      if (this.isFetching) return;
      this.isFetching = true;
      const checkAllAnswers = () =>
        this.questions.every((q) => q.answer !== null);

      while (!checkAllAnswers()) {
        const updatedQuestions = await Promise.all(
          this.questions.map(async (element) => {
            if (element.answer == null) {
              try {
                const response = await usecase.GetDocuments(
                  this.$route.params.docId ?? ""
                );

                const updatedQuestion = response.data.data.questions.find(
                  (q) => q.id === element.id
                  // console.log("ID", element.id)
                );

                if (updatedQuestion && updatedQuestion.answer !== null) {
                  return {
                    ...element,
                    answer: updatedQuestion.answer,
                  };
                }
                // console.log("Element DATA HERE 1:", element);
                return element;
              } catch (error) {
                // console.error("Error fetching result:", error);
                return element;
              }
            }
            // console.log("Element DATA HERE 2:", element);
            return element;
          })
        );

        this.questions = updatedQuestions;
        // console.log("Questions", this.questions);

        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      this.isFetching = false;
    },
    submitQuery(question) {
      const formData = new FormData();
      formData.append("entity_type", "document");
      formData.append("entity_id", this.$route.params.docId);
      formData.append("language", "en");
      formData.append("location_id", 1);
      formData.append("question", question);
      formData.append("jurisdiction", this.wholeJuris);
      this.blockUser = true;
      nexa
        .DetailAsk(formData)
        .then((response) => {
          const responseData = response.data?.data;
          const responseQuestions = responseData?.questions;

          if (responseQuestions && Array.isArray(responseQuestions)) {
            responseQuestions.forEach((q) => {
              const existing = this.questions.find(
                (existing) => existing.id === q.id
              );
              if (existing) {
                existing.answer = q.answer;
              } else {
                this.questions.push(q);
              }
            });

            this.refetchAnswers();

            if (response.data.completedPercentage === 100) {
              this.blockUser = false;
            }
          } else {
            console.error(
              "Error: responseQuestions is undefined or not an array",
              response
            );
          }
        })
        .catch((error) => {
          console.error("Error submitting query:", error);
          this.blockUser = false;
        });
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  created() {
    usecase.GetDocuments(this.$route.params.docId ?? "").then((response) => {
      try {
        const data = response.data.data;
        this.duration = data.summarizeCompletePerCentage;
        this.wholeJuris = data.jurisdiction;
        this.values = JSON.parse(data.values[0].value).output;
        this.questions = data.questions;
        this.loading = false;

        if (this.questions.length > 0) {
          const hasNullValue = this.questions.some((q) => q.answer === null);

          if (hasNullValue) {
            this.refetchAnswers();
          }
        }
      } catch (error) {
        this.$toast.error("An error occurred while fetching the document");
      }
    });
  },
};
</script>

<style scoped>
.claims-container {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  display: flex;
  flex-direction: column;
  font: 400 14px/1.5 Poppins, sans-serif;
  padding: 22px 24px;
  margin-bottom: 2rem;
  transition: height 0.3s ease;
  cursor: pointer;
}

.marked-style ::v-deep(div > * > strong) {
  font-weight: 600 !important;
}

.marked-style ::v-deep(div > * > b) {
  font-weight: 600 !important;
}

.marked-style ::v-deep(div h1 *) {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
  font-weight: 600 !important;
}

.marked-style ::v-deep(div h2 *) {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
  font-weight: 600 !important;
}

.marked-style ::v-deep(div h3 *) {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  font-weight: 600 !important;
}

.marked-style ::v-deep(div p *) {
  font-size: 1rem !important;
  line-height: 1.75rem !important;
  font-weight: bold !important;
  color: #374151 !important;
}

.marked-style ::v-deep(div a *) {
  color: #3b82f6 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}

.marked-style ::v-deep(div ul *) {
  font-size: inherit !important;
  font-weight: bold !important;
}

.marked-style ::v-deep(div ol *) {
  font-size: inherit !important;
  font-weight: bold !important;
}

.marked-style ::v-deep(div li *) {
  font-size: inherit !important;
  font-weight: bold !important;
}

.marked-style ::v-deep(div blockquote strong) {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  font-weight: bold !important;
  color: #6b7280 !important;
}

.marked-style ::v-deep(div pre strong) {
  font-size: inherit !important;
  font-weight: bold !important;
  color: #d97706 !important;
}

.marked-style ::v-deep(div code strong) {
  font-size: 0.875rem !important;
  font-weight: bold !important;
  background-color: #f3f4f6 !important;
  color: #d97706 !important;
}

.marked-style ::v-deep(div h1) {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
  font-weight: 800 !important;
  margin-bottom: 1.5rem !important;
}

.marked-style ::v-deep(div h2) {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
  font-weight: 700 !important;
  margin-bottom: 1.25rem !important;
}

.marked-style ::v-deep(div h3) {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  font-weight: 700 !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div p) {
  font-size: 1rem !important;
  line-height: 1.75rem !important;
  margin-bottom: 1rem !important;
  color: #374151 !important;
}

.marked-style ::v-deep(div a) {
  color: #3b82f6 !important;
  text-decoration: underline !important;
}

.marked-style ::v-deep(div ul) {
  list-style-type: disc !important;
  margin-left: 1.25rem !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div ol) {
  list-style-type: decimal !important;
  margin-left: 1.25rem !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div li) {
  margin-bottom: 0.5rem !important;
}

.marked-style ::v-deep(div blockquote) {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  color: #6b7280 !important;
  border-left: 4px solid #d1d5db !important;
  padding-left: 1rem !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div pre) {
  background-color: #f3f4f6 !important;
  padding: 1rem !important;
  border-radius: 0.375rem !important;
  overflow-x: auto !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div code) {
  background-color: #f3f4f6 !important;
  padding: 0.25rem !important;
  border-radius: 0.25rem !important;
  font-size: 0.875rem !important;
  color: #d97706 !important;
}

.action-buttons {
  display: flex;
  align-items: center;
  gap: 16px;
}

.action-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  background: none;
}

.copy-btn {
  color: #0e4485;
}

.export-btn {
  background: #0e4485;
  color: #fff;
}

.page-content {
  padding: 0px;
  overflow-x: hidden;
}

.overlay-container {
  width: 25%;
  position: absolute;
  top: 8.7vh;
  right: 0;
  z-index: 1000;
  height: 100vh;
}

.breadcrumb_header {
  display: flex;
  justify-content: space-between;
  height: 68px;
  background: #fff;
  /* padding: 16px 20px; */
  align-items: center;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin: auto 0;
}
.breadcrumb-text {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.open_sidebar {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 4px;
  background: var(--Primary-Blue, #0e4485);
}

.open_sidebar span {
  color: #fff;
  display: flex;
  /* Ensure icon is aligned vertically */
  align-items: center;
  /* Align icon vertically within span */
}

.open_sidebar p {
  color: #fff !important;
  margin: 0;
  /* Remove any default margin to align the text properly */
  line-height: 1;
  /* Ensure text has the same line height as the icon */
  display: flex;
  align-items: center;
  /* Align text vertically */
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}

.back {
  color: var(--primary) !important;
  font-weight: 500;
}
</style>
<style scoped>
.content {
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  box-shadow: 0 2px 4px 0 rgba(170, 171, 175, 0.6);
  display: flex;
  flex-direction: column;
  padding: 22px 24px;
}

@media (max-width: 991px) {
  .content {
    padding: 0 20px;
  }
}

.intro,
.question-section,
.answer-section,
.discussion,
.doctorine,
.citations,
.legal-references {
  max-width: 100%;
}

.quillEditor .ql-toolbar.ql-snow {
  border-radius: 8px 0px !important;
  border-bottom: 1px solid #d1d2d5 !important;
  background: #f2f3f3 !important;
}

.legal-references {
  margin-bottom: 5rem;
}

.title {
  color: var(--Primary-Blue, #0e4485) !important;
  font: 500 16px/28px Poppins, sans-serif;
}

.separator {
  background: var(--mid-grey);
  height: 1px;
  width: 100%;
}

.question-section .tabs,
.answer-section .answer-header,
.legal-references {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: 25px;
}

.result-tabs {
  display: flex;
  width: 100%;
  background: var(--Neutral-Light-Grey, #f2f3f3);
  align-items: center;
}

.question .button,
.actions .button,
.tab {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  border-radius: 4px;
}

h6.title.answer-title.case-title {
  color: var(--Primary-Blue, #0e4485) !important;
  margin: 0px;
}

.img {
  aspect-ratio: 1/1;
  width: 18px;
  object-fit: contain;
}

.question-description,
.answer-text {
  margin-top: 4px;
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font: 400 14px/24px Poppins, sans-serif;
}

.doctorine-text {
  color: var(--Neutral-Dark-Grey, #86888d);

  /* Button */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.text-link {
  color: var(--Status-Blue, #1890ff);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
}

.answer-title,
.reference-title {
  color: var(--Primary-Blue, #0e4485) !important;
  font-size: 16px;
  line-height: 28px;
}

.actions .button {
  border: 1px solid var(--Neutral-Mid-Grey, #d1d2d5);
  background: var(--Neutral-White, #fff);
  color: var(--Primary-Dark-Blue, #000046) !important;
}

.actions .button-primary {
  background: var(--Primary-Blue, #0e4485);
  color: var(--Neutral-White, #fff) !important;
}

.actions .button,
.actions .button-primary {
  padding: 2px 8px;
}

.details .detail {
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font-size: 14px;
}

.detail-title {
  color: var(--Primary-Blue, #0e4485) !important;
  font-size: 16px;
}

.detail-text {
  margin-top: 4px;
  color: var(--Neutral-Dark-Grey, #86888d) !important;
}

.link {
  color: var(--Status-Blue, #1890ff) !important;
}

.results {
  background: var(--Neutral-Interface-Grey, #fafbfc);
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  border-radius: 8px;
}

.result-tabs .tab {
  background: var(--Neutral-Light-Grey, #f2f3f3);
  color: var(--Neutral-Dark-Grey, #86888d);
  padding: 8px 16px;
  font-weight: 500;
  border-radius: 0px !important;
}

.result-tabs .tab.active {
  border-bottom: 2px solid var(--Primary-Blue, #0e4485);
  color: var(--Primary-Blue, #0e4485) !important;
}

.case-result {
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  padding: 20px 16px;
  margin-top: 20px;
}

.case-title {
  font-size: 16px;
  color: var(--Neutral-Black, #383a3e) !important;
  font-weight: 500;
}

.case-details .meta {
  display: flex;
  align-items: center;
}

.case-details .meta img {
  margin-right: 4px;
}

.case-overview,
.case-analysis p {
  font-size: 14px;
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  line-height: 24px;
}

.relevancy {
  display: flex;
  align-items: center;
  color: var(--Status-Green, #52c41a) !important;
}

.relevancy img {
  margin-right: 4px;
}

.actions {
  display: flex;
  gap: 2px;
}
</style>

<style scoped>
.follow-up-question {
  justify-content: center;
  border-radius: 16px;
  border: 1px solid var(--Primary-Blue, #0e4485);
  background: var(--Neutral-White, #fff);
  box-shadow: 0 2px 10px 0 rgba(14, 68, 133, 0.6);
  z-index: 10;
  display: flex;
  width: 74%;
  gap: 8px;
  flex-wrap: wrap;
  padding: 12px 20px;
  align-items: center;
  position: fixed;
  bottom: 20px;
}

.input-field {
  align-self: stretch;
  min-width: 240px;
  flex: 1;
  padding: 10px 16px 10px 0;
  font: 400 16px Poppins, sans-serif;
  border: none;
  outline: none;
  background: transparent;
}

.follow-up-actions {
  display: flex;
  padding-right: 20px;
  align-items: center;
  justify-content: start;
  height: 100%;
  width: 56px;
}

.submit-button {
  border-radius: 4px;
  align-self: stretch;
  display: flex;
  width: 36px;
  align-items: center;
  justify-content: center;
  margin: auto 0;
  padding: 6px;
  background: none;
  border: none;
  cursor: pointer;
}

.submit-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  align-self: stretch;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@media (max-width: 991px) {
  .follow-up-question,
  .input-field {
    max-width: 100%;
  }
}
</style>
