<template>
  <div class="follow-up-questions">
    <!-- <button
      class="follow-up-button"
      @click="toggleModal"
      v-if="showModal == false"
    >
      <div class="follow-up-button-text">
        <svg
          style="width: 18px; height: 18px"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="lucide lucide-picture-in-picture-2"
        >
          <path d="M21 9V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10c0 1.1.9 2 2 2h4" />
          <rect width="10" height="7" x="12" y="13" rx="2" />
        </svg>
        <span> Prompt Library </span>
      </div> -->

    <!-- <div
        v-clickaway="toggleModal"
        v-if="showModal == true"
        style="
          position: absolute;
          bottom: 120%;
          left: 0;
          width: auto;
          height: auto;
          background-color: #606060;
          border-radius: 20px;
          overflow: hidden;
          opacity: 90%;
        "
        @click="closeModal"
      >
        <ul style="width: 32rem; list-style-type: none; margin: 0; padding: 0">
          <li
            v-for="(question, index) in questions"
            :key="index"
            role="menuitem"
            tabindex="-1"
            class="popover-item"
            @click="handleQuestionClick(question)"
          >
            {{ question }}
          </li>
        </ul>
      </div> -->
    <!-- </button> -->
    <ul
      style="
        display: flex;
        flex-direction: row;
        font-size: 12px;
        flex-wrap: wrap;
        row-gap: 8px;
        column-gap: 4px;
        width: 100%;
        list-style-type: none;
        padding: 0;
        margin: 0;
      "
    >
      <li
        class="list-items"
        v-for="(question, index) in questions"
        :key="index"
        role="menuitem"
        tabindex="-1"
        @click="handleQuestionClick(question)"
      >
        {{ question }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    questions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    handleQuestionClick(question) {
      this.$emit("question-selected", question);
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

<style>
.list-items {
  display: flex;

  border-radius: 6px;
  font-size: 12px;
  padding: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border: 1px solid #c0c0c0;
  color: #1990ff;
}

.list-items:hover {
  background-color: #dedede;
}

.follow-up-button-text {
  display: flex;
  gap: 4px;
  border-radius: 6px;
  font-size: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  color: #9f9f9f;
}
.follow-up-button {
  position: relative;
}

.follow-up-button:hover {
  color: black;
}

.follow-up-button-text:hover {
  background-color: #efefef;
  color: black;
}

.popover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items to the top */
  z-index: 1000;
}

.popover-content {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%; /* Set width to 100% */
  max-width: 300px; /* Set max width to 300px */
  height: 25px; /* Set height to 25px */
  padding: 8px; /* Adjust padding as needed */
  position: absolute; /* Position relative to the button */
  top: calc(100% + 8px); /* Position above the button with some margin */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust for centering */
  animation: popIn 0.3s ease;
  display: none; /* Initially hidden */
}

.popover-overlay.show .popover-content {
  display: block; /* Show the popover when needed */
}

.popover-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popover-title {
  font-size: 18px;
  font-weight: bold;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #888;
}

.popover-body {
  padding: 8px 0;
}

.separator {
  height: 1px;
  background-color: #e0e0e0;
  margin: 8px 0;
}

.popover-item {
  color: white;
  text-align: left;
  font-size: 12px;
  padding: 14px 18px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.popover-item:hover {
  background-color: #8f8f8f;
}

@keyframes popIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
