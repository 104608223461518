<template>
  <div>
    <div class="breadcrumb_header">
      <div class="breadcrumb ms-2">
        <h4 style="margin: 0; padding-left: 10px">NeXa</h4>
        <span class="divider"></span>
        <h6 class="breadcrumb-text">
          Use Cases / Summarize a Case / {{ this.$route.params.id }}
        </h6>
      </div>
      <div>
        <button @click="toggleSidebar" class="open_sidebar me-2">
          <span class="material-symbols-rounded icon"
            >keyboard_double_arrow_left</span
          >
          <p>Open Sidebar</p>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1 mt-3 p-0">
        <div
          class="d-flex justify-content-end cursor-pointer back"
          @click="goBack"
        >
          <span class="material-symbols-rounded" style="margin: 0"
            >arrow_back</span
          >
          <span style="margin: 0 0 0 8px">Back</span>
        </div>
      </div>
      <div class="col-md-10 mt-5 p-0">
        <PageLoader v-if="loading" />
        <!-- Add v-else in div below -->

        <div class="case-summary-container" v-else>
          <section
            class="claims-container"
            v-for="q in questions"
            :key="`questions-${q.id}`"
            :id="`q-${q.id}`"
          >
            <FollowUpQuestionBox :q="q" />
          </section>
          <section class="file-upload-status">
            <h2 class="status-title">Uploaded {{ files.length }} Files</h2>
            <button class="view-files-button" @click="toggleSidebar">
              View Uploaded Files
            </button>
            <div class="security-notice">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/ee5e1527866344adfafc18849c89c62dd76f2b9c7ded99add1ae660ece66da12?placeholderIfAbsent=true&apiKey=411e2ec61bdb45ecb3af3338badbc5df"
                alt="Security icon"
                class="security-icon"
              />

              <p class="security-text" style="margin: 0; padding: 0">
                Your files will be securely stored in NeXa, accessible only to
                you and your workspace admins, and you can delete them at any
                time.
              </p>
            </div>
          </section>

          <main class="case-details-container">
            <header class="case-header">
              <div class="case-title-section">
                <h1 class="case-title">Summarize a Case</h1>
              </div>
              <div class="action-buttons">
                <button
                  class="action-button copy-button"
                  tabindex="0"
                  @click="copy"
                >
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/b44c86e6cccfd119bd0ae151720d5bcb372679b6fd25fe58afd18b9b6a9484b5?placeholderIfAbsent=true&apiKey=411e2ec61bdb45ecb3af3338badbc5df"
                    alt="Copy icon"
                    class="button-icon"
                  />
                  <span>{{ isCopied ? "Copied" : "Copy" }}</span>
                </button>
                <b-dropdown
                  size="sm"
                  id="dropdown-right"
                  right
                  offset="-9"
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  @click="toggleDropdown"
                >
                  <template #button-content>
                    <button class="action-button export-button">
                      <span class="button-label">Export</span>
                      <img
                        :class="{ 'rotate-180': isDropdown }"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/d12668b437286b44ce443817869f8a85013f194276d09cf30b58481266006963?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
                        alt="Export Icon"
                        class="icon"
                      />
                    </button>
                  </template>

                  <b-dropdown-item class="ms-1 me-1" @click="exportToPDF">
                    <img class="me-2" src="@/assets/icon/pdf-icon.svg" />
                    <span class="dropdown-text">Export as PDF</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </header>

            <section class="document-section">
              <!-- <div class="document-header">
                <h2 class="document-title">Document 1:</h2>
                <span class="document-name">Statement_of_Claim.pdf</span>
              </div> -->

              <h3 class="document-name">Case Info</h3>

              <div class="info-grid">
                <div class="info-card">
                  <div class="info-item">
                    <h4 class="info-label">Case Title</h4>
                    <p class="info-value">{{ basic_details.case_title }}</p>
                  </div>
                  <div class="info-item">
                    <h4 class="info-label">Case Number</h4>
                    <p class="info-value">{{ basic_details.case_number }}</p>
                  </div>
                </div>

                <div class="info-card">
                  <div class="info-item">
                    <h4 class="info-label">Court</h4>
                    <p class="info-value">
                      {{ basic_details.court }}
                    </p>
                  </div>
                  <!-- <div class="info-item">
                    <h4 class="info-label">Presiding Judge</h4>
                    <p class="info-value">Hon. Susan Taylor</p>
                  </div> -->
                </div>

                <div class="info-card">
                  <div class="info-item">
                    <h4 class="info-label">Plaintiff</h4>
                    <p class="info-value">{{ basic_details.petitioner }}</p>
                  </div>
                  <div class="info-item">
                    <h4 class="info-label">Defendant</h4>
                    <p class="info-value">{{ basic_details.respondent }}</p>
                  </div>
                </div>
              </div>
            </section>

            <hr class="section-divider" />

            <article id="content">
              <section class="summary-section">
                <h3 class="section-title">Brief Summary</h3>
                <article class="summary-text">
                  <vue-marked>{{ brief_summary }}</vue-marked>
                </article>
              </section>

              <hr class="section-divider" />

              <section class="summary-section">
                <h3 class="section-title">Evidences</h3>
                <article class="summary-text">
                  <vue-marked>{{ evidences }}</vue-marked>
                </article>
              </section>

              <hr class="section-divider" />
              <section class="summary-section">
                <h3 class="section-title">Key Points :</h3>
                <ol class="summary-text">
                  <li v-for="key_point in key_points" :key="key_point">
                    {{ key_point }}
                  </li>
                </ol>
              </section>

              <hr class="section-divider" />

              <section class="summary-section">
                <h3 class="section-title">Legal Issues :</h3>
                <article class="summary-text">
                  <vue-marked>{{ legal_issues }}</vue-marked>
                </article>
              </section>

              <hr class="section-divider" />

              <section class="summary-section">
                <!-- <h3 class="section-title">Ruling :</h3> -->
                <article class="summary-text">
                  <vue-marked>{{ ruling }}</vue-marked>
                </article>
              </section>
            </article>
          </main>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>
    <div v-if="!loading" class="wrapper-chatbox">
      <ChatBox @chat-submit="submitQuery" />
    </div>
    <sidebar
      v-clickaway="toggleSidebar"
      v-if="sidebarCollapsed"
      @toggle-sidebar="toggleSidebar"
      :uploadFiles="files"
    ></sidebar>
  </div>
</template>
<script>
import countryImages from "../../../assets/country/index";
import PageLoader from "../../PageLoader.vue";
import sidebar from "../sidebar.vue";
import usecase from "@/store/usecase.js";
import { ExportDataPDF } from "@/store/utils";
import VueMarked from "vue-marked";
import ChatBox from "../../../components/input/ChatBox.vue";
import FollowUpQuestionBox from "../../../components/input/FollowUpQuestionBox.vue";
import nexa from "@/store/nexa.js";
export default {
  components: {
    sidebar,
    VueMarked,
    PageLoader,
    ChatBox,
    FollowUpQuestionBox,
  },
  data() {
    return {
      loading: true,
      duration: -1,
      interval: null,
      sidebarCollapsed: false,
      lawbotVisible: false,
      isCopied: false,
      isDropdown: false,
      showSummary: false,
      countryImages,
      files: [],
      values: {},
      documentBrief: {},
      basic_details: {},
      brief_summary: "",
      evidences: "",
      key_points: [],
      legal_issues: "",
      ruling: "",
      questions: [],
      wholeJuris: {},
    };
  },
  methods: {
    toggleDropdown() {
      this.isDropdown = !this.isDropdown;
    },
    toggleSummary() {
      this.showSummary = !this.showSummary;
    },
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    goBack() {
      this.$router.push({ name: "SummarizeCase" });
    },
    copy() {
      const contentElement = document.querySelector("#content").innerText;

      navigator.clipboard
        .writeText(contentElement)
        .then(() => {
          this.$toast.success("Copied as plain text successfully!");
          this.isCopied = true;
        })
        .catch(() => {
          this.$toast.error("Error copying to clipboard");
        })
        .finally(() => {
          setTimeout(() => {
            this.isCopied = false;
          }, 2000);
        });
    },

    exportToPDF() {
      try {
        this.$nextTick(() => {
          const followUpQuestion = `<h1 style="font-size: 24px; font-weight: bold;">Summarize a Case: ${this.$route.params.id} </h1><br><hr>`;
          const tempDiv = document.createElement("div");

          // Style the temporary div
          tempDiv.style.width = "65%";
          tempDiv.style.maxWidth = "800px";
          tempDiv.style.margin = "20px auto";
          tempDiv.style.padding = "20px";
          tempDiv.style.border = "1px solid #ccc";
          tempDiv.style.borderRadius = "10px";
          tempDiv.style.backgroundColor = "#f9f9f9";
          tempDiv.style.boxShadow = "0 2px 10px rgba(0, 0, 0, 0.1)";
          tempDiv.style.fontFamily = "Arial, sans-serif";

          const contentElement = document.querySelector("#content").innerHTML;
          if (!contentElement) {
            this.$toast.error("Content not found");
            return;
          }

          tempDiv.innerHTML = followUpQuestion + contentElement;

          document.body.appendChild(tempDiv);

          ExportDataPDF(
            tempDiv.innerHTML,
            false,
            `Summarize a Case: ${this.$route.params.id} `
          );

          this.$toast.success("Successfully downloaded PDF");

          document.body.removeChild(tempDiv);
        });
      } catch (err) {
        this.$toast.error("Error exporting to PDF");
        console.error(err);
      }
    },
    async refetchAnswers() {
      if (this.isFetching) return;
      this.isFetching = true;

      const checkAllAnswers = () =>
        this.questions.slice(1).every((q) => q.answer !== null); // Skip the first question

      while (!checkAllAnswers()) {
        const updatedQuestions = await Promise.all(
          this.questions.map(async (element, index) => {
            if (index === 0) return element; // Skip the first question

            if (element.answer == null) {
              try {
                const response = await usecase.GetResult(
                  this.$route.params.id ?? ""
                );

                const updatedQuestion = response.data.data.questions.find(
                  (q) => q.id === element.id
                );

                if (updatedQuestion && updatedQuestion.answer !== null) {
                  return {
                    ...element,
                    answer: updatedQuestion.answer,
                  };
                }
                return element;
              } catch (error) {
                return element;
              }
            }
            return element;
          })
        );

        this.questions = updatedQuestions;

        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      this.isFetching = false;
    },
    submitQuery(question) {
      const formData = new FormData();
      formData.append("entity_type", "campaign");
      formData.append("entity_id", this.$route.params.id);
      formData.append("language", "en");
      formData.append("location_id", 1);
      formData.append("question", question);
      formData.append("jurisdiction", JSON.stringify(this.wholeJuris));
      this.blockUser = true;

      nexa
        .DetailAsk(formData)
        .then((response) => {
          const responseData = response.data?.data;
          const responseQuestions = responseData?.questions;

          if (responseQuestions && Array.isArray(responseQuestions)) {
            // Skip the first question (index 0)
            responseQuestions.slice(1).forEach((q) => {
              // Start from index 1
              const existing = this.questions.find(
                (existing) => existing.id === q.id
              );
              if (existing) {
                existing.answer = q.answer; // Update existing question's answer
              } else {
                this.questions.push(q); // Add new question to the list
              }
            });

            this.refetchAnswers();

            if (response.data.completedPercentage === 100) {
              this.blockUser = false;
            }
          } else {
            console.error(
              "Error: responseQuestions is undefined or not an array",
              response
            );
          }
        })
        .catch((error) => {
          console.error("Error submitting query:", error);
          this.blockUser = false;
        });
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  created() {
    usecase.GetResult(this.$route.params.id ?? "").then((response) => {
      const DATA = response.data.data;
      if (DATA.completedPerCentage >= 100) {
        this.completedPerCentage = DATA.completedPerCentage;
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }

      if (DATA.completedPerCentage < 100) {
        if (DATA.completedPerCentage >= 0) {
          this.completedPerCentage = DATA.completedPerCentage;
          this.files = DATA.questionDocuments;
        }

        this.interval = setInterval(() => {
          usecase.GetResult(this.$route.params.id).then((res) => {
            if (res.data.data.completedPerCentage >= 100) {
              this.completedPerCentage = res.data.data.completedPerCentage;
              clearInterval(this.interval);
              location.reload();
            } else if (res.data.data.completedPerCentage > 0) {
              this.completedPerCentage = res.data.data.completedPerCentage;
            }
          });
        }, 10000);
      } else {
        console.log(JSON.parse(DATA.values[0].value));
        const {
          basic_details,
          brief_summary,
          evidences,
          key_points,
          legal_issues,
          ruling,
        } = JSON.parse(DATA.values[0].value);
        this.basic_details = basic_details[0];
        this.brief_summary = brief_summary;
        this.evidences = evidences;
        this.key_points = key_points;
        this.legal_issues = legal_issues;
        this.ruling = ruling;
        this.files = DATA.questionDocuments;
        if (DATA.questions.length > 0) {
          this.questions = DATA.questions;
        }

        this.wholeJuris[DATA.jurisdiction] = ["all"];

        this.questions = DATA.questions || [];

        const hasNullValue = this.questions.some((q) => q.answer === null);

        if (hasNullValue) {
          this.refetchAnswers();
        }

        // this.collapsedQuestions = this.questions.map((_, index) => index);
      }
    });
  },
};
</script>

<style scoped>
.claims-container {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  display: flex;
  flex-direction: column;
  font: 400 14px/1.5 Poppins, sans-serif;
  padding: 22px 24px;
  margin-bottom: 2rem;
  transition: height 0.3s ease;
  cursor: pointer;
}
.marked-style ::v-deep(div > * > strong) {
  font-weight: 600 !important;
}

.marked-style ::v-deep(div > * > b) {
  font-weight: 600 !important;
}

.marked-style ::v-deep(div h1 *) {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
  font-weight: 600 !important;
}

.marked-style ::v-deep(div h2 *) {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
  font-weight: 600 !important;
}

.marked-style ::v-deep(div h3 *) {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  font-weight: 600 !important;
}

.marked-style ::v-deep(div p *) {
  font-size: 1rem !important;
  line-height: 1.75rem !important;
  font-weight: bold !important;
  color: #374151 !important;
}

.marked-style ::v-deep(div a *) {
  color: #3b82f6 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}

.marked-style ::v-deep(div ul *) {
  font-size: inherit !important;
  font-weight: bold !important;
}

.marked-style ::v-deep(div ol *) {
  font-size: inherit !important;
  font-weight: bold !important;
}

.marked-style ::v-deep(div li *) {
  font-size: inherit !important;
  font-weight: bold !important;
}

.marked-style ::v-deep(div blockquote strong) {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  font-weight: bold !important;
  color: #6b7280 !important;
}

.marked-style ::v-deep(div pre strong) {
  font-size: inherit !important;
  font-weight: bold !important;
  color: #d97706 !important;
}

.marked-style ::v-deep(div code strong) {
  font-size: 0.875rem !important;
  font-weight: bold !important;
  background-color: #f3f4f6 !important;
  color: #d97706 !important;
}

.marked-style ::v-deep(div h1) {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
  font-weight: 800 !important;
  margin-bottom: 1.5rem !important;
}

.marked-style ::v-deep(div h2) {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
  font-weight: 700 !important;
  margin-bottom: 1.25rem !important;
}

.marked-style ::v-deep(div h3) {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  font-weight: 700 !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div p) {
  font-size: 1rem !important;
  line-height: 1.75rem !important;
  margin-bottom: 1rem !important;
  color: #374151 !important;
}

.marked-style ::v-deep(div a) {
  color: #3b82f6 !important;
  text-decoration: underline !important;
}

.marked-style ::v-deep(div ul) {
  list-style-type: disc !important;
  margin-left: 1.25rem !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div ol) {
  list-style-type: decimal !important;
  margin-left: 1.25rem !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div li) {
  margin-bottom: 0.5rem !important;
}

.marked-style ::v-deep(div blockquote) {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  color: #6b7280 !important;
  border-left: 4px solid #d1d5db !important;
  padding-left: 1rem !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div pre) {
  background-color: #f3f4f6 !important;
  padding: 1rem !important;
  border-radius: 0.375rem !important;
  overflow-x: auto !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div code) {
  background-color: #f3f4f6 !important;
  padding: 0.25rem !important;
  border-radius: 0.25rem !important;
  font-size: 0.875rem !important;
  color: #d97706 !important;
}

.action-buttons {
  display: flex;
  align-items: center;
  gap: 16px;
}

.action-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  background: none;
}

.copy-btn {
  color: #0e4485;
}

.export-btn {
  background: #0e4485;
  color: #fff;
}

.page-content {
  padding: 0px;
  overflow-x: hidden;
}

.overlay-container {
  width: 25%;
  position: absolute;
  top: 8.7vh;
  right: 0;
  z-index: 1000;
  height: 100vh;
}

.breadcrumb_header {
  display: flex;
  justify-content: space-between;
  height: 68px;
  background: #fff;
  /* padding: 16px 20px; */
  align-items: center;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin: auto 0;
}
.breadcrumb-text {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.open_sidebar {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 4px;
  background: var(--Primary-Blue, #0e4485);
}

.open_sidebar span {
  color: #fff;
  display: flex;
  /* Ensure icon is aligned vertically */
  align-items: center;
  /* Align icon vertically within span */
}

.open_sidebar p {
  color: #fff !important;
  margin: 0;
  /* Remove any default margin to align the text properly */
  line-height: 1;
  /* Ensure text has the same line height as the icon */
  display: flex;
  align-items: center;
  /* Align text vertically */
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}

.back {
  color: var(--primary) !important;
  font-weight: 500;
}
.case-summary-container {
  background: var(--Neutral-Interface-Grey, #fafbfc);
  font-family: Poppins, sans-serif;
}

.file-upload-status {
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  padding: 16px 20px;
}

.status-title {
  font-size: 20px;
  color: var(--Neutral-Black, #383a3e);
  font-weight: 600;
  line-height: 1.4;
}

.view-files-button {
  border-radius: 4px;
  background: var(--Neutral-Light-Grey, #f2f3f3);
  color: var(--Status-Blue, #1890ff);
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  padding: 2px 8px;
  margin-top: 16px;
  border: none;
  cursor: pointer;
}

.security-notice {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 16px;
}

.security-icon {
  width: 18px;
  height: 18px;
}

.security-text {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 12px;
  line-height: 24px;
}

.case-details-container {
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  box-shadow: 0 2px 4px rgba(170, 171, 175, 0.6);
  margin-top: 32px;
  padding: 22px 24px;
}

.case-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}

.case-title {
  color: var(--Primary-Blue, #0e4485);
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

.action-buttons {
  display: flex;
  gap: 16px;
}

.action-button {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  cursor: pointer;
}

.copy-button {
  color: var(--Primary-Blue, #0e4485);
  background: transparent;
}

.export-button {
  color: var(--Neutral-White, #fff);
  background: var(--Primary-Blue, #0e4485);
}

.button-icon {
  width: 18px;
  height: 18px;
}

.document-section {
  margin-top: 32px;
}

.document-header {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

.document-title {
  color: var(--Primary-Blue, #0e4485);
}

.document-name {
  color: var(--Neutral-Black, #383a3e);
}

.section-title {
  color: var(--Neutral-Black, #383a3e);
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  margin-top: 16px;
}

.info-grid {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.info-card {
  display: flex;
  gap: 10px;
  padding: 4px 0;
}

.info-item {
  flex: 1;
  min-width: 240px;
}

.info-label {
  color: var(--Neutral-Black, #383a3e);
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

.info-value {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 14px;
  line-height: 24px;
  margin-top: 4px;
}

.section-divider {
  border: none;
  border-top: 1px solid var(--Neutral-Mid-Grey, #d1d2d5);
  margin: 32px 0;
}

.summary-text {
  color: var(--Neutral-Black, #383a3e);
  font-size: 14px;
  line-height: 24px;
  margin-top: 8px;
}

.subsection-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 8px;
}

.issues-list {
  list-style: none;
  padding: 0;
  margin: 8px 0;
  color: var(--Neutral-Black, #383a3e);
  font-size: 14px;
  line-height: 24px;
}

.issues-list li {
  margin-bottom: 8px;
}

.dispute-item {
  margin-top: 16px;
}

.dispute-question,
.dispute-action {
  margin-bottom: 8px;
}

.label-text {
  color: var(--Neutral-Dark-Grey, #86888d);
}

.value-note {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 12px;
  margin-top: 16px;
}

@media (max-width: 991px) {
  .case-details-container {
    padding: 20px;
  }

  .case-header {
    flex-wrap: wrap;
  }

  .info-card {
    flex-wrap: wrap;
  }

  .info-item {
    flex-basis: 100%;
  }
}
</style>
