<template>
  <div @click="hideAllDropdown">
    
    <div>
      <div class="case_result">
        <div class="result">
          <BreadCrumb
            title="Legal AI Trial Copilot"
            subtitle="Pre-trial Input"
            icon="gavel"
          />
          <div class="container mt-4">
            <div class="card p-4">
              <p class="mb-4 title">Post-trial Submission</p>
              <p>Upload Final Trial Document</p>
              <p class="pt">If applicable, else proceed with the document</p>
              <div class="mt-4">
                <FileInput v-if="trial_file.length === 0" class="input" :accept="['pdf']" @input-files="c_files = $event" />
                <FileProcess class="process" :accept="['pdf']" :files="c_files" @output-files="handleFileOutput" />
              </div>
              <div style="display: flex; justify-content: flex-end;">
                <button class="btn ogt me-3 mt-4 d-flex align-items-center">
                    <span class="material-symbols-rounded me-2">arrow_forward</span>{{ "Next" }}
                </button>
            </div>
            </div>
          </div>
        </div>
       <!-- LawBot SideBar -->
      <div>
        <LawBot/>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from '../../components/misc/BreadCrumb.vue';
import FileInput from '../../components/input/FileInput.vue';
import FileProcess from '../../components/input/FileProcess.vue';
import LawBot from '../../components/LawBot.vue';
export default {
  components: {
    BreadCrumb,FileProcess,FileInput,LawBot
  },
  data() { 
    return {
    c_files: [],
    trial_file:[]
    };
  },
  methods:{
    hideAllDropdown() { this.$root.$emit('bv::hide::tooltip'); },
    handleFileOutput(outputFiles) {
      this.trial_file = outputFiles;
    },
  }
}
</script>

<style scoped>
[custom-class=court-modal] .modal-body {
  padding: 0px;
}
h3, p {
  margin-bottom: 0px;
}

#Header {
  margin: -20px -20px 0px -20px;
  padding: 16px 20px;
  height: 72px;
  display: flex;
  align-items: center;
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}

.page-content {
  padding: 0 !important;
}

.case_result {
  display: flex;
  justify-content: space-between;
  .result {
    width: 97%;
    padding: 20px !important;
    .container{
        max-width: 880px;
    }
    }
    .lawbot {
        width: 3%;
        box-shadow: 1px 1px 1px 1px #AAABAF;
    }
    .lawbot_toggle{
        display: flex;
        flex-direction: column;
    }
    .icon{
        background: #0E4485;
        height: 64px;
        display: flex; 
        justify-content: center;
        align-items: center;
      }
    span{
        width: 22px;
        height: 19px;
        color: #FFFFFF;
    }
}
.title{
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: 36px;
}
p{
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  }
.pt{
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--dark-grey);
  }
  .ogt{
  background: #0E44851A;
  border-radius: 4px;
  padding: 4px 16px;
  width: 100px;
  color: var(--mid-grey);
  span{
    color:var(--mid-grey);
  }
  &:hover{
    color: #FFFFFF;
    background: var(--primary);
  }
}
</style>