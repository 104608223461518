import axios from "@/axios.js";
import Vue from "vue";
/* eslint-disable */
export default {
  loginJWT(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("login", payload)

        .then((response) => {
          if (
            response.data.message ==
            "You email is not verified, please check your email and verify it"
          ) {
            const vm = new Vue();
            vm.$toast.warning(
              "Your email is not verified! Please check your email and verify it."
            );
          } else if (response.data.message == "You are unactivated user!") {
            const vm = new Vue();
            vm.$toast.error(response.data.message);
          } else {
            localStorage.setItem(
              "accessToken",
              response.data.data.access_token
            );

            //set token globally once login
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${response.data.data.access_token}`;

            // If there's user data in response
            if (response.data.status) {
              //if response true
              // Navigate User to homepage
              axios
                .get("me")
                .then((res) => {
                  console.log("res DATA", res);
                  localStorage.setItem(
                    "userInfo",
                    JSON.stringify(res.data.data)
                  );
                  localStorage.setItem(
                    "permissions",
                    JSON.stringify(res.data.data.permissions)
                  );

                  const thisUser = res.data.data;

                  let role = "editor";

                  Object.assign(thisUser, { userRole: role });
                  if (
                    JSON.parse(localStorage.userInfo).not_have_plan_yet !==
                      "1" &&
                    JSON.parse(localStorage.userInfo).not_have_plan_yet !== 1
                  ) {
                    axios
                      .get("locations")
                      .then((res) => {
                        var locations = [];
                        res.data.data.forEach((element, index) => {
                          // if (element.name == "www.google.com.my") {
                          //     locations.push(element)
                          // }
                          // else if (element.name == "www.google.com.au") {
                          //     locations.push(element)
                          // }
                          // else if (element.name == "www.google.com.sg") {
                          //     locations.push(element)
                          // }
                          locations.push(element);
                        });

                        localStorage.setItem(
                          "locations",
                          JSON.stringify(locations)
                        );
                      })
                      .catch((error) => {
                        reject(error);
                      });
                  }
                })
                .catch((error) => {
                  reject(error);
                });

              // var keywordList = []
              // for (var count = 1; count < 5; count++) {
              //     axios.get('keywordpools?&page=' + count)
              //         .then(res => {

              //             res.data.data.data.forEach((element) => {

              //                 keywordList.push(element);
              //             });
              //             localStorage.setItem('keyword_pool', JSON.stringify(keywordList))

              //         })
              //         .catch(error => {
              //         })
              // }

              resolve(response);
            } else {
              reject({ message: "Incorrect Email or Password!" });
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setClientInfo(item) {
    return new Promise((resolve, reject) => {
      axios
        .post(`signup/set-client-information`, item)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  signupNoCard(item) {
    return new Promise((resolve, reject) => {
      axios
        .post(`signup/subscripe-without-card`, item)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  allNexlaw() {
    return new Promise((resolve, reject) => {
      axios
        .get(`nexlaw?page=1`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  allNexlawPage(obj) {
    return new Promise((resolve, reject) => {
      axios
        .get(`nexlaw` + obj)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getMe() {
    return new Promise((resolve, reject) => {
      axios
        .get("me")
        .then((response) => {
          //
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  forgetPassword(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("Forgot-Password", { email: payload.email })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updatePassword(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("Update-Password", {
          token: payload.token,
          password: payload.password,
          password_confirmation: payload.password_confirmation,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  destroyAccessToken() {
    // //
    return new Promise((resolve, reject) => {
      // //
      axios
        .post("logout", { access_token: localStorage.getItem("accessToken") })
        .then((response) => {
          resolve(response);
          //
        })
        .catch((error) => {
          reject(error);
          //
        });
    });
  },

  updateProfile(item) {
    return new Promise((resolve, reject) => {
      axios
        .put("Change-My-Profile", item)
        .then((response) => {
          resolve(response);

          axios
            .get("me")
            .then((res) => {
              localStorage.setItem("userInfo", JSON.stringify(res.data.data));
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  changePassword(item) {
    return new Promise((resolve, reject) => {
      axios
        .put("Change-My-Password", item)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
