<template>
  <!-- LawBot SideBar -->
  <div>
    <div class="sidebar">
      <div class="top-icon">
        <span class="material-symbols-rounded">smart_toy</span>
      </div>
      <button class="collapse-btn" @click="toggleSidebar">
        <span class="material-symbols-rounded">keyboard_double_arrow_left</span>
      </button>
    </div>

    <!-- Normal Mode -->
    <transition name="slide" v-if="!expertmode">
      <div v-if="isSidebarVisible" class="normal">
        <div class="d-flex justify-content-between top_bar">
          <div class="d-flex align-items-center lb_left">
            <span class="material-symbols-rounded">smart_toy</span>
            <p>LawBot</p>
          </div>
          <div></div>
        </div>

        <!-- {{ chatMessages }} -->

        <div class="main-content">
          <!-- left side -->
          <div class="left_side">
            <div class="d-flex justify-content-between ls">
              <p>Recent Conversations</p>
              <span class="material-symbols-rounded">border_color</span>
            </div>
            <div v-if="chatMessages.length === 0" class="chat-placeholder">
              <img :src="fileImg" alt="" style="width: 80px; height: 80px" />
              <p>
                Chat history will show up here once you start a conversation.
              </p>
            </div>
            <div v-else class="recent-conversations pb-5">
              <!-- RESET TO DEFAULT FOR DEBUG -->
              <div v-if="messagesToday.length > 0" class="mb-3">
                <p class="history-text">Today</p>
                <div
                  v-for="(message, idx) in messagesToday"
                  :key="idx"
                  class="message-item"
                >
                  <div class="message-header">
                    <p>{{ truncateText(message.text, 27) }}</p>

                    <!-- <span
                      class="material-symbols-rounded delete-icon"
                      @click="$bvModal.show(`alert-modal-${idx}`)"
                      >delete</span
                    > -->
                    <b-modal
                      :id="`alert-modal-${idx}`"
                      custom-class="delete-modal"
                      hide-header
                      hide-footer
                      centered
                    >
                      <div class="delete-prompt">
                        <p>
                          <b>Are you sure you want to delete this conversation?</b
                          ><br />
                        </p>
                        <p class="bg">This action cannot be undone.</p>
                      </div>
                      <div class="delete-prompt-options">
                        <button
                          class="btn-outlined"
                          @click="$bvModal.hide(`alert-modal-${idx}`)"
                        >
                          Cancel
                        </button>
                        <button class="btn-danger" @click="deleteMessage(idx)">
                          Delete
                        </button>
                      </div>
                    </b-modal>
                  </div>
                  <div class="message-subheader">
                    <p>From {{ campaign }}</p>
                  </div>
                </div>
              </div>

              <!-- Disable for Debug -->
              <div v-if="messagesYesterday.length > 0" class="mb-3">
                <p class="history-text">Yesterday</p>
                <div
                  v-for="(message, idx) in messagesYesterday"
                  :key="idx"
                  class="message-item"
                >
                  <div class="message-header">
                    <p>{{ truncateText(message.text, 27) }}</p>

                    <b-modal
                      :id="`alert-modal-${idx}`"
                      custom-class="delete-modal"
                      hide-header
                      hide-footer
                      centered
                    >
                      <div class="delete-prompt">
                        <p>
                          <b>Are you sure you want to delete this conversation?</b
                          ><br />
                        </p>
                        <p class="bg">This action cannot be undone.</p>
                      </div>
                      <div class="delete-prompt-options">
                        <button
                          class="btn-outlined"
                          @click="$bvModal.hide(`alert-modal-${idx}`)"
                        >
                          Cancel
                        </button>
                        <button class="btn-danger" @click="deleteMessage(idx)">
                          Delete
                        </button>
                      </div>
                    </b-modal>
                  </div>
                  <div class="message-subheader">
                    <p>From {{ campaign }}</p>
                  </div>
                </div>
              </div>

              <div v-if="messagesLast7Days.length > 0" class="mb-5">
                <p class="history-text">Previous 7 Days</p>
                <div
                  v-for="(message, idx) in messagesLast7Days"
                  :key="idx"
                  class="message-item"
                >
                  <div class="message-header">
                    <p>{{ truncateText(message.text, 27) }}</p>

                    <b-modal
                      :id="`alert-modal-${idx}`"
                      custom-class="delete-modal"
                      hide-header
                      hide-footer
                      centered
                    >
                      <div class="delete-prompt">
                        <p>
                          <b>Are you sure you want to delete this conversation?</b
                          ><br />
                        </p>
                        <p class="bg">This action cannot be undone.</p>
                      </div>
                      <div class="delete-prompt-options">
                        <button
                          class="btn-outlined"
                          @click="$bvModal.hide(`alert-modal-${idx}`)"
                        >
                          Cancel
                        </button>
                        <button class="btn-danger" @click="deleteMessage(idx)">
                          Delete
                        </button>
                      </div>
                    </b-modal>
                  </div>
                  <div class="message-subheader">
                    <p>From {{ campaign }}</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <button class="collapse-btns" @click="toggleSidebar">
            <span class="material-symbols-rounded"
              >keyboard_double_arrow_right</span
            >
          </button>
          <!-- right side -->
          <div class="content-area">
            <div class="card border-0" style="overflow-y: auto; height: 100%">
              <div class="no-questions" v-if="!chatMessages.length">
                <img :src="chat" alt="" style="width: 180px; height: 180px" />
                <p>No questions asked yet!</p>
                <p>Start by selecting a sample question to begin.</p> 
              </div>
              <div v-else class="chat__box" id="messages" ref="messages1">
                <!-- Incoming Chat box -->

                <div
                  v-for="(message, idx) in chatMessages"
                  :key="idx"
                  :class="message.class_1"
                >
                  <div class="d-flex align-items-start ms-3 me-2">
                    <span
                      v-if="idx % 2 !== 0"
                      class="material-symbols-rounded st_icon"
                      >smart_toy</span
                    >
                    <div :class="message.class_1">
                      <div :class="message.class_3">
                        <span v-html="marked.parse(message.text)"></span>
                        <!-- Copy and Regenerate Button -->
                         <div v-if=" message.class_1 === 'chat__incoming'" class="d-flex justify-content-start">
                            <button class="icon-button" @click="copyAnswer(message.text)">
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/072fca6f880e47769db5f7634dd8a8c6/9362a8923d32ca8bc5c04a2b7240493a0b95be121fbf3951ec46c906c790bfd2?apiKey=072fca6f880e47769db5f7634dd8a8c6&amp;"
                              />
                            </button>
                            <button class="icon-button" @click="regenerateAnswer(chatMessages, idx)">
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/072fca6f880e47769db5f7634dd8a8c6/eae4d16b1ac11d42038051a950c3e42d5b06c6a5a93ae7facbb44db102a392ba?apiKey=072fca6f880e47769db5f7634dd8a8c6&amp;"
                              />
                            </button>
                         </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
                <!-- Outgoing chat box -->
                <div class="chat__incoming ms-3" v-if="loadAnswer">
                  <div class="d-flex align-items-start">
                    <span
                      class="material-symbols-rounded st_icon"
                      style="color: white"
                      >smart_toy</span
                    >
                    <div class="chat__bubble__wrapper-incoming">
                      <div
                        class="chat__bubble-incoming animated-background-incoming"
                      >
                        <b-spinner
                          class="ms-1 me-2"
                          variant="primary"
                          label="Nexa Thinking"
                          style="
                            width: 1.2rem;
                            height: 1.2rem;
                            border-width: 0.125rem;
                          "
                        ></b-spinner>
                        <span>Lawbot is thinking... </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-start m-3">
                <b-dropdown ref="questionDropdown" @hide="toggleQuestionsFalse" size="lg" variant="link" toggle-class="text-decoration-none p-0" no-caret>
                  <template #button-content>
                    <div @click="toggleQuestions">
                      <button class="sample-questions-btn">
                        <span class="dot"></span>
                        <span style="color: #1890FF;">Sample questions</span>
                        <span style="color: #1890FF;" class="material-symbols-rounded">
                          {{
                            showQuestions ? "keyboard_arrow_down" : "keyboard_arrow_up"
                          }}
                        </span>
                      </button>
                    </div>
                  </template>
                  <div style="width: 278px; padding: 8px;"> 
                    <button
                      v-for="(question, index) in default_questions.slice(0, 2)"
                      :key="index"
                      @click="chooseMessage(question, index)"
                      :disabled="loadAnswer || question.displayed"
                      class="prompt_css"
                    >
                      {{ question.text }}
                    </button>
                  </div>
                </b-dropdown>
              </div>


              <!-- Sample Questions Section -->
              <div
                class="sample-questions-container"
                @click="toggleQuestions"
                id="prompts"
              >
              </div>
              <b-tooltip
                target="prompts"
                triggers="click"
                placement="top"
                custom-class="DROPDOWN-MENU"
              >
                <div>
                  <button
                    v-for="(question, index) in default_questions.slice(0, 2)"
                    :key="index"
                    @click="chooseMessage(question, index)"
                    :disabled="loadAnswer || question.displayed"
                    class="prompt_css"
                  >
                    {{ question.text }}
                  </button>
                </div>
              </b-tooltip>

                <!-- Input Container -->
                <div class="input-container">
                  <input
                    type="text"
                    v-show="NewText.length > 0"
                    :disabled="loadAnswer"
                    placeholder="Ask LawBot"
                    class="input-field"
                    v-model="NewText"
                    @keyup.enter="sendNewMessage"
                  />
                  <span
                    class="material-symbols-rounded nr_send"
                    @click="sendNewMessage"
                    >send</span
                  >
                </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import fileImg from "../assets/copilot/file.png";
import chat from "../assets/copilot/chat.png";
import star from "../assets/copilot/Vector.svg";
import marked from "marked";
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    loadAnswer: {
      type: Boolean,
      required: true,
      default: false,
    },
    campaign: {
      type: String,
      required: true,
    },
    chatMessages: {
      type: Array,
      required: true,
    },
    default_questions: {
      type: Array,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      isSidebarVisible: false,
      fileImg,
      chat,
      star,
      expertmode: false,
      showModal: false,
      files: [],
      trial_file: [],
      NewText: "",
      showQuestions: false,
      history: [],

      options: [
        {
          icon: "folder_open",
          label: "View Files",
        },
        {
          icon: "archive",
          label: "Archive",
        },
        {
          icon: "delete",
          label: "Delete",
        },
      ],
    };
  },
  created() {
    this.marked = marked;
  },

  computed: {
    filteredMessages() {
      return this.chatMessages.filter((_, idx) => idx % 2 === 0);
    },
    messagesToday() {
      return this.filteredMessages.filter((message) => {
        const today = new Date();
        const msgDate = new Date(message.dateCreated);
        return (
          msgDate.toDateString() === today.toDateString() // Match today's date
        );
      });
    },
    messagesYesterday() {
      return this.filteredMessages.filter((message) => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1); // Subtract 1 day
        const msgDate = new Date(message.dateCreated);
        return msgDate.toDateString() === yesterday.toDateString();
      });
    },
    messagesLast7Days() {
      return this.filteredMessages.filter((message) => {
        const today = new Date();
        const msgDate = new Date(message.dateCreated);

        const diffTime = today - msgDate; // Time difference in milliseconds
        const diffDays = diffTime / (1000 * 60 * 60 * 24); // Convert to days

        return diffDays > 1 && diffDays <= 7; // Between 2 and 7 days old
      });
    },
  },
  methods: {
    copyAnswer(text) {
      navigator.clipboard.writeText(text);
      this.$toast.success("Answer copied to clipboard.");
    },
    regenerateAnswer(message, index){

      this.$emit("regenerate-answer", message, index);

      var div = document.getElementById("messages");
      div.scrollTop = div.scrollHeight;

      this.$nextTick(() => {
        var messageDisplay = this.$refs.messages1;
        messageDisplay.scrollTop = messageDisplay.scrollHeight;
      });
    },
    toggleSidebar() {
      this.isSidebarVisible = !this.isSidebarVisible;
    },
    expertMode() {
      this.expertmode = true;
    },
    toggleQuestions() {
      if (this.default_questions.length < 2) {
        this.toggleQuestionsFalse();
        this.$toast.warning("No more questions available.");
        return;
      }
      this.showQuestions = !this.showQuestions; // Toggle the display of questions
    },
    toggleQuestionsFalse() {
      this.showQuestions = false;
    },
    deleteMessage(idx) {
      this.filteredMessages.splice(idx, 1);
      this.$bvModal.hide(`alert-modal-${idx}`);
    },
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + "...";
      }
      return text;
    },
    chooseMessage(message, index) {
      this.$refs.questionDropdown.hide();
      this.showQuestions = false;

      this.$emit("question-selected", message, index);

      var div = document.getElementById("messages");
      div.scrollTop = div.scrollHeight;

      this.$nextTick(() => {
        var messageDisplay = this.$refs.messages1;
        messageDisplay.scrollTop = messageDisplay.scrollHeight;
      });
    },

    sendNewMessage() {
      if (this.NewText.length != 0) {
        this.$emit("send-message", this.NewText);
        this.NewText = "";

        var div = document.getElementById("messages");
        div.scrollTop = div.scrollHeight;

        this.$nextTick(() => {
          var messageDisplay = this.$refs.messages1;
          messageDisplay.scrollTop = messageDisplay.scrollHeight;
        });
        
      }
        else this.$toast.error("Please enter a message to send.");
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const chatContainer = this.$refs.messages1;
        chatContainer.scrollTop = chatContainer.scrollHeight;
      });
    },
  },
};
</script>
<style scoped>
/* Sidebar Lawbot */
.sidebar {
  position: fixed;
  width: 48px;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 5px #aaabaf;
  right: 0;
}
.top-icon {
  width: 48px;
  height: 64px;
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.collapse-btn {
  background-color: #ffffff;
  border-radius: 50% !important;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: translateX(-50%);
  bottom: 50%;
  box-shadow: 0 0 5px #aaabaf;
  cursor: pointer;
  span {
    color: var(--primary);
  }
}
.collapse-btns {
  z-index: 100;
  background-color: #ffffff;
  border-radius: 50% !important;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: translateX(-50%);
  bottom: 35%;
  box-shadow: 0 0 5px #aaabaf;
  cursor: pointer;
  span {
    color: var(--primary);
  }
}

.normal {
  width: 1080px;
  position: absolute;
  right: 0;
  background-color: #fff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease;
}

.slide-enter, .slide-leave-to /* .slide-leave-active in <2.1.8 */ {
  transform: translateX(100%);
}
.top_bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  background-color: var(--primary);
}

.lb_left {
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 13px;
}

.lb_left span {
  font-size: 24px;
  display: flex;
  align-items: center;
}

.lb_left p {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  display: flex;
  align-items: center;
  color: #ffffff !important;
}

.mode {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: transparent; /* Remove button background */
  border: none; /* Remove button border */
  cursor: pointer;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 20px !important;
  background: #ffffff;
  cursor: pointer;
}

.mode .em {
  margin: 0;
  font-size: 14px;
  line-height: 1;
  display: flex;
  align-items: center;
  font-weight: 400;
  color: var(--primary) !important;
}

.mode span {
  font-size: 24px;
  display: flex;
  align-items: center;
  margin-left: 5px;
  color: var(--status-blue) !important;
}

/* Main Content Styles */
.main-content {
  display: flex;
  flex-grow: 1;
}

/* Sidebar Styles */
.left_side {
  width: 33%;
  height: calc(100vh - 64px);
  background-color: #ffffff;
  padding: 20px;
  border-right: 1px solid #ddd;
  position: relative;
  overflow-y: auto;
}

.recent-conversations {
  margin-top: 10px;
  padding: 10px;
}

.history-text{
  color: var(--Neutral-Dark-Grey, #86888D);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
}


.message-item {
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
  position: relative;
  background-color: var(--light-grey);
  border: 1px solid var(--light-grey);
}

.message-item:hover{
  border: 1px solid var(--Status-Blue, #1890FF);
  background: rgba(24, 144, 255, 0.10);
}

.message-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.message-header p {
  margin: 0;
  font-weight: 600;
}

.message-subheader {
  font-size: 12px;
  color: var(--dark-grey) !important;
  margin-top: 5px;
}

.delete-icon {
  color: var(--dark-grey);
  cursor: pointer;
  &:hover {
    color: var(--status-red);
  }
}

.ls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--mid-grey);
}

.ls p {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--primary) !important;
  margin: 0;
}

.ls span {
  color: var(--primary) !important;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.chat-placeholder {
  padding-top: 40%;
  text-align: center;
  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
}

.chat-placeholder i {
  font-size: 48px;
  color: #ddd;
  margin-bottom: 10px;
}

/* Right Content Area Styles */
.content-area {
  width: 67%;
  position: relative;
  flex-grow: 1;
  text-align: center;
  height: calc(100vh - 64px);
  padding-bottom: 50px;
}
.no-questions {
  height: 100%;
  display: flex; /* Enable Flexbox */
  flex-direction: column; /* Stack child elements vertically */
  align-items: center; /* Center child elements horizontally */
  justify-content: center;
  text-align: center;
}

.no-questions p {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}
/* Fixed position for sample questions and input */
.sample-questions-container {
  position: sticky;
  bottom: 20px;
  margin-left: 15px;
}

.input-container {
  height: 100px;
  /* position: sticky; */
  bottom: 0;
  right: 0; /* Align the container to the right edge */
  width: 100%;
  padding: 12px 20px;
  background: var(--interface-grey);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* Ensure padding is included in the width calculation */
}

.input-field {
  flex: 1;
  background: none;
  display: block !important;
}

.nr_send {
  color: var(--primary);
}

.chat-section {
  margin-bottom: 100px; /* Add margin to accommodate the fixed input section */
  max-height: calc(100vh - 200px); /* Adjust based on the fixed elements */
}

.sample-questions-btn {
  display: flex;
  position: relative;
  align-items: center;
  padding: 10px 15px;
  border-radius: 20px !important;
  height: 40px;
  background-color: #ffffff;
  cursor: pointer;
  box-shadow: 0px 4px 10px 0px #e3e5eb99;
  border: none;
  font-size: 16px;
}

.dot {
  height: 8px;
  width: 8px;
  background-color: #52c41a;
  border-radius: 50%;
  margin-right: 10px;
}

.questions-list {
  margin-top: 10px;
  padding: 10px;
  border-radius: 6px;
}

.question-btn {
  display: block;
  text-align: left;
  border: 1px solid var(--mid-grey);
  padding: 10px;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;
  color: var(--dark-grey);
}
.prompt_css {
  display: block;
  text-align: left;
  padding: 10px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;
  color: var(--neutral-black);
}
.prompt_css:hover {
  background: rgba(24, 144, 255, 0.10);
}
</style>
<style scoped>
.custom-header {
  width: 100%;
  height: 60px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  P {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: var(--neutral-black);
  }
}

.close-btn {
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.custom-body {
  display: flex;
  width: 100%;
  height: auto;
  padding: 12px 24px; /* Body padding */
  gap: 16px; /* Space between child elements */
}
/* Plan Card Styles */
.plan {
  width: 100%;
  height: auto;
  padding: 16px 0px 0px;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid var(--light-grey);
  background: var(--interface-grey);
  p {
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
  }
}
.current {
  height: 44px;
  padding: 10px;
  gap: 10px;
  border: 1px solid var(--mid-grey);
  border-radius: 25px;
  text-align: center;
  margin: auto 13px;
  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--dark-grey) !important;
  }
}
.update {
  height: 44px;
  padding: 10px;
  gap: 10px;
  background: #fae8ff;
  border: 1px solid #c319ff;
  color: #c319ff !important;
  border-radius: 25px;
  text-align: center;
  margin: auto 13px;
  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #c319ff !important;
  }
}
.list {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 15px;
  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 33px;
    color: var(--neutral-black) !important;
    margin: 0;
  }
}
.footer {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: center !important;
}
</style>
<style scoped>
.chat-container {
  padding: 20px;
  position: relative;
}
.chat-header {
  margin-bottom: 10px;
}
.header-input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid #ccc;
}

.chat-box {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 10px;
}

.user-message,
.bot-message {
  margin: 10px 0;
}

.user-message {
  background-color: var(--status-blue);
  padding: 10px;
  border-radius: 10px;
  text-align: right;
  margin-left: auto;
  max-width: 40%;
  height: auto;
}
.user-message::before {
  content: "";
  position: absolute;
  top: -10px;
  right: 10px;
  width: 20px;
  height: 20px;
  background-color: #4caf50;
  clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
}
.bot-message-container {
  display: flex;
  align-items: center; /* Aligns icon and text vertically in the center */
  margin: 10px 10px;
  span {
    width: 32px;
    height: 32px;
    background: var(--primary);
    border-radius: 50%;
    color: #fff;
    display: flex; /* This centers the content within the span */
    justify-content: center; /* Horizontally centers the icon */
    align-items: center;
  }
}
.bot-message {
  background-color: #f0f0f0;
  color: #333;
  padding: 10px;
  border-radius: 10px;
  max-width: 80%;
}

.bot-text {
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
}

.thinking {
  text-align: center;
  font-style: italic;
}

.loading-dots::after {
  content: "...";
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    content: ".";
  }
  40% {
    content: "..";
  }
  60%,
  100% {
    content: "...";
  }
}

.chat-input-container {
  display: flex;
  align-items: center;
}

.chat-input {
  flex: 1;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #ccc;
  font-size: 14px;
  margin-right: 10px;
}

.send-btn {
  background-color: #0084ff;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
}

.sample-questions {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.sample-btn {
  background-color: #f0f0f0;
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 12px;
}
</style>
<style scoped>
.animated-background-outgoing {
  background: var(--status-blue);
  background-size: 200% 100%;
  animation: gradientAnimation 3s linear infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }

  25% {
    background-position: 100% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  75% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.animated-background-incoming {
  background: var(--light-grey);
  background-size: 200% 100%;
  animation: gradientAnimation 3s linear infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }

  25% {
    background-position: 100% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  75% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.chat__box {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  height: 100%;
  overflow-y: scroll;
}

.chat__incoming {
  margin-right: auto;
  margin-top: 0.5rem;
}

.chat__bubble__wrapper-incoming {
  margin-right: auto;
  margin-bottom: 1rem;
}

.chat__bubble-incoming {
  padding: 0.3rem 1rem;
  background-color: #f1f1f1;
  border-bottom-left-radius: 0px;
  margin-right: 3rem;
  margin-left: 0.5rem;
}

.chat__bubble-incoming span {
  color: #333333;
}

.chat__outgoing {
  margin-left: auto;
  margin-top: 0.5rem;
  color: white !important;
}

.chat__bubble__wrapper-outgoing {
  margin-left: auto;
  margin-bottom: 1rem;
}

.chat__bubble-outgoing {
  padding: 0.3rem 1rem;
  background-color: var(--status-blue);
  border-bottom-right-radius: 0px;
  margin-left: 3rem;
}

.chat__bubble-outgoing span p {
  color: #fff !important;
}

/* chat suggestions and input */
.chat__suggestion {
  display: flex;
  border: 2px solid #dddddd;
  border-radius: 20px;
  padding-left: 10px;
  height: auto;
  margin-bottom: 0.5rem;
}

.chat__suggestion:hover {
  background-color: #f1f1f1;
  transition: 300ms ease-out;
}

.chat__suggestion span {
  text-align: left;
  padding-top: 0.2rem;
}

.chat__input {
  display: flex;
  border: 2px solid #0058a2;
  border-radius: 20px;
  padding-left: 10px;
}

.chat__input input {
  border: none;
  border-radius: 20px;
  height: 40px;
  width: 100%;
  box-shadow: none;
  outline: none;
}

.chat__input .btn-send {
  padding: 0;
  margin: 0;
  height: 38px;
}

.chat__input .btn-send span {
  color: #0058a2;
  font-size: 40px;
}

.chat__input .btn-send:hover span {
  color: #004278;
  transition: 300ms ease-out;
}

.accordion-checkbox {
  display: none;
}

.accordion-label {
  cursor: pointer;
  display: block;
}

.accordion-icon {
  transition: transform 0.3s;
}

.accordion-checkbox:checked ~ .accordion-label .accordion-icon {
  transform: rotate(-180deg);
}

.card-header {
  padding: 0rem 0rem !important;
  padding-top: 1rem !important;
  margin-bottom: 0 !important;
  color: var(--bs-card-cap-color) !important;
  background-color: #ffffff !important;
  border-bottom: 0px solid #ffffff !important;
}

.content-card {
  height: 650px;
}

.underlined {
  border-bottom: 1px solid lightgray;
}

table tbody {
  height: 410px;
  overflow-y: scroll;
}
</style>
<style scoped>
.dot_active {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--status-green);
  margin-right: 5px;
}
.pur {
  color: var(--status-violet);
  width: 20px;
  height: 16px;
  cursor: pointer;
}
.blu {
  color: var(--status-violet);
}
.st_icon {
  background: var(--primary); /* Use your primary color */
  border-radius: 50%; /* Make the icon circular */
  padding: 5px;
  color: #ffffff; /* Make the icon color white */
  display: flex; /* Align the icon within the circle */
  justify-content: center; /* Center the icon horizontally */
  align-items: center; /* Center the icon vertically */
  font-size: 24px; /* Adjust the icon size as needed */
}
</style>

<style scoped>
.delete-prompt {
  margin: 8px 8px 20px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.delete-prompt p {
  margin-bottom: 0px;
  text-align: center;
}

.delete-prompt p b {
  font-weight: 500;
  font-size: 16px;
  color: var(--neutral-black);
}

.delete-prompt-options {
  display: flex;
  justify-content: space-between;
  margin: 0px 8px 8px 8px;
}

.delete-prompt-options button {
  width: calc(50% - 4px);
  height: 32px;
}
.trial {
  background: var(--primary);
  color: #ffffff;
}
.DROPDOWN-MENU ::v-deep .tooltip-inner {
  max-width: 291px !important;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid var(--light-grey);
  box-shadow: 0px 4px 10px 0px #aaabaf99;
  transform: translateX(-200px); /* Move the element 20px to the left */
}
</style>
