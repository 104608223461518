<template>
  <form class="search-bar" role="search" @submit.prevent>
    <div class="input-section">
      <textarea
        v-if="!onlyFile"
        id="question"
        autocorrect="off"
        autocomplete="off"
        name="statement"
        v-model="legalQuery"
        class="input-field"
        :disabled="blockUser"
        placeholder="Enter your legal query or task here"
        rows="2"
        maxlength="3000"
        @input="updateCharCount"
      ></textarea>

      <textarea
        v-if="onlyText"
        id="question"
        autocorrect="off"
        autocomplete="off"
        name="statement"
        v-model="legalQuery"
        class="input-field"
        :disabled="blockUser"
        placeholder="Enter your legal query or task here"
        rows="2"
        maxlength="3000"
        @input="updateCharCount"
      ></textarea>

      <button
        v-if="!notSend"
        class="upload-button"
        tabindex="0"
        :disabled="blockUser"
        @click.prevent="emitLegalQueryWithFiles"
      >
        <span
          class="material-symbols-rounded"
          :style="{
            color:
              legalQuery?.length > 0 && selectedLocationId
                ? 'var(--primary)'
                : '',
          }"
          >send</span
        >
      </button>

      <div class="only-file-container">
        <span v-if="onlyFile && files[2]?.length === 0" class="only-file-text">
          <slot>
            <span v-if="showText" class="container-description"
              >No files uploaded yet.</span
            >
          </slot>
        </span>
      </div>

      <div
        v-if="files?.length > 0 && files[2]?.length > 0"
        class="file-display"
      >
        <b-row class="d-flex flex-wrap">
          <b-col
            v-for="(file, index) in displayedFiles"
            :key="index"
            cols="4"
            class="d-flex align-items-center mt-2 mb-2 p-0"
          >
            <div class="d-flex align-items-center ms-4">
              <span class="material-symbols-rounded badge-icon me-2">
                {{ file.name.endsWith(".zip") ? "folder" : "description" }}
              </span>
              <p class="badge-title" style="margin: 0">
                {{ truncateFileName(file.name) }}
              </p>
            </div>
            <div class="ms-auto d-flex align-items-center me-4">
              <span class="badge-size mb-0 me-2"
                >{{ (file.file.size / (1024 * 1024)).toFixed(2) }} MB</span
              >
              <template v-if="file.uploading">
                <span>
                  <b-spinner
                    small
                    style="width: 1.15rem; height: 1.15rem"
                    variant="primary"
                    label="Uploading"
                  ></b-spinner>
                </span>
              </template>
              <template v-else>
                <span
                  class="material-symbols-rounded close-icon"
                  @click="removeFile(index)"
                  >close</span
                >
              </template>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- <div
        class="d-flex footer-text ps-2"
        v-if="files?.length > 0 && files[2]?.length > 0"
      >
        <span class="material-symbols-rounded" style="font-size: 18px"
          >info</span
        >
        <p class="footer-text ps-1">
          Your files will be securely stored in NeXa, accessible only to you and
          your workspace admins, and you can delete them at any time.
        </p>
      </div> -->

      <div class="d-flex justify-content-center align-items-center">
        <button
          v-if="files?.length > 0 && files[2]?.length > maxFilesToShow"
          type="button"
          @click="toggleShowAll"
          class="d-flex align-items-center m-3"
          style="color: #1890ff"
        >
          <span class="material-symbols-rounded icon">
            {{
              showAll
                ? "keyboard_double_arrow_up"
                : "keyboard_double_arrow_down"
            }}
          </span>
          {{ showAll ? "Show Less" : "Show More" }}
        </button>
      </div>
    </div>

    <b-tooltip target="tooltip_attach" triggers="hover">
      Upload any documents and let our AI show you what it can do!
    </b-tooltip>

    <footer class="search-bar-footer">
      <div class="d-flex button-wrapper">
        <button
          v-if="onlyText == true || noFiles == false"
          class="attach-button d-flex align-items-center"
          id="tooltip_attach"
          type="button"
          @click="showAttachModal"
          :disabled="blockUser"
          :style="{
            color:
              files?.length && files[2]?.length > 0 ? 'var(--primary)' : '',
          }"
        >
          <span
            class="material-symbols-rounded icon-small me-1"
            :style="{
              color:
                files?.length && files[2]?.length > 0 ? 'var(--primary)' : '',
            }"
            >attach_file</span
          >
          Attach
        </button>

        <span
          v-if="onlyText == true || noFiles == false"
          class="divider"
        ></span>
        <span
          v-if="Object.keys(selectedStates).length > 0"
          class="selected-states"
        >
          {{ formattedSelectedStates }}
        </span>
        <button
          class="country-button d-flex align-items-center"
          type="button"
          @click="toggleCountryDropdown"
          :disabled="blockUser"
          :style="{ color: selectedLocationId ? 'var(--primary)' : '' }"
        >
          <span
            v-if="Object.keys(selectedStates).length == 0"
            class="material-symbols-rounded icon-small me-1"
            :style="{ color: selectedLocationId ? 'var(--primary)' : '' }"
            >arrow_drop_down</span
          >

          <img
            v-if="selectedLocationId"
            :src="countryImages[selectedLocationId]"
            alt="Country Flag"
            class="country-flag"
          />
          {{
            !multipleJurisdiction
              ? selectedLocationId
                ? selectedLocationId
                : "Country"
              : Object.keys(selectedStates).length > 0
              ? "Add More +"
              : "Jurisdiction"
          }}
        </button>

        <div
          v-if="isCountry"
          class="country-option"
          v-clickaway="closeCountryDropdown"
        >
          <ul class="country-list">
            <li
              v-for="(flag, countryCode) in filteredCountryImages"
              :key="countryCode"
              class="countryOption"
              @click="
                multipleJurisdiction
                  ? countryCode === 'USA'
                    ? toggleStateDropdown(countryCode)
                    : 'asd'
                  : selectCountry(countryCode)
              "
            >
              <div class="country-wrapper">
                <img :src="flag" alt="Country Flag" class="country-flag" />
                <span>{{ countryCode }}</span>
              </div>

              <img
                src="@/assets/icon/arrow-right.svg"
                alt="Arrow Right"
                v-if="multipleJurisdiction && countryCode === 'USA'"
              />
              <input
                v-else-if="multipleJurisdiction && countryCode !== 'USA'"
                type="checkbox"
                :id="countryCode"
                :value="countryCode"
                :checked="selectedStates[countryCode]"
                @change="toogleCountrySelection(countryCode)"
              />
            </li>
            <template v-if="multipleJurisdiction">
              <ul v-if="isDropdownOpen[country]" class="state-list">
                <div class="state-list-filter-wrapper">
                  <span>State</span>
                  <input
                    type="text"
                    v-model="stateFilter[country]"
                    placeholder="Filter states..."
                    class="state-filter-input"
                  />
                </div>

                <li
                  class="state-row"
                  v-for="state in filteredStates(country)"
                  :key="state"
                >
                  <label :for="state">{{ state }}</label>
                  <input
                    type="checkbox"
                    :id="state"
                    :value="state"
                    :checked="selectedStates[country]?.includes(state)"
                    @change="toggleStateSelection(country, state)"
                  />
                </li>
              </ul>
            </template>
          </ul>
        </div>
      </div>

      <div class="d-flex align-items-center" v-if="!onlyFile">
        <span class="char-count">{{ charCount }} / 3000</span>
      </div>

      <div class="d-flex align-items-center" v-if="showWords">
        <span class="char-count">{{ charCount }} / 3000</span>
      </div>
    </footer>

    <b-modal id="attachfile" hide-footer hide-header size="lg">
      <div class="d-flex justify-content-between">
        <div>
          <h5>Upload Files</h5>
        </div>
        <div>
          <span
            class="material-symbols-rounded modal-icon"
            @click="hideAttachModal"
            >close</span
          >
        </div>
      </div>
      <div>
        <!-- Add 'zip' for accepting zip files -->
        <FileInput
          style="flex-grow: 1"
          :accept="['pdf', 'docx', 'png', 'jpg', 'jpeg']"
          @input-files="$set(files, 0, $event)"
        />
        <div class="mt-2 d-flex">
          <span class="material-symbols-rounded mt-1 help-icon">help</span>
          <p class="help-description">
            If you have multiple files to upload, we recommend zipping them into
            one file to make the process quicker and easier.
          </p>
        </div>
        <!-- Add 'zip' for accepting zip files -->
        <FileProcess
          style="width: 100%; max-height: 200px; overflow-y: auto"
          :accept="['pdf', 'docx', 'png', 'jpg', 'jpeg']"
          :files="files[0]"
          @output-files="$set(files, 1, $event)"
        />
      </div>
      <footer>
        <button class="upload" @click="uploadFile">Upload</button>
      </footer>
    </b-modal>
  </form>
</template>

<script>
import FileInput from "@/components/input/FileInput.vue";
import FileProcess from "@/components/input/FileProcess.vue";
import countryImages from "@/assets/country/index";
import courtname from "@/store/courtname";
import { mapState } from "vuex";

export default {
  name: "UploadFilesBar",
  components: {
    FileInput,
    FileProcess,
  },

  props: {
    notSend: {
      type: Boolean,
      default: false,
    },
    onlyFile: {
      type: Boolean,
      default: false,
    },
    onlyText: {
      type: Boolean,
      default: false,
    },
    showText: {
      type: Boolean,
      default: true,
    },
    showWords: {
      type: Boolean,
      default: false,
    },
    multipleJurisdiction: {
      type: Boolean,
      default: false,
    },
    onlyQuery: {
      type: Boolean,
      default: false,
    },
    noJurisdiction: {
      type: Boolean,
      default: false,
    },
    noFiles: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      legalQuery: "",
      countryImages,
      courtname,
      blockUser: false,
      files: [[], [], []],
      selectedLocationId: null,
      charCount: 0,
      country: "",
      selectedCountries: [],
      selectedStates: {},
      isCountry: false,
      maxFilesToShow: 9,
      showAll: false,
      isDropdownOpen: {},
      stateFilter: {},
      emitFromStoreDone: false,
    };
  },
  methods: {
    toggleShowAll() {
      this.showAll = !this.showAll;
    },
    selectCountry(countryCode) {
      this.selectedLocationId = countryCode;

      this.isCountry = false;
    },
    emitLegalQueryWithFiles() {
      if (this.files[2].length === 0) {
        this.$toast.error("Please upload at least one file.");
        return;
      }

      if (this.legalQuery === "" && !this.onlyFile) {
        this.$toast.error("Question cannot be empty.");
        return;
      }

      if (this.selectedLocationId === null && !this.multipleJurisdiction) {
        this.$toast.error("Please select a country.");
        return;
      }

      if (
        Object.keys(this.selectedStates).length === 0 &&
        this.multipleJurisdiction
      ) {
        this.$toast.error("Please select at least one state.");
        return;
      }
      if (this.files.length > 0 || this.legalQuery || this.selectedLocationId) {
        this.$emit("query-details-submitted", {
          files: this.files,
          query: this.legalQuery,
          jurisdiction: this.getJurisdictionObject(),
        });
      }
    },

    getJurisdictionObject() {
      return JSON.stringify(this.selectedStates);
    },
    toggleCountryDropdown() {
      this.isCountry = !this.isCountry;
    },
    toggleStateDropdown(countryCode) {
      this.country = countryCode;
      Object.keys(this.isDropdownOpen).forEach((key) => {
        if (key !== countryCode) {
          this.$set(this.isDropdownOpen, key, false);
        }
      });

      this.$set(
        this.isDropdownOpen,
        countryCode,
        !this.isDropdownOpen[countryCode]
      );
    },
    closeCountryDropdown() {
      this.isCountry = false;
      this.isDropdownOpen = {};
    },
    toogleCountrySelection(countryCode) {
      if (this.selectedStates[countryCode]) {
        this.$delete(this.selectedStates, countryCode);
      } else {
        this.$set(this.selectedStates, countryCode, ["all"]);
      }
    },
    toggleStateSelection(countryCode, state) {
      if (!this.selectedStates[countryCode]) {
        this.$set(this.selectedStates, countryCode, []);
      }

      const selectedStatesArray = this.selectedStates[countryCode];
      const selectedStateIndex = selectedStatesArray.indexOf(state);

      if (selectedStateIndex === -1) {
        selectedStatesArray.push(state);
      } else {
        selectedStatesArray.splice(selectedStateIndex, 1);
      }

      if (selectedStatesArray.length === 0) {
        this.$delete(this.selectedStates, countryCode);
      }
    },
    truncateFileName(name) {
      return name.length > 20 ? name.substring(0, 20) + "..." : name;
    },
    updateCharCount() {
      this.charCount = this.legalQuery.length;
    },
    removeFile(index) {
      this.files[2].splice(index, 1);
    },
    showAttachModal() {
      this.$bvModal.show("attachfile");
    },
    hideAttachModal() {
      this.$bvModal.hide("attachfile");
    },
    uploadFile() {
      this.$set(this.files, 2, this.files[1]);
      this.$set(this.files, 0, []);
      this.$set(this.files, 1, []);

      this.displayedFiles.forEach((file) => {
        this.$set(file, "uploading", true);
        const uploadTime = Math.min(
          10000,
          Math.max(1000, (file.file.size / (1024 * 1024)) * 1000)
        ); // Scale upload time

        setTimeout(() => {
          this.$set(file, "uploading", false);
        }, uploadTime + 1000);
      });
      this.hideAttachModal();
    },
    emitFile() {
      if (this.files.length > 0) {
        this.$emit("get-file", this.files);
      }
    },
    emitQuery() {
      this.$emit("get-query", this.legalQuery);
    },
    emitJurisdiction() {
      this.$emit("get-jurisdiction", {
        [this.selectedLocationId]: ["all"],
      });
    },
    emitStates() {
      const jurisdictions = [];

      for (const [country, states] of Object.entries(this.selectedStates)) {
        const selectedStates = [];

        states.forEach((state) => {
          if (state === "all") {
            selectedStates.push("all");
          } else {
            selectedStates.push(state);
          }
        });

        if (selectedStates.length > 0) {
          jurisdictions.push({ [country]: selectedStates });
        }
      }

      this.$emit("get-jurisdiction", jurisdictions);
    },
  },
  computed: {
    ...mapState({
      storeSelectedLocationId: (state) => state.selectedLocationId,
      storeFiles: (state) => state.files,
      storeLegalQuery: (state) => state.legalQuery,
    }),
    filteredCountryImages() {
      return Object.entries(this.countryImages).reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
    },
    formattedSelectedStates() {
      const result = [];

      for (const country in this.selectedStates) {
        const states = this.selectedStates[country].filter(
          (state) => state !== "all"
        );
        let stateString;

        if (states.length > 2) {
          const firstTwoStates = states.slice(0, 2).join(", ");
          const moreCount = states.length - 2;
          stateString = `${country} (${firstTwoStates} +${moreCount} More)`;
        } else if (states.length > 0) {
          stateString = `${country} (${states.join(", ")})`;
        } else {
          stateString = country;
        }

        result.push(stateString);
      }

      return result.join(", ");
    },
    filteredCourts() {
      const result = {};
      Object.keys(this.courtname).forEach((countryCode) => {
        if (this.courtname[countryCode].states) {
          const states = this.courtname[countryCode].states;
          const filteredStates = states.map((state) =>
            state.split("|")[0].trim()
          );
          result[countryCode] = {
            states: filteredStates,
          };
        }
      });
      return result;
    },
    filteredStates() {
      return (country) => {
        const filter = this.stateFilter[country]
          ? this.stateFilter[country].toLowerCase()
          : "";
        return this.filteredCourts[country].states.filter((state) =>
          state.toLowerCase().includes(filter)
        );
      };
    },
    displayedFiles() {
      return this.showAll
        ? this.files[2]
        : this.files[2].slice(0, this.maxFilesToShow);
    },
  },
  watch: {
    files: {
      handler() {
        if (this.notSend && !this.isUpdatingFiles && this.emitFromStoreDone) {
          this.emitFile();
        }
      },
      deep: true,
    },
    legalQuery: {
      handler() {
        if (this.notSend && this.emitFromStoreDone) {
          this.emitQuery();
        }
      },
    },
    selectedLocationId: {
      handler() {
        if (this.notSend & this.emitFromStoreDone) {
          this.emitJurisdiction();
        }
      },
    },
    selectedStates: {
      handler() {
        if (this.notSend && this.multipleJurisdiction) {
          this.emitStates();
        }
      },
      deep: true,
    },
  },
  destroyed() {
    this.files[2] = [];
  },
  beforeRouteLeave(next) {
    this.files[2] = [];
    next();
  },
  mounted() {
    try {
      if (this.storeFiles.length > 0) {
        this.$emit("get-file", this.storeFiles);
        this.files = this.storeFiles;
      }
      if (this.storeLegalQuery) {
        this.$emit("get-query", this.storeLegalQuery);
      }
      if (this.storeSelectedLocationId) {
        this.$emit("get-jurisdiction", {
          [this.storeSelectedLocationId]: ["all"],
        });
      }
    } catch (error) {
      console.error("Error in mounted hook:", error);
    } finally {
      this.emitFromStoreDone = true;
    }
  },
};
</script>

<style scoped>
.state-list-filter-wrapper {
  display: flex;
  flex-direction: column;
  padding: 2px 8px;
  gap: 4px;
  margin: auto;
  width: 100%;
  span {
    color: var(--Neutral-Dark-Grey, #86888d);

    font-size: 0.75rem !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  input {
    display: flex;
    padding: 0.25rem 0.5rem;
    align-items: center;
    gap: 0.625rem;
    flex: 1 0 0;
    border-radius: 0.25rem;
    border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
    background: var(--Neutral-Interface-Grey, #fafbfc);
  }
}
.selected-states {
  display: inline-flex;
  padding: 0.25rem 0.5rem;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--Neutral-Mid-Grey, #d1d2d5);
  max-width: 36rem;
}
.state-row {
  padding: 4px 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: var(--Neutral-Dark-Grey, #86888d);

  font-size: 0.875rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  cursor: pointer;
  > * {
    cursor: pointer;
  }
  input {
    accent-color: #0e4485;
    width: 1.125rem;
    height: 1.125rem;
    justify-content: center;
    align-items: center;
  }
}

.state-row:hover {
  background-color: var(--Neutral-Light-Grey, #f2f3f3);
}
.state-row label {
  padding-left: 1.5rem;
}
.state-row input {
  margin-right: 1.5rem;
}
.button-wrapper {
  position: relative;
}
.country-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}
.country-flag {
  width: 20px;
  height: 15px;
  margin-right: 8px;
}
.state-list {
  position: absolute;
  left: 115%;
  top: 0;
  display: flex;
  width: 14.4375rem;
  flex-direction: column;
  align-items: flex-start;
  max-height: 20rem;
  overflow-y: auto;
  border-radius: 0.375rem;
  border: 1px solid var(--Status-Blue, #1890ff);
  background: #fafbfc;
  padding: 2px 0;
  box-shadow: 0px 4px 10px 0px rgba(170, 171, 175, 0.6);
}
.country-checkbox {
  margin-left: 8px;
}

.input-section {
  position: relative;
  border: 1px solid #f2f3f3;
  background: var(--Neutral-Interface-Grey, #fafbfc);
  padding: 20px 20px 0 20px;
  margin-bottom: 16px;
  border-radius: 8px 8px 0px 0px;
}

.input-label {
  color: #383a3e;
  font-size: 16px;
  font-weight: 500;
  display: block;
  margin-bottom: 8px;
}

.input-field::placeholder {
  color: #86888d;
  opacity: 1;
}

.character-count {
  display: block;
  text-align: right;
  font-size: 14px;
  color: #86888d;
  margin-top: 8px;
}

.badge {
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 2px 8px;
  border-radius: 8px;
  background: var(--Neutral-Interface-Grey, #fafbfc);
  color: grey;
}

.badge-icon {
  color: var(--primary);
}

.badge-title {
  color: var(--Neutral-Black, #383a3e);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.badge-size {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}
</style>
<style scoped>
.main-container {
  width: 100%;
  position: relative;
}

.overlay-container {
  width: 25%;
  position: absolute;
  top: 8.7vh;
  right: 0;
  z-index: 1000;
  height: 100vh;
}

.breadcrumb {
  display: flex;
  align-items: center;

  span {
    font-size: 14px;
    color: var(--dark-grey) !important;
  }
}

.open_sidebar {
  height: 40px;
  background: var(--primary);
  display: inline-flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  /* Ensure vertical alignment */
  gap: 8px;
  border-radius: 4px;
}

.open_sidebar span {
  color: #fff;
  display: flex;
  /* Ensure icon is aligned vertically */
  align-items: center;
  /* Align icon vertically within span */
}

.open_sidebar p {
  color: #fff !important;
  margin: 0;
  /* Remove any default margin to align the text properly */
  line-height: 1;
  /* Ensure text has the same line height as the icon */
  display: flex;
  align-items: center;
  /* Align text vertically */
}

.container {
  background: var(--Neutral-Interface-Grey, #fafbfc);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 70px 14px;
}

@media (max-width: 991px) {
  .container {
    padding: 0 20px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1076px;
}

@media (max-width: 991px) {
  .content {
    max-width: 100%;
    padding-right: 20px;
  }
}

.header {
  text-align: center;
  max-width: 100%;
}

.title {
  color: var(--Neutral-Black, #383a3e);
  font-family: Poppins, sans-serif;
  font-size: 32px !important;
  font-weight: 600;
}

.subtitle {
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.search-bar {
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  box-shadow: 0px 4px 10px 0px rgba(227, 229, 235, 0.6);
  margin-top: 24px;
  width: 100%;
}

.input-group {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  position: relative;
}

.input-field {
  flex: 1;
  background: var(--Neutral-Interface-Grey, #fafbfc);
  color: var(--Neutral-Mid-Grey, #000);
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  border: none;
  outline: none;
  width: 97%;
  resize: none;
}

.upload-button {
  border-radius: 4px;
  overflow: hidden;
  padding: 4px;
  padding-right: 10px;
  right: 16px;
  position: absolute;
}

.upload-button img {
  width: 20px;
  object-fit: contain;
}

.tooltip {
  align-self: start;
  border-radius: 4px;
  color: var(--Neutral-White, #fff);
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 6px 8px;
  margin-top: -14px;
}

.tooltip-arrow {
  position: absolute;
  left: 16px;
  bottom: 8px;
  width: 11px;
  height: 6px;
  background: var(--Neutral-Black, #383a3e);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.search-bar-footer {
  border-top: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  background: var(--Neutral-Light-Grey, #f2f3f3);
  display: flex;
  justify-content: space-between;
  padding: 6px 20px;
  margin-top: -16px;
  border-radius: 0px 0px 8px 8px;
}

.country-button {
  position: relative;
}

.attach-button,
.country-button {
  color: #86888d;
  font-size: 14px;
  font-weight: 500;
}

.attach-button:hover,
.country-button:hover {
  color: var(--primary);
  > span {
    color: var(--primary);
  }
}

.char-count {
  color: var(--Neutral-Mid-Grey, #d1d2d5);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.prompts {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.prompt-button {
  background: var(--Neutral-Light-Grey, #f2f3f3);
  border: 1px solid var(--Neutral-Mid-Grey, #d1d2d5);
  border-radius: 16px !important;
  box-shadow: 0px 4px 10px 0px rgba(227, 229, 235, 0.6);
  color: var(--Neutral-Black, #383a3e);
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  padding: 6px 12px;
}

.load-more-button {
  background: none;
  color: var(--Primary-Blue, #0e4485);
  font-size: 14px;
  font-weight: 500;
  padding: 2px 8px;
}

.use-cases {
  margin-top: 35px;
}

.use-cases-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.use-cases-title {
  max-width: 218px;
}

.use-cases-controls {
  display: flex;
  gap: 20px;
}

.filter-button {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 11px 10px;
  border-radius: 4px;
}

.search-container {
  padding: 8px 8px;
  width: 336px;
}

.search-container {
  display: flex;
  align-items: center;
  gap: 4px;
  background: var(--Neutral-White, #fff);
  border: 1px solid var(--Neutral-Mid-Grey, #d1d2d5);
  border-radius: 4px;
}

.search-container {
  padding: 8px 8px;
}

.search-field {
  border: none;
  outline: none;
}

.use-case-grid {
  margin-top: 11px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.use-case-cards {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px 0px rgba(227, 229, 235, 0.6);
  padding: 24px;
  flex: 1 1 350px;
}

.use-case-card {
  height: 212px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px 0px rgba(227, 229, 235, 0.6);
  padding: 24px;
  /* flex: 1 1 240px; */
  flex: 1 1 300px;
  outline: 1px solid transparent;
}

.use-case-card:hover {
  outline: 1px solid var(--primary);
}

.use-case-title {
  color: var(--Neutral-Black, #383a3e);
  font-size: 20px;
  font-weight: 600;
}

.use-case-description {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 16px;
}

.create-button {
  display: none;
  /* width: 240px; */
  width: 300px;
  align-self: end;
  background: var(--Primary-Blue, #0e4485);
  border-radius: 4px;
  color: var(--Neutral-White, #fff);
  font-size: 16px;
  font-weight: 500;
  padding: 4px 16px;
  margin-top: 16px;
}

.use-case-card:hover .create-button {
  display: block;
  width: 100%;
}

.decorative-image {
  width: 20px;
  object-fit: contain;
  margin-top: 204px;
}

@media (max-width: 991px) {
  .decorative-image {
    margin-top: 40px;
  }
}
</style>
<style scoped>
.sidebar {
  background: var(--Neutral-White, #fff);
  box-shadow: 0px 4px 10px rgba(170, 171, 175, 0.6);
  display: flex;
  max-width: 300px;
  flex-direction: column;
  overflow: hidden;
  font-family: Poppins, sans-serif;
  padding: 24px 12px;
}

.sidebar-toggle,
.new-chat-button,
.show-all-button {
  border-radius: 4px;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 500;
  justify-content: flex-start;
  padding: 4px 16px;
  background: none;
  border: none;
}

.show-all-button {
  justify-content: center !important;
  color: var(--Primary-Blue, #0e4485) !important;
}

.show-all-button .icon-small {
  color: var(--Primary-Blue, #0e4485) !important;
}

button.sidebar-toggle {
  color: var(--Primary-Blue, #0e4485);
}

.icon,
.search-icon {
  width: 24px;
  height: 24px;
}

.icon-small {
  color: #86888d;
  margin: auto;
  font-size: 18px;
}

.icon-filter {
  margin: auto;
  font-size: 18px;
}

.sidebar-header {
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.new-chat-button {
  background-color: var(--Primary-Blue, #0e4485);
  color: var(--Neutral-White, #fff);
  padding: 6px 16px;
}

.search-form {
  margin-top: 11px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: var(--Neutral-Light-Grey, #f2f3f3);
  padding: 6px 12px 6px 16px;
  gap: 3px;
  width: 100%;
}

.search-input {
  flex: 1;
  border: none;
  background: none;
  outline: none;
  font-size: 16px;
  color: var(--Neutral-Dark-Grey, #86888d);
}

.search-icon {
  width: 24px;
  height: 24px;
}

.item-list {
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.separator {
  border: 1px solid var(--mid-grey);
  width: 100%;
  margin: 5px 0px 10px;
}

.recent-logs-title {
  margin: 0px;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: var(--Neutral-Dark-Grey, #86888d) !important;
}

.conversation-card,
.alt-card {
  border-radius: 8px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.conversation-card:hover {
  background: #f2f3f3;
}

.alt-card {
  background-color: var(--Neutral-Light-Grey, #f2f3f3);
}

.conversation-title {
  font-size: 14px !important;
  color: var(--Neutral-Black, #383a3e) !important;
  font-weight: 400 !important;
  margin: 0;
}

.conversation-details {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: var(--Neutral-Dark-Grey, #86888d);
}

.file-upload-info {
  flex: 1;
}

.sidebar-image {
  position: absolute;
  right: 77px;
  bottom: 97px;
  width: 20px;
  height: 21px;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.upload-button {
  span {
    color: #86888d;
  }
}

input::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #d1d2d5;
}

.use-case-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
}

/* Styling the header text */
.use-case-header h2 {
  font-size: 1.5rem;
  /* Adjust the size */
  font-weight: 600;
  /* Semi-bold for emphasis */
  color: #333;
  /* Darker color for readability */
  margin: 0;
}

.use-case-header p {
  color: #888888;
  /* Light gray for the subtext */
  font-size: 0.875rem;
  /* Smaller font size for description */
  margin: 0;
  margin-top: 4px;
  /* Adds slight spacing below the heading */
}

/* Styling the filter and search area */
.filter-search {
  display: flex;
  gap: 10px;
  align-items: center;
}

.filter-button {
  font-size: 0.875rem;
  color: #333;
  /* Dark text */
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filter-button:hover {
  background-color: #f0f0f0;
}

.search-input::placeholder {
  color: #888888;
}

.icon-search {
  color: #888888;
}

.country-option {
  right: 0;

  background: #fafbfc;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 12px;
  position: absolute;

  top: 100%;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.country-flag {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.country-list {
  position: relative;
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: flex-start;
}

.countryOption {
  cursor: pointer;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  gap: 1rem;
  span {
    color: #86888d;
    font-size: 14px;
  }
}

.divider {
  display: inline-block;
  margin: 0px 8px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}

.close-icon {
  font-size: 18px;
  color: var(--status-red);
  cursor: pointer;
}

.modal-icon {
  width: 24px;
  height: 24px;
  color: #383a3e;
  cursor: pointer;
}

.upload {
  background: var(--primary);
  border-radius: 6px;
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
  float: right;
}

.toggle {
  display: flex;
  width: 40px;
}

.switch {
  flex-grow: 1;
  background: var(--Neutral-Mid-Grey, #d1d2d5);
  border-radius: 100px;
  padding: 2px;
  display: flex;
  align-items: center;
}

.button {
  width: 16px;
  height: 16px;
  background: var(--Neutral-White, #fff);
  border-radius: 100px;
  box-shadow: 0 2px 4px rgba(39, 39, 39, 0.1);
}

.file-list {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.footer-text {
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.overflow-recent {
  max-height: 30vh;
  overflow: auto;
}

.help-icon {
  font-size: 18px;
  color: #86888d;
}

.help-description {
  font-size: 12px;
  font-weight: 400;
  margin: 0px;
  padding-left: 5px;
  color: #86888d !important;
}

.only-file-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.container-description {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.only-file-text {
  color: var(--Neutral-Dark-Grey, #86888d);

  font-size: 0.75rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}
</style>
