<template>
  <div>
    <div class="breadcrumb_header">
      <div class="breadcrumb">
        <h5 style="margin: 0">NeXa</h5>
        <span class="divider"></span>
        <span
          >Use Cases / Find Precedents / {{ this.$route.params.id }} / Detail /
          {{ this.$route.params.docId }}</span
        >
      </div>
      <div class="open_sidebar" @click="toggleSidebar">
        <span class="material-symbols-rounded icon"
          >keyboard_double_arrow_left</span
        >
        <p>Open Sidebar</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1 back-button">
        <div
          class="d-flex justify-content-start cursor-pointer back"
          @click="goBack"
        >
          <span class="material-symbols-rounded" style="margin: 0"
            >arrow_back</span
          >
          <span style="margin: 0 8px">Back to Precedent List</span>
        </div>
      </div>
      <div class="col-md-10">
        <PageLoader
          class="mt-5"
          v-if="loading"
          :loading="loading"
          :extra_msg="loading_message"
          :completedPercentage="duration"
        />
        <section v-else>
          <section
            class="claims-container"
            v-for="q in questions"
            :key="`questions-${q.id}`"
            :id="`q-${q.id}`"
          >
            <FollowUpQuestionBox :q="q" />
          </section>

          <section id="content" class="content">
            <header class="heading">
              <h1 class="heading-title">{{ summarizedData.name }}</h1>
              <div class="buttons">
                <button class="button" @click="copy">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/a5fc1aca50cb553e4e813d1e66a4f62c328c94fc60417f9c7fb438b58331a1a1?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
                    alt="Copy Icon"
                    class="icon"
                  />
                  <span class="button-title">{{
                    isCopied ? "Copied" : "Copy"
                  }}</span>
                </button>
                <b-dropdown
                  size="sm"
                  id="dropdown-right"
                  right
                  offset="-9"
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  @click="toggleDropdown"
                >
                  <template #button-content>
                    <button class="action-btn export-btn">
                      <span class="button-label">Export</span>
                      <img
                        :class="{ 'rotate-180': isDropdown }"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/d12668b437286b44ce443817869f8a85013f194276d09cf30b58481266006963?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
                        alt="Export Icon"
                        class="icon"
                      />
                    </button>
                  </template>

                  <b-dropdown-item class="ms-1 me-1" @click="exportToPDF">
                    <img class="me-2" src="@/assets/icon/pdf-icon.svg" />
                    <button class="dropdown-text">Export as PDF</button>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </header>

            <article class="case-info">
              <header class="header">Case Info</header>
              <div class="info-card">
                <div class="case">
                  <span class="case-title">Case Title</span>
                  <p class="case-detail">{{ summarizedData.name }}</p>
                </div>
                <div class="case">
                  <span class="case-title">Citation</span>
                  <p class="case-detail">{{ summarizedData.cite_no }}</p>
                </div>
              </div>
              <div class="info-card">
                <div class="case">
                  <span class="case-title">Court</span>
                  <p class="case-detail">{{ summarizedData.court }}</p>
                </div>
                <div class="case">
                  <span class="case-title">Date Decided</span>
                  <p class="case-detail">{{ summarizedData.judgement_date }}</p>
                </div>
              </div>
            </article>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/4ad81a182688703b1fff1bef3787724cc1de957c3535a492ebf0569fa04a6c97?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
              alt=""
              class="divider-image"
            />
            <section class="summary">
              <h2 class="section-heading">Case Overview</h2>
              <p class="section-detail">
                {{ summarizedData.abstract }}
              </p>
            </section>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/4ad81a182688703b1fff1bef3787724cc1de957c3535a492ebf0569fa04a6c97?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
              alt=""
              class="divider-image"
            />
            <section class="summary marked-style">
              <h2 class="section-heading">Legal Issues</h2>
              <vue-marked v-if="values.legal_issues">{{
                values.legal_issues
              }}</vue-marked>
              <p v-else>No legal issues found</p>
            </section>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/4ad81a182688703b1fff1bef3787724cc1de957c3535a492ebf0569fa04a6c97?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
              alt=""
              class="divider-image"
            />
            <section class="arguments marked-style">
              <h2 class="section-heading">Evidences</h2>

              <vue-marked v-if="values.evidences">{{
                values.evidences
              }}</vue-marked>
              <p v-else>No evidences found</p>
            </section>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/4ad81a182688703b1fff1bef3787724cc1de957c3535a492ebf0569fa04a6c97?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
              alt=""
              class="divider-image"
            />
            <section class="ruling-section marked-style">
              <h2 class="section-heading">Ruling</h2>

              <vue-marked v-if="values.ruling">{{ values.ruling }}</vue-marked>
              <p v-else>No ruling found</p>
            </section>
          </section>
        </section>
      </div>
    </div>
    <div class="wrapper-chatbox">
      <ChatBox @chat-submit="submitQuery" />
    </div>
    <sidebar
      v-clickaway="toggleSidebar"
      v-if="sidebarCollapsed"
      @toggle-sidebar="toggleSidebar"
      :uploadFiles="files"
    />
  </div>
</template>
<script>
import sidebar from "../sidebar.vue";
import ChatBox from "../../../components/input/ChatBox.vue";
import nexa from "@/store/nexa.js";
import usecase from "@/store/usecase.js";
import VueMarked from "vue-marked";
import PageLoader from "../../PageLoader.vue";
import { ExportDataPDF } from "@/store/utils";
import FollowUpQuestionBox from "@/components/input/FollowUpQuestionBox.vue";
export default {
  components: {
    ChatBox,
    sidebar,
    VueMarked,
    PageLoader,
    FollowUpQuestionBox,
  },
  data() {
    return {
      loading_message: "Please wait a moment while we process it.",
      duration: 0,
      loading: true,
      sidebarCollapsed: false,
      wholeJuris: {},
      questions: [],
      summarizedData: {},
      values: {},
      isDropdown: false,
      isCopied: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.isDropdown = !this.isDropdown;
    },
    fetchData() {
      try {
        this.loading = true;
        usecase
          .GetSummarizedDocument(this.$route.params.docId ?? "")
          .then((response) => {
            const {
              values,
              summarizeCompletePerCentage,
              jurisdiction,
              questions,
              ...rest
            } = response.data.data;
            this.wholeJuris[jurisdiction] = ["all"];
            this.questions = questions;
            this.duration = summarizeCompletePerCentage;
            this.values = JSON.parse(values[0].value);
            this.summarizedData = rest;

            if (this.questions.length > 0) {
              const hasNullValue = this.questions.some(
                (q) => q.answer === null
              );

              if (hasNullValue) {
                this.refetchAnswers();
              }
            }
            this.loading = false;
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    goBack() {
      this.$router.push({
        name: "FindPrecedentResult",
        params: { id: this.$route.params.id },
      });
    },
    async refetchAnswers() {
      if (this.isFetching) return;
      this.isFetching = true;
      const checkAllAnswers = () =>
        this.questions.every((q) => q.answer !== null);

      while (!checkAllAnswers()) {
        const updatedQuestions = await Promise.all(
          this.questions.map(async (element) => {
            if (element.answer == null) {
              try {
                const response = await usecase.GetSummarizedDocument(
                  this.$route.params.docId ?? ""
                );

                const updatedQuestion = response.data.data.questions.find(
                  (q) => q.id === element.id
                  // console.log("ID", element.id)
                );

                if (updatedQuestion && updatedQuestion.answer !== null) {
                  return {
                    ...element,
                    answer: updatedQuestion.answer,
                  };
                }
                // console.log("Element DATA HERE 1:", element);
                return element;
              } catch (error) {
                // console.error("Error fetching result:", error);
                return element;
              }
            }
            // console.log("Element DATA HERE 2:", element);
            return element;
          })
        );

        this.questions = updatedQuestions;
        // console.log("Questions", this.questions);

        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      this.isFetching = false;
    },
    submitQuery(question) {
      const formData = new FormData();
      formData.append("entity_type", "link");
      formData.append("entity_id", this.$route.params.docId);
      formData.append("language", "en");
      formData.append("location_id", 1);
      formData.append("question", question);
      formData.append("jurisdiction", JSON.stringify(this.wholeJuris));
      this.blockUser = true;
      nexa
        .DetailAsk(formData)
        .then((response) => {
          const responseData = response.data?.data;
          const responseQuestions = responseData?.questions;

          if (responseQuestions && Array.isArray(responseQuestions)) {
            responseQuestions.forEach((q) => {
              const existing = this.questions.find(
                (existing) => existing.id === q.id
              );
              if (existing) {
                existing.answer = q.answer;
              } else {
                this.questions.push(q);
              }
            });

            this.refetchAnswers();

            if (response.data.completedPercentage === 100) {
              this.blockUser = false;
            }
          } else {
            console.error(
              "Error: responseQuestions is undefined or not an array",
              response
            );
          }
        })
        .catch((error) => {
          console.error("Error submitting query:", error);
          this.blockUser = false;
        });
    },

    copy() {
      const contentElement = document.querySelector("#content");

      const clonedContent = contentElement.cloneNode(true);

      clonedContent.querySelectorAll("button").forEach((btn) => {
        btn.remove();
      });

      const copyContent = clonedContent.innerText;

      navigator.clipboard
        .writeText(copyContent)
        .then(() => {
          this.$toast.success("Copied as plain text successfully!");
          this.isCopied = true;
        })
        .catch(() => {
          this.$toast.error("Error copying to clipboard");
        })
        .finally(() => {
          setTimeout(() => {
            this.isCopied = false;
          }, 2000);
        });
    },

    exportToPDF() {
      try {
        this.$nextTick(() => {
          const followUpQuestion = `<h1 style="font-size: 24px; font-weight: bold;">Find Precedents: ${this.$route.params.id} - Summarized Document : ${this.$route.params.docId}</h1><br><hr>`;
          const tempDiv = document.createElement("div");

          // Style the temporary div
          tempDiv.style.width = "65%";
          tempDiv.style.maxWidth = "800px";
          tempDiv.style.margin = "20px auto";
          tempDiv.style.padding = "20px";
          tempDiv.style.border = "1px solid #ccc";
          tempDiv.style.borderRadius = "10px";
          tempDiv.style.backgroundColor = "#f9f9f9";
          tempDiv.style.boxShadow = "0 2px 10px rgba(0, 0, 0, 0.1)";
          tempDiv.style.fontFamily = "Arial, sans-serif";

          const contentElement = document.querySelector("#content");
          if (!contentElement) {
            this.$toast.error("Content not found");
            return;
          }

          const clonedContent = contentElement.cloneNode(true);

          clonedContent.querySelectorAll("button").forEach((btn) => {
            btn.remove();
          });
          clonedContent.querySelectorAll("img").forEach((img) => {
            img.remove();
          });
          tempDiv.innerHTML = followUpQuestion + clonedContent.outerHTML;

          document.body.appendChild(tempDiv);

          ExportDataPDF(
            tempDiv.innerHTML,
            false,
            `Find Precedents: ${this.$route.params.id} - Summarized Document : ${this.$route.params.docId}`
          );

          this.$toast.success("Successfully downloaded PDF");

          document.body.removeChild(tempDiv);
        });
      } catch (err) {
        this.$toast.error("Error exporting to PDF");
        console.error(err);
      }
    },
  },
  created() {
    this.fetchData();
  },
  copy() {},
};
</script>
<style scoped>
.marked-style ::v-deep(div > * > strong) {
  font-weight: 600 !important;
}

.marked-style ::v-deep(div > * > b) {
  font-weight: 600 !important;
}

.marked-style ::v-deep(div h1 *) {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
  font-weight: 600 !important;
}

.marked-style ::v-deep(div h2 *) {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
  font-weight: 600 !important;
}

.marked-style ::v-deep(div h3 *) {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  font-weight: 600 !important;
}

.marked-style ::v-deep(div p *) {
  font-size: 1rem !important;
  line-height: 1.75rem !important;
  font-weight: bold !important;
  color: #374151 !important;
}

.marked-style ::v-deep(div a *) {
  color: #3b82f6 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}

.marked-style ::v-deep(div ul *) {
  font-size: inherit !important;
  font-weight: bold !important;
}

.marked-style ::v-deep(div ol *) {
  font-size: inherit !important;
  font-weight: bold !important;
}

.marked-style ::v-deep(div li *) {
  font-size: inherit !important;
  font-weight: bold !important;
}

.marked-style ::v-deep(div blockquote strong) {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  font-weight: bold !important;
  color: #6b7280 !important;
}

.marked-style ::v-deep(div pre strong) {
  font-size: inherit !important;
  font-weight: bold !important;
  color: #d97706 !important;
}

.marked-style ::v-deep(div code strong) {
  font-size: 0.875rem !important;
  font-weight: bold !important;
  background-color: #f3f4f6 !important;
  color: #d97706 !important;
}

.marked-style ::v-deep(div h1) {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
  font-weight: 800 !important;
  margin-bottom: 1.5rem !important;
}

.marked-style ::v-deep(div h2) {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
  font-weight: 700 !important;
  margin-bottom: 1.25rem !important;
}

.marked-style ::v-deep(div h3) {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  font-weight: 700 !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div p) {
  font-size: 1rem !important;
  line-height: 1.75rem !important;
  margin-bottom: 1rem !important;
  color: #374151 !important;
}

.marked-style ::v-deep(div a) {
  color: #3b82f6 !important;
  text-decoration: underline !important;
}

.marked-style ::v-deep(div ul) {
  list-style-type: disc !important;
  margin-left: 1.25rem !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div ol) {
  list-style-type: decimal !important;
  margin-left: 1.25rem !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div li) {
  margin-bottom: 0.5rem !important;
}

.marked-style ::v-deep(div blockquote) {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  color: #6b7280 !important;
  border-left: 4px solid #d1d5db !important;
  padding-left: 1rem !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div pre) {
  background-color: #f3f4f6 !important;
  padding: 1rem !important;
  border-radius: 0.375rem !important;
  overflow-x: auto !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div code) {
  background-color: #f3f4f6 !important;
  padding: 0.25rem !important;
  border-radius: 0.25rem !important;
  font-size: 0.875rem !important;
  color: #d97706 !important;
}

.wrapper-chatbox {
  position: fixed;
  right: 2rem;

  width: 80%;
  z-index: 30;
  bottom: 2rem;
}

.claims-container {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  display: flex;
  flex-direction: column;
  font: 400 14px/1.5 Poppins, sans-serif;
  padding: 22px 24px;
  margin-bottom: 2rem;
}

.claims-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  flex-wrap: wrap;
}

.title-group {
  display: flex;
  min-width: 240px;
  align-items: center;
  gap: 8px;

  line-height: 28px;
}
.overlay-container {
  width: 25%;
  position: fixed;
  top: 8.7vh;
  right: 0;
  z-index: 1000;

  height: 100vh;
}
.primary-title {
  color: #0e4485;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem;
}

.secondary-title {
  color: #383a3e;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem;
}

.action-buttons {
  display: flex;
  align-items: center;
  gap: 16px;
}

.action-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  background: none;
}

.copy-btn {
  color: #0e4485;
}

.export-btn {
  background: #0e4485;
  color: #fff;
}

.action-icon {
  width: 18px;
  height: 18px;
}

.claims-content {
  margin: 32px 0;
}

.claims-description {
  color: #86888d;
  line-height: 24px;
  margin: 0;
}

.claims-table {
  border: 1px solid #86888d;
  border-radius: 8px;
  overflow: hidden;
}

.table-header {
  display: flex;
  background: #f2f3f3;
  border-bottom: 1px solid #86888d;
}

.column-title {
  flex: 1;
  min-width: 240px;
  padding: 9px 16px;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #383a3e;
}

.column-title:first-child {
  border-right: 1px solid #86888d;
}

.table-row {
  display: flex;
  border-bottom: 1px solid #86888d;
}

.row-label {
  flex: 1;
  min-width: 240px;
  padding: 9px 16px;
  border-right: 1px solid #86888d;
}

.row-content {
  flex: 1;
  min-width: 240px;
  padding: 9px 16px;
}

@media (max-width: 991px) {
  .claims-container {
    padding: 0 20px;
  }

  .claims-header,
  .table-header,
  .table-row {
    max-width: 100%;
    flex-wrap: wrap;
  }

  .title-group,
  .action-buttons,
  .column-title,
  .row-label,
  .row-content {
    max-width: 100%;
  }
}

.page-content {
  padding: 0;
}
.breadcrumb_header {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  background: #fff;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin: auto 0;
  span {
    font-size: 14px;
    color: var(--dark-grey) !important;
  }
}
.open_sidebar {
  height: 40px;
  background: var(--primary);
  display: inline-flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center; /* Ensure vertical alignment */
  gap: 8px;
  border-radius: 4px;
  cursor: pointer;
}

.open_sidebar span {
  color: #fff;
  display: flex; /* Ensure icon is aligned vertically */
  align-items: center; /* Align icon vertically within span */
}

.open_sidebar p {
  color: #fff !important;
  margin: 0; /* Remove any default margin to align the text properly */
  line-height: 1; /* Ensure text has the same line height as the icon */
  display: flex;
  align-items: center; /* Align text vertically */
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}
.back {
  color: var(--primary) !important;
  font-weight: 500;
  padding: 5px;
}
.col-md-10 {
  margin-top: 7rem;
}

.back-button {
  margin-top: 2rem;
  text-wrap: nowrap;
  padding-left: 2rem;
}
.content {
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  box-shadow: 0 2px 4px 0 rgba(170, 171, 175, 0.6);
  display: flex;
  flex-direction: column;
  padding: 22px 24px;
  margin-bottom: 20px;
}
@media (max-width: 991px) {
  .content {
    padding: 0 20px;
  }
}
</style>
<style scoped>
.content {
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  box-shadow: 0 2px 4px rgba(170, 171, 175, 0.6);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: Poppins, sans-serif;
  padding: 22px 24px;
}
@media (max-width: 991px) {
  .content {
    padding: 0 20px;
  }
}
.heading {
  display: flex;
  align-items: start;
  gap: 40px;
  font-weight: 500;
  justify-content: space-between;
  flex-wrap: wrap;
}
.heading-title {
  font-size: 24px !important;
  max-width: 60%;
  color: var(--Primary-Blue, #0e4485);
}
.buttons {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 14px;
}
.button,
.button-2 {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 8px;
}
.button {
  border-radius: 4px;
  color: var(--Primary-Blue, #0e4485);
}
.button-2 {
  border-radius: 4px;
  background: var(--Primary-Blue, #0e4485);
  color: var(--Neutral-White, #fff);
}
.icon {
  width: 18px;
}
.case-info {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}
.header {
  font-size: 16px;
  color: var(--Neutral-Black, #383a3e);
  font-weight: 500;
}
.info-card {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 16px;
  flex-wrap: wrap;
  padding: 4px 0;
}
.case {
  display: flex;
  align-items: start;
  flex-direction: column;
  min-width: 240px;
  flex: 1;
}
.case-title {
  color: var(--Neutral-Black, #383a3e);
  font-size: 16px;
  font-weight: 500;
}
.case-detail {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 18px !important;
  margin-top: 4px;
}
.divider-image {
  width: 100%;
  margin-top: 32px;
}
.summary,
.arguments,
.ruling-section {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}
.section-heading {
  font-size: 16px;
  color: var(--Primary-Blue, #0e4485);
  font-weight: 500;
}
.section-detail,
.issue,
.argument,
.reasoning-detail,
.conclusion-detail,
.takeaway-detail {
  color: var(--Neutral-Black, #383a3e);
  font-size: 14px;
  line-height: 24px;
}
.issues-list,
.reasoning-list,
.takeaway-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.issue-title,
.reasoning-title,
.conclusion-title,
.takeaway-title {
  font-weight: 500;
}
</style>
