<template>
  <ul class="case-list mb-4">
    <li
      v-for="(caseItem, index) in filteredData"
      :key="index"
      class="case-item"
    >
      <CaseCard
        v-if="typeof caseItem === 'object'"
        :caseData="caseItem"
        :number="index + 1"
        :caseType="caseType"
        :caseTitle="caseTitle"
        :abstract="abstract"
      />
      <article class="case-card" v-else>
        <span class="case-number">{{ index + 1 }}</span>
        <div class="case-info">
          <div class="case-content">
            <div class="case-header">
              <p class="case-title" style="font-style: italic">
                {{ caseItem }}
              </p>
            </div>
          </div>
        </div>
      </article>
      <img
        v-if="index < filtered_searched_data.length - 1"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/86f8a62eda915b32cea76a2453ac2cc7d4d6e8910796cc2f1e7f7051a59c2468?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
        alt=""
        class="divider"
      />
    </li>
  </ul>
</template>

<script>
import CaseCard from "./CaseCard.vue";

export default {
  name: "CaseList",
  components: {
    CaseCard,
  },
  props: {
    caseTitle: String,
    abstract: String,
    filtered_searched_data: {
      type: Array,
      required: true,
    },
    caseType: {
      type: String,
      required: true,
    },
  },

  computed : {
    filteredData() {
      return this.filterPrecedent();
    }
  },

  methods: {
    filterPrecedent() {
      return this.filtered_searched_data.filter(item => {
        return !(
          item.name === "None" && 
          item.date === "None" &&
          item.court === "None" 
        );
      });
    }
  },
  
};
</script>

<style scoped>
case-card {
  border-radius: 8px;
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 16px;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 8px 10px;
  cursor: pointer;
}

.highlighted {
  background-color: #e8f4ff;
}

.case-number {
  border-radius: 50%;
  background-color: #0e4485;
  min-height: 30px;
  color: #fff;
  text-align: center;
  width: 30px;
  height: 30px;
  padding: 0 10px;
  font: 500 14px/30px Poppins, sans-serif;
}

.case-info {
  display: flex;
  min-width: 240px;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  flex-basis: 20px;
}

.case-content {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.case-header {
  display: flex;
  min-width: 240px;
  flex-direction: column;
  font-family: Poppins, sans-serif;
  justify-content: flex-start;
  flex: 1;
  flex-basis: 12px;
}

.case-title {
  color: #383a3e;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

.case-meta {
  display: flex;
  margin-top: 4px;
  width: 100%;
  align-items: center;
  font-size: 14px;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.case-label {
  color: #86888d;
  font-weight: 400;
  line-height: 24px;
  margin-right: 10px;
}

.case-details {
  border-radius: 8px;
  border: 1px solid var(--Status-Blue, #1890ff) !important;
  background-color: var(--Neutral-White, #fff) !important;
  margin-top: 16px;
  padding: 8px 16px;
}

.case-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.case-item {
  margin-top: 24px;
}

.divider {
  width: 100%;
  stroke-width: 1px;
  stroke: #d1d2d5;
  margin-top: 24px;
}

@media (max-width: 991px) {
  .divider {
    max-width: 100%;
  }
}
</style>
