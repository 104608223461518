<template>
  <div id="container">
    <div
      v-for="(f, id) in displayData"
      :key="id"
      :class="['frame', !f.valid && 'failed']"
    >
      <div class="file">
        <!-- <span v-if="f.ext === 'pdf'" class="material-symbols-rounded">picture_as_pdf</span>
        <span v-else-if="['png', 'jpg', 'jpeg'].includes(f.ext)" class="material-symbols-rounded">image</span>
        <span v-else class="material-symbols-rounded">description</span> -->
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/ebdf51739feacebebfd543d3411bfa2a651671ccdd32c00d535ba15c193a6988?placeholderIfAbsent=true&apiKey=e830e5794ba84973b9eb690ba825e1f8"
          height="18"
          width="18"
        />

        <div>
          <p class="ellipsis ps-1" @click="toggleEllipse">{{ f.name }}</p>
          <!-- <p :class="f.valid ? 'grey' : 'red'">{{ f.valid ? formatFileSize(f.file.size) : "Unsupported file format" }}</p> -->
        </div>

        <div v-show="!display" class="footer" v-if="fileType">
          <span class="material-symbols-rounded" @click="removeFile2(id)"
            >close</span
          >
        </div>

        <div v-show="!display" class="footer" v-else>
          <span class="material-symbols-rounded" @click="removeFile(id)"
            >close</span
          >
        </div>
      </div>

      <span
        v-show="display"
        class="material-symbols-rounded"
        @click="$bvModal.show(`delete-modal-${id}`)"
        >delete</span
      >

      <!-- Delete Modal -->
      <b-modal
        :id="`delete-modal-${id}`"
        custom-class="delete-modal"
        hide-header
        hide-footer
        centered
      >
        <div class="delete-prompt">
          <p>
            <b>Are you sure you want to delete this entry?</b><br />
            This action cannot be undone.
          </p>
        </div>
        <div class="delete-prompt-options">
          <button
            class="btn-outlined"
            @click="$bvModal.hide(`delete-modal-${id}`)"
          >
            Cancel
          </button>
          <button class="btn-danger" @click="deleteEntry(id)">Delete</button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { toggleEllipse } from "../../store/utils";

export default {
  props: {
    // Array of unprocessed files
    files: { type: Array },

    // Array of accepted file extensions
    accept: { type: Array },

    // Enter display only state
    display: { type: String },

    // Single file display
    single: { type: Boolean, default: false },

    fileType: { type: Boolean, default: true }
  },

  data() {
    return {
      // Store the processed files
    displayData: [],
    };
  },

  computed: {
    totalSize() {
      return this.displayData.reduce((total, f) => total + f.file.size, 0);
    },
  },

  // watch: {
  //   files() {
  //     if (!this.files.length) {
  //       this.displayData = [];
  //     }

  //     if (!this.display) {
  //       const duplicated = this.processFile();

  //       if (duplicated) {
  //         this.$toast.warning("Duplicated file will not be processed");
  //       }
  //     }
  //   },

  //   displayData() {
  //     this.emitData();
  //   },
  // },

    watch: {
      files: {
        immediate: true,
        handler(newFiles) {
          // Check if files have changed before processing
          if (!newFiles || newFiles.length === this.displayData.length) return;

          if (!this.display) {
            const duplicated = this.processFile();
            if (duplicated) {
              this.$toast.warning("Duplicated file will not be processed");
            }
          }
        },
      },
      displayData: {
        handler() {
          this.emitData();
        },
        deep: true,
      },
    },

  methods: {
    toggleEllipse(e) {
      toggleEllipse(e);
    },

    resolveExt(filename) {
      const ext = filename.split(".").pop().toLowerCase();
      return [this.accept.includes(ext), ext];
    },

    formatFileSize(size) {
      return size < 1024
        ? `${size} bytes`
        : size < 1024 * 1024
        ? `${(size / 1024).toFixed(2)} KB`
        : `${(size / (1024 * 1024)).toFixed(2)} MB`;
    },

    processFile() {
      const maxFiles = 1000;
      const maxSize = 1024 * 1024 * 1024;
      let duplicated = false;
      let newFiles = [];
      let totalSize = this.totalSize;

      this.files
        .filter((f) => {
          const alreadyExists = this.displayData
            .map((d) => d.file.name)
            .includes(f.name);

          if (alreadyExists) {
            duplicated = true;
          }

          return !alreadyExists;
        })
        .forEach((f) => {
          const resolved = this.resolveExt(f.name);
          const fileSize = f.size;

          if (newFiles.length < maxFiles && totalSize + fileSize <= maxSize) {
            newFiles.push({
              valid: resolved[0],
              ext: resolved[1],
              file: f,
              name: f.name,
            });
            totalSize += fileSize;
          } else {
            if (newFiles.length >= maxFiles) {
              this.$toast.warning(
                "You can only upload a maximum of 1000 files."
              );
            }

            if (totalSize + fileSize > maxSize) {
              this.$toast.warning("Total size must not exceed 1 GB.");
            }
          }
        });

      if (this.single) {
        this.displayData.length = 0;
        this.displayData.push(...newFiles);
      } else {
        this.displayData.push(...newFiles);
      }
      
      return duplicated;
    },

    removeFile(id) {
      this.$emit("remove-file", id,this.displayData);
    },

    removeFile2(id) {
      this.displayData.splice(id, 1);
      this.emitData();
    },

    deleteEntry(id) {
      this.$bvModal.hide(`delete-modal-${id}`);
      this.displayData.splice(id, 1);
      this.emitData(true);
    },

    emitData(del = null) {
      this.$emit(del ? "delete-entry" : "output-files", this.displayData);
    },
  },

  created() {
    this.processFile(); // Process files on component creation
  },
};
</script>

<style scoped>
p {
  margin: 0px;
  line-height: 20px;
}

.grey {
  color: var(--dark-grey) !important;
}

.red {
  color: var(--status-red) !important;
}

#container {
  display: flex;
  flex-wrap: wrap;
  margin: 0.5rem 0rem;
}

.frame {
  margin-bottom: 12px;
  display: flex;
  width: 50%;
}

span {
  font-size: 18px;
  color: var(--Neutral-Dark-Grey, #86888d);
  cursor: pointer;
}

.file {
  /* padding: 10px; */
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  /* background-color: var(--interface-grey); */
}

.file > div {
    width: calc(100% - 50px - 18px);
  }

.file > div.footer {
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: flex-end;
}

.ellipsis {
  color: var(--Neutral-Dark-Grey, #86888d) !important;
}
</style>

<!-- Failed Input Styles -->
<style scoped>
.failed .file {
  border: 1px solid var(--status-red);
  background-color: #ff4d4f0d;
}

.failed .file > .material-symbols-rounded,
.failed .file > .material-symbols-rounded {
  color: #ff2400 !important;
}
</style>

<!-- Delete Modal Styles -->
<style scoped>
.delete-prompt {
  margin: 8px 8px 20px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.delete-prompt p {
  margin-bottom: 0px;
  text-align: center;
}

.delete-prompt p b {
  font-weight: 500;
  font-size: 16px;
  color: var(--neutral-black);
}

.delete-prompt-options {
  display: flex;
  justify-content: space-between;
  margin: 0px 8px 8px 8px;
}

.delete-prompt-options button {
  width: calc(50% - 4px);
  height: 32px;
}
</style>
