<template>
  <div id="app"><router-view :key="$route.path" /></div>
</template>
<script>
export default { name: "App" };
</script>

<!-- This file is basically a .css file -->

<!-- General -->
<style>
/* Font Style: Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

#app {
  width: 100vw;
  height: 100vh;
  font-family: "Poppins", sans-serif !important;
  background-color: var(--interface-grey);
}

.page-content {
  height: calc(100vh - 64px);
  width: 100%;
  padding: 20px;
  overflow: auto;
}
/* In a global CSS file */
.quillEditor .ql-toolbar.ql-snow {
  border-radius: 8px 0;
  border-bottom: 1px solid #d1d2d5;
  background: #f2f3f3;
}

.quillEditor .ql-container.ql-snow {
  background: #fafbfc;
  border: none;
}
</style>

<!-- Utility Classes -->
<style>
.material-symbols-rounded.fill {
  font-variation-settings: "FILL" 1;
}

.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.sm-shadow {
  box-shadow: 0px 0px 10px 0px #e3e5eb99;
}

.arrow {
  -webkit-transition: transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
  color: var(--neutral-black);
  user-select: none;
}

*[aria-expanded="true"] .arrow {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.loading-badge {
  display: block;
  width: 90%;
  height: 20px;
  margin: 3px 0px;
  border-radius: 12px;
  background-color: #f3f3f3;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis[show="true"] {
  white-space: normal;
  overflow: visible;
  text-overflow: initial;
}

.disabled {
  color: var(--mid-grey) !important;
  cursor: not-allowed !important;
}

.page-item.disabled {
  cursor: default !important;
}

.material-symbols-rounded.info:hover {
  cursor: pointer;
  font-variation-settings: "FILL" 1;
}
</style>

<!-- Custom Card -->
<style>
.card {
  background-color: white !important;
  border: 1px solid var(--light-grey) !important;
  border-radius: 8px !important;
}

.card-header {
  background-color: white;
}
</style>

<!-- Custom Buttons -->
<style>
.small-button {
  font-family: "Poppins", sans-serif !important;
  color: var(--neutral-black) !important;
  font-size: 14px !important;
  font-weight: 500;
  line-height: 20px;
}

.btn-primary {
  display: flex;
  align-items: center;
  padding: 4px 16px;
  color: white;
  background-color: var(--primary);

  &:hover {
    background-color: var(--dark-blue);
  }

  &:disabled {
    color: var(--mid-grey) !important;
    background-color: var(--light-grey) !important;
    cursor: not-allowed;
  }
}

.btn-outlined {
  border: 1px solid var(--mid-grey);
  padding: 4px 16px;

  &:hover {
    background-color: var(--light-grey);
  }
}

.btn-transparent {
  padding: 2px 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: var(--primary) !important;

  &:hover {
    background-color: var(--light-grey);
  }
}

.btn-danger {
  padding: 4px 16px;
  color: white;
  background-color: var(--status-red);

  &:hover {
    background-color: var(--dark-red);
  }
}
</style>

<!-- Placeholder -->
<style>
.empty-placeholder {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.empty-placeholder p {
  color: var(--dark-grey) !important;
}
</style>

<!-- Dropdown Menu // for tableList -->
<style>
.DROPDOWN-MENU {
  opacity: 1 !important;
}

.DROPDOWN-MENU .tooltip-inner {
  background-color: white;
  color: var(--neutral-black);
  border: 1px solid var(--light-grey);
  box-shadow: 0px 0px 10px 0px #e3e5eb99;
  padding: 10px 0px;
}

.DROPDOWN-MENU .tooltip-inner .dropdown-option {
  height: 48px;
  padding: 12px;
  display: flex;
  cursor: pointer;

  &:hover {
    background-color: var(--light-grey);
  }
}

.DROPDOWN-MENU .tooltip-inner .dropdown-option span {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.DROPDOWN-MENU .tooltip-inner .dropdown-option p {
  font-weight: 500;
  margin-bottom: 0px;
}
</style>

<!-- Pagination -->
<style>
.pagination.b-pagination {
  margin: 0px;
  height: 32px;
}

.page-item button,
.page-item span {
  height: 100%;
  min-width: 32px;
  border-radius: 8px;
  margin-right: 16px;
  padding: 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border: 0px;
  background-color: transparent;
  color: var(--neutral-black);
}

.page-item button:hover {
  color: var(--primary);
  background-color: var(--light-blue);
}

.page-item.active button {
  color: white;
  background-color: var(--primary);
}

/* arrow button */
.page-item button[role="menuitem"],
.page-item span[role="menuitem"] {
  font-size: 20px;
}

.page-item span[role="menuitem"] {
  display: none;
}

/* separator */
.page-item[role="separator"] span {
  min-width: 0px;
  color: var(--dark-grey);
  background-color: transparent;
}
</style>

<!-- -- -- -- -- -- -->
<!-- -- -- -- -- -- -->

<!-- Color Theme -->
<style>
:root {
  --neutral-black: #383a3e;
  --dark-grey: #86888d;
  --mid-grey: #d1d2d5;
  --light-grey: #f2f3f3;
  --interface-grey: #fafbfc;

  --dark-blue: #000046;
  --primary: #0e4485;
  --status-blue: #1890ff;
  --light-blue: #e7ecf3;

  --status-red: #ff4d4f;
  --dark-red: #c2292a;
  --light-green: #f4f9ff;
  --status-green: #52c41a;
  --status-violet: #c319ff;
}
</style>

<!-- -- -- -- -- -- -->
<!-- -- -- -- -- -- -->

<!-- Text Default Style -->
<style>
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
div,
span,
button {
  font-family: "Poppins", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6
{
  color: var(--neutral-black);
}

/* h1 {
  font-size: 56px !important;
  font-weight: 600 !important;
  line-height: 84px;
}

h2 {
  font-size: 32px !important;
  font-weight: 600 !important;
  line-height: 48px;
}

h3 {
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 36px;
}

h4 {
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 28px;
}

h5 {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px;
}

h6 {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
} */

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

textarea.form-control {
  border: 1px solid var(--light-grey);
  color: var(--neutral-black);
  background-color: var(--interface-grey);
}
</style>

<!-- Table Default Style -->
<style>
table {
  border-radius: 8px;
  border: 1px solid var(--light-grey);
  overflow: hidden;

  thead {
    display: grid;
  }

  tbody {
    display: block;
    overflow-y: auto;
    background-color: var(--light-grey);

    /*
      Full Screen Height (100vh)
      - Header.height (64px)
      - Page.padding (40px = 20px * top & bottom)
      - TableHeader.height (~40px)
      - Pagination.height (52px)
    */
    max-height: calc(100vh - 64px - 40px - 40px - 52px);
  }
}

tbody:has(tr:hover) p {
  color: var(--dark-grey) !important;
}

tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  background-color: white;

  &:hover {
    background-color: var(--interface-grey);

    td p {
      color: var(--neutral-black) !important;
    }
  }
}

tr td {
  height: 46px;
  padding: 10px 16px 5px 16px;
  border-bottom: 1px solid var(--light-grey) !important;
  align-content: start;
  overflow-wrap: break-word;
}

th {
  padding: 8px 16px;
  background-color: var(--light-grey) !important;

  button {
    text-align: left;
  }
}
</style>

<!-- Misc Default Style -->
<style>
body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

button {
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--neutral-black);
  border-radius: 4px !important;
  cursor: pointer;
  appearance: none;
  border: transparent;
  background: none;
  transition: all 0.2s ease;
}

input {
  outline: 0px;
  color: var(--neutral-black);
  border: 0px;
}
</style>

<!-- ScrollBar Style -->
<style lang="css">
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 10px;
  border: 2px solid white;
  cursor: pointer;
}
</style>
