<template>
  <div>
    <div class="breadcrumb_header">
      <div class="breadcrumb ms-2">
        <h4 style="margin: 0; padding-left: 10px">NeXa</h4>
        <span class="divider"></span>
        <h6 class="breadcrumb-text">
          Use Cases / Summarize a Case / {{ this.$route.params.id }}
          <span v-if="selectedFunction.trim() != ''"
            >/ {{ selectedFunction }}</span
          >
        </h6>
      </div>
      <div>
        <button @click="toggleSidebar" class="open_sidebar me-2">
          <span class="material-symbols-rounded icon"
            >keyboard_double_arrow_left</span
          >
          <p>Open Sidebar</p>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1 mt-3 p-0">
        <div
          class="d-flex justify-content-end cursor-pointer back"
          style="position: sticky; top: 24px"
          @click="goBack"
        >
          <span class="material-symbols-rounded" style="margin: 0"
            >arrow_back</span
          >
          <span style="margin: 0 0 0 8px">Back</span>
        </div>
      </div>
      <div class="col-md-10 mt-5 p-0">
        <PageLoader
          v-if="loading"
          :loading="loading"
          :extra_msg="loading_message"
          :completedPercentage="duration"
        />
        <!-- Add v-else in div below -->

        <div class="page-container" v-else>
          <div class="main-content">
            <section
              class="claims-container"
              v-for="q in questions"
              :key="`questions-${q.id}`"
              :id="`q-${q.id}`"
            >
              <FollowUpQuestionBox :q="q" />
            </section>
            <section class="files-section">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: start;
                "
              >
                <div class="section-header">Contract Due Diligence</div>
                <div class="action-buttons" v-if="selectedFunction != ''">
                  <button
                    :disabled="isCopied"
                    class="copy-button"
                    aria-label="Copy content"
                    @click="copy"
                  >
                    <img
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/b44c86e6cccfd119bd0ae151720d5bcb372679b6fd25fe58afd18b9b6a9484b5?placeholderIfAbsent=true&apiKey=411e2ec61bdb45ecb3af3338badbc5df"
                      alt=""
                      class="button-icon"
                    />
                    <span class="button-text">{{
                      isCopied ? "Copied" : "Copy"
                    }}</span>
                  </button>

                  <b-dropdown
                    size="sm"
                    id="dropdown-right"
                    right
                    offset="-9"
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                    @click="toggleDropdown"
                  >
                    <template #button-content>
                      <button class="action-button export-button">
                        <span class="button-label">Export</span>
                        <img
                          :class="{ 'rotate-180': isDropdown }"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/d12668b437286b44ce443817869f8a85013f194276d09cf30b58481266006963?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
                          alt="Export Icon"
                          class="icon"
                        />
                      </button>
                    </template>

                    <b-dropdown-item class="ms-1 me-1" @click="exportToPDF">
                      <img class="me-2" src="@/assets/icon/pdf-icon.svg" />
                      <span class="dropdown-text">Export as PDF</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      class="ms-1 me-1"
                      @click="exportToExcel"
                      v-if="selectedFunction != 'Concise Summary'"
                    >
                      <img class="me-2" src="@/assets/icon/excel-icon.svg" />
                      <span class="dropdown-text">Export as Excel</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <button class="view-files-btn">View Uploaded Files</button>
              <div class="security-notice">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/ee5e1527866344adfafc18849c89c62dd76f2b9c7ded99add1ae660ece66da12?placeholderIfAbsent=true&apiKey=411e2ec61bdb45ecb3af3338badbc5df"
                  alt="Security icon"
                  class="security-icon"
                />
                <p class="security-text" style="padding: 0; margin: 0">
                  Your files will be securely stored in NeXa, accessible only to
                  you and your workspace admins, and you can delete them at any
                  time.
                </p>
              </div>
            </section>

            <section
              class="features-section"
              style="margin-bottom: 8rem"
              v-if="selectedFunction.trim() == ''"
            >
              <div class="features-header" style="margin-bottom: 2rem">
                <div class="header-content">
                  <h1 class="title">Ask NeXa</h1>
                  <p class="subtitle">
                    Here's what NeXa can do with your uploaded files:
                  </p>
                </div>
              </div>

              <section class="use-cases" aria-label="Legal Use Cases">
                <article
                  v-for="(useCase, index) in useCases"
                  :key="index"
                  class="use-case-card"
                >
                  <div
                    style="cursor: pointer"
                    class="use-case-content"
                    @click="selectedFunction = useCase.title"
                  >
                    <h3 class="use-case-title">{{ useCase.title }}</h3>
                    <p class="use-case-description">
                      {{ useCase.description }}
                    </p>
                  </div>
                </article>
              </section>
            </section>

            <section
              v-else
              style="
                background-color: white;
                box-shadow: 0px 4px 10px 0px rgba(227, 229, 235, 0.6);
                padding: 24px;
              "
            >
              <div
                v-if="selectedFunction == 'Concise Summary'"
                class="pdf-content"
              >
                <span>Concise Summary</span>
                <vue-marked>{{ document_review.summary }}</vue-marked>
              </div>
              <div
                v-if="selectedFunction == 'Risk Analysis'"
                class="pdf-content"
              >
                <h3 style="margin-top: 20px; margin-bottom: 40px">
                  Risk Analysis
                </h3>
                <table class="table-content">
                  <thead>
                    <tr>
                      <th
                        v-for="key in Object.keys(document_review.risk[0])"
                        :key="key"
                      >
                        {{ key }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(row, index) in document_review.risk"
                      :key="index"
                    >
                      <td v-for="value in Object.values(row)" :key="value">
                        {{ value }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                v-if="selectedFunction == 'Regulatory Compliance Review'"
                class="pdf-content"
              >
                <h3 style="margin-top: 20px; margin-bottom: 40px">
                  Regulatory Compliance Review and Summary
                </h3>

                <table class="table-content">
                  <thead>
                    <tr>
                      <th
                        v-for="key in Object.keys(document_review.review[0])"
                        :key="key"
                      >
                        {{ key }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(row, rowIndex) in document_review.review"
                      :key="rowIndex"
                    >
                      <td
                        v-for="(value, keyIndex) in Object.values(row)"
                        :key="keyIndex"
                      >
                        {{ value }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                v-if="selectedFunction == 'Due Diligence Review'"
                class="pdf-content"
              >
                <h3 style="margin-top: 20px; margin-bottom: 40px">
                  Due Diligence Review
                </h3>
                <table class="table-content">
                  <thead>
                    <tr>
                      <th
                        v-for="key in Object.keys(due_diligence[0])"
                        :key="key"
                      >
                        {{ key }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(row, rowIndex) in due_diligence"
                      :key="rowIndex"
                    >
                      <td
                        v-for="(value, keyIndex) in Object.values(row)"
                        :key="keyIndex"
                      >
                        {{ value }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                v-if="selectedFunction == 'Clause Comparison'"
                class="pdf-content"
              >
                <div class="empty-placeholder" v-if="comparison == null">
                  <span class="min-margin"></span>
                  <img src="@/assets/misc/empty.svg" alt="No Content" />
                  <p>
                    Comparison Not Possible<br />
                    We couldn’t perform a comparison because only one file was
                    uploaded.
                    <br />
                    Please upload an additional file to proceed.
                  </p>
                </div>
                <div v-else>
                  <h3 style="margin-top: 20px; margin-bottom: 40px">
                    Clause Comparison
                  </h3>
                  <table class="table-content">
                    <thead>
                      <tr>
                        <th
                          v-for="key in Object.keys(comparison[0])"
                          :key="key"
                        >
                          {{ key }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in comparison" :key="index">
                        <td v-for="value in item" :key="value">
                          {{ value }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>
    <div v-if="!loading" class="wrapper-chatbox">
      <ChatBox @chat-submit="submitQuery" />
    </div>
    <sidebar
      v-clickaway="toggleSidebar"
      v-if="sidebarCollapsed"
      @toggle-sidebar="toggleSidebar"
      :uploadFiles="files"
    ></sidebar>
  </div>
</template>
<script>
import countryImages from "../../../assets/country/index";
import PageLoader from "../../PageLoader.vue";
import sidebar from "../sidebar.vue";
import usecase from "@/store/usecase.js";
import { ExportDataPDF } from "@/store/utils";
import VueMarked from "vue-marked";
import ChatBox from "../../../components/input/ChatBox.vue";
import FollowUpQuestionBox from "../../../components/input/FollowUpQuestionBox.vue";
import nexa from "@/store/nexa";
import * as XLSX from "xlsx";
export default {
  components: {
    sidebar,
    VueMarked,
    PageLoader,
    ChatBox,
    FollowUpQuestionBox,
  },
  data() {
    return {
      useCases: [
        {
          title: "Regulatory Compliance Review",
          description:
            "Analyze contracts against relevant regulatory frameworks (e.g., GDPR, HIPAA), flagging areas of non-compliance and suggesting corrective actions.",
        },
        {
          title: "Risk Analysis",
          description:
            "Identify and assess potential legal risks or ambiguities in contract terms, aligned with jurisdiction-specific regulations and industry standards.",
        },
        {
          title: "Due Diligence Review",
          description:
            "Conduct a comprehensive due diligence review, detecting missing clauses, ambiguities, or inconsistencies with legal norms and standards.",
        },
        {
          title: "Clause Comparison",
          description:
            "Compare key clauses across multiple contract versions, highlighting changes, potential legal implications, and impacts on obligations or liabilities.",
        },
        // {
        //   title: "Draft Revisions",
        //   description:
        //     "Automatically generate legally sound revisions to contract clauses, ensuring compliance with client-specific requirements and legal frameworks.",
        // },
        {
          title: "Concise Summary",
          description:
            "Generate a clear, concise summary of the contract's key elements, simplifying complex legal language for client understanding and approval.",
        },
      ],
      loading_message: "Please wait a moment while we process it.",
      duration: 0,
      loading: true,
      interval: null,
      sidebarCollapsed: false,
      wholeJuris: {},
      questions: [],
      lawbotVisible: false,
      isCopied: false,
      isDropdown: false,
      showSummary: false,
      countryImages,
      files: [],
      values: {},
      documentBrief: {},
      selectedFunction: "",
      document_review: [],
      comparison: null,
      due_diligence: [],
    };
  },
  methods: {
    toggleDropdown() {
      this.isDropdown = !this.isDropdown;
    },
    toggleSummary() {
      this.showSummary = !this.showSummary;
    },
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    goBack() {
      if (this.selectedFunction.trim() != "") {
        this.selectedFunction = "";
      } else {
        this.$router.push({ name: "ContractDiligence" });
      }
    },
    copy() {
      if (this.selectedFunction == "Concise Summary") {
        const contentElement = document.querySelector(".pdf-content").innerText;

        navigator.clipboard
          .writeText(contentElement)
          .then(() => {
            this.$toast.success("Copied as plain text successfully!");
            this.isCopied = true;
          })
          .catch(() => {
            this.$toast.error("Error copying to clipboard");
          })
          .finally(() => {
            setTimeout(() => {
              this.isCopied = false;
            }, 2000);
          });
      } else {
        const tableElement = document.querySelector(".table-content");
        if (tableElement) {
          const rows = Array.from(tableElement.querySelectorAll("tr"));
          const tableData = rows
            .map((row) => {
              const cells = Array.from(row.querySelectorAll("td, th"));
              return cells.map((cell) => cell.innerText).join("\t");
            })
            .join("\n");

          navigator.clipboard
            .writeText(tableData)
            .then(() => {
              this.$toast.success(
                `Table ${this.selectedFunction} copied to clipboard successfully!`
              );
              this.isCopied = true;
            })
            .catch(() => {
              this.$toast.error("Error copying table to clipboard");
            })
            .finally(() => {
              setTimeout(() => {
                this.isCopied = false;
              }, 2000);
            });
        } else {
          this.$toast.error("Table content not found");
        }
      }
    },

    exportToPDF() {
      try {
        this.$nextTick(() => {
          const followUpQuestion = `<h1 style="font-size: 24px; font-weight: bold;">Contract Due Diligence: ${this.$route.params.id} - ${this.selectedFunction}</h1><br><hr>`;
          const tempDiv = document.createElement("div");

          // Style the temporary div
          tempDiv.style.width = "65%";
          tempDiv.style.maxWidth = "800px";
          tempDiv.style.margin = "20px auto";
          tempDiv.style.padding = "20px";
          tempDiv.style.border = "1px solid #ccc";
          tempDiv.style.borderRadius = "10px";
          tempDiv.style.backgroundColor = "#f9f9f9";
          tempDiv.style.boxShadow = "0 2px 10px rgba(0, 0, 0, 0.1)";
          tempDiv.style.fontFamily = "Arial, sans-serif";

          const contentElement =
            document.querySelector(".pdf-content").innerHTML;
          if (!contentElement) {
            this.$toast.error("Content not found");
            return;
          }

          tempDiv.innerHTML = followUpQuestion + contentElement;

          document.body.appendChild(tempDiv);

          ExportDataPDF(
            tempDiv.innerHTML,
            false,
            `Contract Due Diligence: ${this.$route.params.id} - ${this.selectedFunction}`
          );

          this.$toast.success("Successfully downloaded PDF");

          document.body.removeChild(tempDiv);
        });
      } catch (err) {
        this.$toast.error("Error exporting to PDF");
        console.error(err);
      }
    },
    exportToExcel() {
      const tableElement = document.querySelector(`.table-content`);

      if (!tableElement) {
        console.error("No table found in the provided HTML string.");
        return;
      }

      const rows = Array.from(tableElement.querySelectorAll("tr"));
      let csv = "";

      rows.forEach((row) => {
        const cols = Array.from(row.querySelectorAll("td, th"));

        if (
          cols.length > 0 &&
          cols.some((col) => col.innerText.trim() !== "")
        ) {
          const csvRow = cols
            .map((col) => `"${col.innerText.replace(/"/g, '""')}"`)
            .join(",");
          csv += csvRow + "\n";
        }
      });

      const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const csvLink = document.createElement("a");
      const csvUrl = URL.createObjectURL(csvBlob);

      csvLink.setAttribute("href", csvUrl);
      csvLink.setAttribute(
        "download",
        `Contract Due Diligence: ${this.$route.params.id} - ${this.selectedFunction}`
      );

      document.body.appendChild(csvLink);

      csvLink.click();

      document.body.removeChild(csvLink);
      URL.revokeObjectURL(csvUrl);

      const wb = XLSX.utils.table_to_book(tableElement, { sheet: "Sheet1" });

      XLSX.writeFile(
        wb,
        `Contract Due Diligence: ${this.$route.params.id} - ${this.selectedFunction}.xlsx`
      );

      this.$toast.success(
        `Exported Analyze Pleading ${this.$route.params.id} to CSV and XLSX`
      );
    },
    async refetchAnswers() {
      if (this.isFetching) return;
      this.isFetching = true;
      const checkAllAnswers = () =>
        this.questions.every((q) => q.answer !== null);

      while (!checkAllAnswers()) {
        const updatedQuestions = await Promise.all(
          this.questions.map(async (element) => {
            if (element.answer == null) {
              try {
                const response = await usecase.GetResult(
                  this.$route.params.id ?? ""
                );

                const updatedQuestion = response.data.data.questions.find(
                  (q) => q.id === element.id
                  // console.log("ID", element.id)
                );

                if (updatedQuestion && updatedQuestion.answer !== null) {
                  return {
                    ...element,
                    answer: updatedQuestion.answer,
                  };
                }
                // console.log("Element DATA HERE 1:", element);
                return element;
              } catch (error) {
                // console.error("Error fetching result:", error);
                return element;
              }
            }
            // console.log("Element DATA HERE 2:", element);
            return element;
          })
        );

        this.questions = updatedQuestions;
        // console.log("Questions", this.questions);

        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      this.isFetching = false;
    },
    submitQuery(question) {
      const formData = new FormData();
      formData.append("entity_type", "campaign");
      formData.append("entity_id", this.$route.params.id);
      formData.append("language", "en");
      formData.append("location_id", 1);
      formData.append("question", question);
      formData.append("jurisdiction", JSON.stringify(this.wholeJuris));
      this.blockUser = true;
      nexa
        .DetailAsk(formData)
        .then((response) => {
          const responseData = response.data?.data;
          const responseQuestions = responseData?.questions;

          if (responseQuestions && Array.isArray(responseQuestions)) {
            responseQuestions.forEach((q) => {
              const existing = this.questions.find(
                (existing) => existing.id === q.id
              );
              if (existing) {
                existing.answer = q.answer;
              } else {
                this.questions.push(q);
              }
            });

            this.refetchAnswers();

            if (response.data.completedPercentage === 100) {
              this.blockUser = false;
            }
          } else {
            console.error(
              "Error: responseQuestions is undefined or not an array",
              response
            );
          }
        })
        .catch((error) => {
          console.error("Error submitting query:", error);
          this.blockUser = false;
        });
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  created() {
    usecase.GetResult(this.$route.params.id ?? "").then((response) => {
      const DATA = response.data.data;
      if (DATA.completedPerCentage >= 100) {
        this.duration = DATA.completedPerCentage;
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }

      if (DATA.completedPerCentage < 100) {
        if (DATA.completedPerCentage >= 0) {
          this.duration = DATA.completedPerCentage;
          this.files = DATA.questionDocuments;
        }

        this.interval = setInterval(() => {
          usecase.GetResult(this.$route.params.id).then((res) => {
            if (res.data.data.completedPerCentage >= 100) {
              this.duration = res.data.data.completedPerCentage;
              clearInterval(this.interval);
              location.reload();
            } else if (res.data.data.completedPerCentage > 0) {
              this.duration = res.data.data.completedPerCentage;
            }
          });
        }, 10000);
      } else {
        this.questions = DATA.questions || [];
        this.document_review =
          JSON.parse(
            DATA.values.find((el) => el.key === "nexa_documents_review").value
          )[0] || [];

        this.comparison =
          JSON.parse(
            DATA.values.find((el) => el.key === "nexa_comparison").value
          ) || null;
        this.due_diligence =
          JSON.parse(
            DATA.values.find((el) => el.key === "nexa_due_diligence").value
          ) || [];

        this.wholeJuris[DATA.jurisdiction] = ["all"];
        console.log(this.wholeJuris);
        if (this.questions.length > 0) {
          const hasNullValue = this.questions.some((q) => q.answer === null);

          if (hasNullValue) {
            this.refetchAnswers();
          }
        }
      }
    });
  },
};
</script>

<style scoped>
.actions {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 14px;
}
.action-button {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
}
.copy-button {
  color: #0e4485;
}
.export-button {
  background: #0e4485;
  color: #fff;
}

.claims-container {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  display: flex;
  flex-direction: column;
  font: 400 14px/1.5 Poppins, sans-serif;
  padding: 22px 24px;
  margin-bottom: 2rem;
  transition: height 0.3s ease;
  cursor: pointer;
}

.empty-placeholder {
  min-height: 40%;
  width: 500px;

  .min-margin {
    display: inline-block;
    height: 110px;
  }

  img {
    margin-top: auto;
    margin-bottom: 24px;
  }

  p {
    text-align: center;
    margin-bottom: 32px;
  }
}
.marked-style ::v-deep(div > * > strong) {
  font-weight: 600 !important;
}

.marked-style ::v-deep(div > * > b) {
  font-weight: 600 !important;
}

.marked-style ::v-deep(div h1 *) {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
  font-weight: 600 !important;
}

.marked-style ::v-deep(div h2 *) {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
  font-weight: 600 !important;
}

.marked-style ::v-deep(div h3 *) {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  font-weight: 600 !important;
}

.marked-style ::v-deep(div p *) {
  font-size: 1rem !important;
  line-height: 1.75rem !important;
  font-weight: bold !important;
  color: #374151 !important;
}

.marked-style ::v-deep(div a *) {
  color: #3b82f6 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}

.marked-style ::v-deep(div ul *) {
  font-size: inherit !important;
  font-weight: bold !important;
}

.marked-style ::v-deep(div ol *) {
  font-size: inherit !important;
  font-weight: bold !important;
}

.marked-style ::v-deep(div li *) {
  font-size: inherit !important;
  font-weight: bold !important;
}

.marked-style ::v-deep(div blockquote strong) {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  font-weight: bold !important;
  color: #6b7280 !important;
}

.marked-style ::v-deep(div pre strong) {
  font-size: inherit !important;
  font-weight: bold !important;
  color: #d97706 !important;
}

.marked-style ::v-deep(div code strong) {
  font-size: 0.875rem !important;
  font-weight: bold !important;
  background-color: #f3f4f6 !important;
  color: #d97706 !important;
}

.marked-style ::v-deep(div h1) {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
  font-weight: 800 !important;
  margin-bottom: 1.5rem !important;
}

.marked-style ::v-deep(div h2) {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
  font-weight: 700 !important;
  margin-bottom: 1.25rem !important;
}

.marked-style ::v-deep(div h3) {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  font-weight: 700 !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div p) {
  font-size: 1rem !important;
  line-height: 1.75rem !important;
  margin-bottom: 1rem !important;
  color: #374151 !important;
}

.marked-style ::v-deep(div a) {
  color: #3b82f6 !important;
  text-decoration: underline !important;
}

.marked-style ::v-deep(div ul) {
  list-style-type: disc !important;
  margin-left: 1.25rem !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div ol) {
  list-style-type: decimal !important;
  margin-left: 1.25rem !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div li) {
  margin-bottom: 0.5rem !important;
}

.marked-style ::v-deep(div blockquote) {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  color: #6b7280 !important;
  border-left: 4px solid #d1d5db !important;
  padding-left: 1rem !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div pre) {
  background-color: #f3f4f6 !important;
  padding: 1rem !important;
  border-radius: 0.375rem !important;
  overflow-x: auto !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div code) {
  background-color: #f3f4f6 !important;
  padding: 0.25rem !important;
  border-radius: 0.25rem !important;
  font-size: 0.875rem !important;
  color: #d97706 !important;
}

.action-buttons {
  display: flex;
  align-items: center;
  gap: 16px;
}

.action-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  background: none;
}

.copy-btn {
  color: #0e4485;
}

.export-btn {
  background: #0e4485;
  color: #fff;
}

.page-content {
  padding: 0px;
  overflow-x: hidden;
}

.overlay-container {
  width: 25%;
  position: absolute;
  top: 8.7vh;
  right: 0;
  z-index: 1000;
  height: 100vh;
}

.breadcrumb_header {
  display: flex;
  justify-content: space-between;
  height: 68px;
  background: #fff;
  /* padding: 16px 20px; */
  align-items: center;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin: auto 0;
}
.breadcrumb-text {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.open_sidebar {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 4px;
  background: var(--Primary-Blue, #0e4485);
}

.open_sidebar span {
  color: #fff;
  display: flex;
  /* Ensure icon is aligned vertically */
  align-items: center;
  /* Align icon vertically within span */
}

.open_sidebar p {
  color: #fff !important;
  margin: 0;
  /* Remove any default margin to align the text properly */
  line-height: 1;
  /* Ensure text has the same line height as the icon */
  display: flex;
  align-items: center;
  /* Align text vertically */
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}

.back {
  color: var(--primary) !important;
  font-weight: 500;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--Neutral-Interface-Grey, #fafbfc);
  font-family: Poppins, sans-serif;
}

.main-content {
  width: 100%;
  max-width: 1156px;
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.files-section {
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.section-header {
  font-size: 16px;
  color: var(--Primary-Blue, #0e4485);
  font-weight: 500;
  line-height: 28px;
}

.view-files-btn {
  align-self: flex-start;
  border-radius: 4px;
  color: var(--Status-Blue, #1890ff);
  font-size: 14px;
  padding: 2px 8px;
  border: none;
  background: none;
  cursor: pointer;
}

.security-notice {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 12px;
  line-height: 24px;
}

.security-icon {
  width: 18px;
  height: 18px;
}

.features-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  flex-wrap: wrap;
}

.header-content {
  min-width: 240px;
  color: var(--Neutral-Black, #383a3e);
}

.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  margin: 0;
}

.subtitle {
  font-size: 14px;
  line-height: 24px;
  margin: 0;
}

.search-form {
  min-width: 240px;
  width: 421px;
}

.search-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  background: var(--Neutral-White, #fff);
  padding: 8px 20px;
}

.search-icon {
  width: 24px;
  height: 24px;
}

.search-input {
  border: none;
  outline: none;
  width: 100%;
  font-size: 16px;
  color: var(--Neutral-Dark-Grey, #86888d);
}

.features-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 11px;
}

.feature-card {
  flex: 1 1 240px;
  max-width: 454px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  padding: 24px;
}

.feature-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  color: var(--Neutral-Black, #383a3e);
  margin: 0 0 8px;
}

.feature-description {
  font-size: 14px;
  line-height: 24px;
  color: var(--Neutral-Dark-Grey, #86888d);
  margin: 0;
}

@media (max-width: 991px) {
  .page-container {
    padding: 100px 20px 0;
  }

  .main-content,
  .files-section,
  .search-form,
  .feature-card {
    max-width: 100%;
  }

  .feature-card {
    padding: 20px;
  }
}

.use-cases {
  display: flex;
  align-items: center;
  gap: 20px;
  font-family: Poppins, sans-serif;
  justify-content: start;
  flex-wrap: wrap;
}

.use-case-card {
  border: 1px solid transparent;
  border-radius: 8px;
  box-shadow: 0px 4px 10px 0px rgba(227, 229, 235, 0.6);
  background-color: #fff;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
  flex-grow: 1;
  width: 454px;
  height: 180px;
  padding: 24px;
}

.use-case-card:hover {
  border: 1px solid lightskyblue;
}

.use-case-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
}

.use-case-title {
  color: var(--Neutral-Black, #383a3e);
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

.use-case-description {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 8px;
}

@media (max-width: 991px) {
  .use-case-card {
    max-width: 100%;
    padding: 0 20px;
  }

  .use-case-content {
    max-width: 100%;
  }

  .use-case-description {
    max-width: 100%;
  }
}
</style>
