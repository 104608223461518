<template>
  <div @click="hideAllDropdown">
    <BreadCrumb title="Legal Research" subtitle="Case Law Search / Search History">
    </BreadCrumb>

    <div class="d-flex justify-content-start cursor-pointer back mt-3" @click="goBack">
      <span class="material-symbols-rounded" style="margin: 0;">arrow_back</span>
      <span style="margin: 0 8px;">Back</span>
    </div>

    <TableList class="table-margin" v-if="tableLoading || tableData.length > 0 || $route.query.statement"
      :percentageColumnIndex="6" :load-data="loadData" :loading="tableLoading" :tableConfig="tableConfig" :tableData="tableData" :actions="tableHandler"
      @sort-data="sortData" :show-buttons="true" :search="tableSearchConfig" @send-query="sendQuery" @row-click="viewCampaign" />

    <div v-else class="empty-placeholder">
      <span class="min-margin"></span>
      <img src="@/assets/misc/empty.svg" alt="No Content" />
      <p>Welcome to the Case Law Search! No Search Datas to display yet — start a new one to get started.</p>
    </div>

    <!-- Delete Modal -->
    <b-modal id="delete-modal" hide-header hide-footer centered>
      <div class="delete-prompt">
        <img src="@/assets/misc/delete.svg" alt="Delete" />
        <p style="text-align: center;">
          <b>Are you sure you want to delete this campaign?</b><br>
          This action is permanent and cannot be undone.
        </p>
      </div>
      <div class="delete-prompt-options">
        <button class="btn-outlined" @click="$bvModal.hide('delete-modal')">
          Cancel
        </button>
        <button class="btn-danger" @click="deleteCampaign">Delete</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import BreadCrumb from '../../../components/misc/BreadCrumb.vue';
import TableList from '../../../components/misc/TableList.vue';
import research from '../../../store/research';

export default {
  components: {
    BreadCrumb,
    TableList,
  },

  data() {
    return {
      // header
      creds: {},

      // Case Flow Results
      tableLoading: true,
      tableSearchConfig: {
        title: "History",
      },
      tableConfig: {
        format: [
          {
            header: "ID",
            width: 5,
          },
          {
            header: "Statement",
            // width: 38,
          },
          {
            header: "Jurisdiction",
            width: 16,
            center: true
          },
          {
            header: "Findings",
            width: 12,
            center: true
          },
          {
            header: "Date Created",
            width: 16,
            center: true
          },
          {
            header: "Action",
            width: 8,
            center: true,
          },
        ],
        options: [
          {
            icon: "arrow_forward",
            label: "View Campaign",
          },
          {
            icon: "star",
            label: "Bookmark",
          },
          {
            icon: "archive",
            label: "Archive",
          },
          {
            icon: "delete",
            label: "Delete Campaign",
          },
        ],
        pagination: {
          current: 1,
          total: 0,
        },
      },
      fullData: [],
      tableData: [],

      // Dropdown Menu
      selected: null,
    }
  },

  watch: {
    // update route when changing page
    tableConfig: {
    handler(newCf) {
      const page = newCf.pagination.current;
      if (this.$route.query.page == page) return;

      const query = { page };
      if (this.$route.query.statement) query.statement = this.$route.query.statement; // Change file_name to statement
      this.$router.push({ query });
    },
    deep: true,
  },

  // update data when changed, works when using backward navigation
    $route() { 
      this.loadData(); 
    }
  },

  methods: {
    // to hide all dropdown menu when clicked on anywhere in the page
    hideAllDropdown() { this.$root.$emit('bv::hide::tooltip'); },
    goBack() {
      this.$router.push({ name: 'LegalResearch' });
      // this.$router.go(-1);
    },
    sortData(data) { this.tableData = data; },

    tableHandler(o, id) {
      this.selected = this.tableData[id][0];
      const campaignId = this.tableData[id][0];

      switch (o) {
        // View File
        case 0:
          this.viewCampaign(campaignId);
          break;

        // Bookmark
        case 1:
          this.bookmarkCampaign();
          break;

        // Archive
        case 2:
          this.archiveCampaign();
          break;

        // Delete
        case 3:
          this.$bvModal.show("delete-modal");
          break;
      }
    },
    viewCampaign(campaignId) {
      this.$router.push({
        name: "CaseLawSearchResult",
        params: { id: campaignId }
      });
    },
    bookmarkCampaign() {
      console.log("Selected", this.selected);
      research.BookmarkCampaign(this.selected).then((res) => {
        if (res.data.message === "Successful")
          this.$toast.success(res.data.message);
        this.loadData();
      });
    }, 
    archiveCampaign() {
      research
        .ArchiveCampaign(this.selected)
        .then(res => {
          if (res.data.message === "Successful") this.$toast.success(res.data.message);
          this.loadData();
        });
    },
    deleteCampaign() {
      this.$bvModal.hide("delete-modal");
      research
        .DeleteCampaign(this.selected)
        .then(res => {
          if (res.data.message === "Successful") this.$toast.success(res.data.message);
          this.loadData();
        });
    },
    loadData() {
      const statement = this.$route.query.statement ?? null;
      const page = this.$route.query.page;

      const obj = { type: "statement", page };
      if (statement) obj.statement = statement;

      this.tableLoading = true;
      research
        .GetCampaigns(obj)
        .then(res => {
          if (!res) return;
          const DATA = res.data.data;
          console.log("Data", DATA);

          this.tableConfig.pagination = {
            current: DATA.current_page,
            total: DATA.total,
            perPage: DATA.per_page,
          };
          this.fullData = DATA.data;
          this.statLoading = false;
          this.tableLoading = false;

          this.tableData = this.fullData.map(ent => {
          let parsedStatement;
          let parsedJurisdictions;
          let firstJurisdiction;

          //Safe parsing of JSON(For previous not available data)
          try {
            parsedStatement = JSON.parse(ent.statement)[0];
          } catch (e) {
            console.error('Error parsing statement:', e);
            parsedStatement = 'Invalid statement';
          }

          try {
            parsedJurisdictions = JSON.parse(ent.jurisdictions);
            firstJurisdiction = Object.keys(parsedJurisdictions)[0];
          } catch (e) {
            console.error('Error parsing jurisdictions:', e);
            firstJurisdiction = 'Invalid jurisdiction';
          }

          return [
            ent.id,
            parsedStatement,
            firstJurisdiction,
            ent.total_findings,
            ent.created_at,
            this.$options.filters.username(ent.creator.name),
            ent.completedPerCentage
          ];
        });

        });
    },
    // sendQuery(q) {
    //   const Rquery = this.$route.query;
    //   const query = { page: 1 };
    //   if (q) query.statement = q;
    //   else if (Rquery.page > 1 || Rquery.statement) this.$router.push({ query });
    // },

    sendQuery(q) {
      const Rquery = this.$route.query;
      const query = { page: 1 };
      if (q) {
        query.statement = q;
        this.$router.push({ query });
      } else if (Rquery.page > 1 || Rquery.file_name) {
        this.$router.push({ query });
      }
    },
  },

  created() {
    const CRED = JSON.parse(localStorage.getItem("userInfo")).nexlaw_credits;
    this.creds.les = CRED.full.les - CRED.used.les;

    if (!this.$route.query.page) this.$router.push({ query: { page: 1 } });
    else this.loadData();
  },
}
</script>

<style scoped>
.table-margin {
  margin: 16px 10px 0px 10px;
}

.btn-primary {
  height: 40px;

  span {
    margin-right: 10px;
  }
}

.empty-placeholder {
  min-height: 40%;
  width: 500px;

  .min-margin {
    display: inline-block;
    height: 110px;
  }

  img {
    margin-top: auto;
    margin-bottom: 24px;
  }

  p {
    text-align: center;
    margin-bottom: 32px;
  }
}
</style>

<!-- Delete Modal -->
<style scoped>
.delete-prompt {
  margin: 8px 8px 20px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.delete-prompt img {
  background-color: var(--interface-grey);
  padding: 32px;
  border-radius: 8px;
  height: 164px;
  width: 100%;
  margin-bottom: 16px;
}

.delete-prompt p {
  margin-bottom: 0px;
}

.delete-prompt p b {
  font-weight: 500;
  font-size: 16px;
  color: var(--neutral-black);
}

.delete-prompt-options {
  display: flex;
  justify-content: space-between;
  margin: 0px 8px 8px 8px;
}

.delete-prompt-options button {
  width: calc(50% - 4px);
  height: 32px;
}

.back {
  color: var(--primary) !important;
  font-weight: 500;
}

.black {
  font-weight: 400 !important;
}

#Header {
  height: 68px !important;
  background: #fff !important;
}
</style>