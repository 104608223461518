<template>
  <div>
    <!-- Legal Team Section -->
    <div v-if="showLegalTeam">
      <div class="container mt-4">
        <AlertComponent
          v-if="teamAlert"
          :message="alertMessage"
          @closeAlert="teamAlert = false"
        />
        <p class="Legal_title">Legal Team</p>
        <div class="row">
          <div
            class="col-md-4 mb-3"
            v-for="(member, index) in legalTeam"
            :key="index"
          >
            <MemberCard
              :role="member.type"
              :name="`${member.first_name} ${member.last_name}`"
              :isLegalTeam="true"
              @editMember="editMember(index)"
              @removeMember="removeMember(index)"
            />
          </div>
          <div class="col-md-4 mb-3 add_card">
            <AddCardComponent @click="addMember" text="Add Lawyer" />
          </div>
        </div>
      </div>
    </div>

    <!-- Judiciary Section -->
    <div v-if="showJudiciary">
      <div class="container mt-2">
        <AlertComponent
          v-if="showAlert"
          :message="alertMessage"
          @closeAlert="showAlert = false"
        />
        <p class="Legal_title">Judiciary</p>
        <div class="row">
          <div
            class="col-md-4 mb-3"
            v-for="(judge, index) in judiciary"
            :key="index"
          >
            <MemberCard
              :role="judge.type"
              :name="`${judge.first_name} ${judge.last_name}`"
              @editMember="editJudge(index)"
              @removeMember="removeJudge(index)"
            />
          </div>
          <div class="col-md-4 mb-3 add_card">
            <AddCardComponent @click="addJudge" text="Add Judge" />
          </div>
        </div>
      </div>
    </div>

    <!-- Legal Team Modal -->
    <ModalComponent
      modalId="legalTeamModal"
      modalLabelId="legalTeamLabel"
      title="Legal Team"
      :currentMember="currentMember"
      :options="legalTeamOptions"
      :lawfirm="lawfirmOptions"
      :fieldsArr="legalTeamFields"
      :saveMember="saveLegalTeam"
      :country="country"
    />

    <!-- Judiciary Modal -->
    <ModalComponent
      modalId="judiciaryModal"
      modalLabelId="judiciaryLabel"
      title="Judiciary"
      :currentMember="currentJudge"
      :options="judiciaryOptions"
      :fieldsArr="judiciaryFields"
      :saveMember="saveJudiciary"
    />
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import ModalComponent from "../../../components/input/Modal.vue";
import AlertComponent from "./Alert.vue";
import MemberCard from "./MemberCard.vue";
import AddCardComponent from "./AddCard.vue";

export default {
  components: {
    ModalComponent,
    AlertComponent,
    MemberCard,
    AddCardComponent,
  },
  props: {
    shouldReset: Boolean,
    country: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      teamAlert: false,
      showAlert: false,
      alertMessage: "",
      legalTeam: [],
      judiciary: [],
      currentMember: { role: "", lawfirm: "", firstName: "", middleName: "", lastName: "" },
      currentJudge: { role: "", firstName: "", middleName: "", lastName: "" },
      legalTeamOptions: [],
      lawfirmOptions: [],
      judiciaryOptions: [],
      legalTeamFields: [
        {
          id: "legalFirstName",
          label: "First Name",
          placeholder: "Enter First Name",
          model: "firstName",
        },
        {
          id: "legalMiddleName",
          label: "Middle Name",
          placeholder: "Enter Middle Name",
          model: "middleName",
        },
        {
          id: "legalLastName",
          label: "Last Name",
          placeholder: "Enter Last Name",
          model: "lastName",
        },
      ],
      judiciaryFields: [
        {
          id: "judiciaryFirstName",
          label: "First Name",
          placeholder: "Enter First Name",
          model: "firstName",
        },
        {
          id: "judiciaryMiddleName",
          label: "Middle Name",
          placeholder: "Enter Middle Name",
          model: "middleName",
        },
        {
          id: "judiciaryLastName",
          label: "Enter Last Name",
          placeholder: "Enter Last Name",
          model: "lastName",
        },
      ],
    };
  },
  computed: {
    showLegalTeam() {
      // Show Legal Team if country is empty (not selected) or one of the specified countries
      return (
        !this.country || ["USA", "UK", "NZ", "AUS", "SG", "MY"].includes(this.country)
      );
    },
    showJudiciary() {
      // Show Judiciary if country is empty (not selected) or one of the specified countries
      return !this.country || ["USA", "UK", "NZ", "CAN", "MY"].includes(this.country);
    },
  },
  watch: {
    country: {
      immediate: true,
      handler(newCountry) {
        this.updateTeamOptions(newCountry);
      },
    },
    shouldReset(newVal) {
      if (newVal) {
        this.resetValues();
      }
    },
  },
  methods: {
    updateTeamOptions(country) {
      switch (country) {
        case "USA":
          this.legalTeamOptions = [
            "Lead Attorney",
            "Associate Attorney",
            "Co-Counsel",
            "Legal Consultant",
            "Paralegal",
          ];
          this.lawfirmOptions = [];
          this.judiciaryOptions = [
            "Chief Justice of the Supreme Court",
            "Associate Justice of the Supreme Court",
            "Chief Circuit Judge",
            "Circuit Judge",
            "District Judge",
            "Magistrate Judge",
            "Master",
          ];
          break;
        case "UK":
          this.legalTeamOptions = [
            "Barrister",
            "Solicitor",
            "Queen’s Counsel (QC)",
            "King’s Counsel (KC)",
            "Junior Counsel",
            "Legal Consultant",
            "Paralegal",
          ];
          this.lawfirmOptions = [];
          this.judiciaryOptions = [
            "Lord Chief Justice",
            "Master of the Rolls",
            "Lord Justice of Appeal",
            "High Court Judge",
            "Circuit Judge",
            "District Judge",
          ];
          break;
        case "AUS":
          this.legalTeamOptions = [
            "Barrister",
            "Solicitor",
            "Senior Counsel",
            "Junior Counsel",
            "Legal Consultant",
            "Legal Clerk",
          ];
          this.lawfirmOptions = [];
          this.judiciaryOptions = [];
          break;
        case "CAN":
          this.legalTeamOptions = [];
          this.lawfirmOptions = [];
          this.judiciaryOptions = [
            "Chief Justice of the Supreme Court",
            "Puisne Justice of the Supreme Court",
            "Chief Circuit Judge",
            "Presiding Judge of the Court",
            "Senior Circuit Court Judge",
            "Judge of the Court",
            "Provisional Judge of the Supreme Court",
            "Chief Justice",
            "Provisional Judge",
            "Justice of Peace",
            "Justice",
          ];
          break;
        case "NZ":
          this.legalTeamOptions = [
            "Barrister",
            "Solicitor",
            "Senior Counsel",
            "Junior Counsel",
            "Legal Consultant",
            "Legal Executive",
          ];
          this.lawfirmOptions = [];
          this.judiciaryOptions = [
            "Chief Justice of the Supreme Court",
            "Justice of the Supreme Court",
            "Chief High Court Judge",
            "High Court Judge",
            "District Court Judge",
            "Community Magistrate",
          ];
          break;
        case "SG":
          this.legalTeamOptions = [
            "Senior Counsel",
            "Advocate & Solicitor",
            "Junior Counsel",
            "Legal Consultant",
            "Legal Assistant",
            "Paralegal",
          ];
          this.lawfirmOptions = [
            "Attorney - General's Chambers",
            "Others", 
          ];
          this.judiciaryOptions = [];
          break;
        case "MY":
          this.legalTeamOptions = [
            "Advocate & Solicitor",
            "Senior Counsel",
            "Junior Counsel",
            "Legal Consultant",
            "Legal Assistant",
            "Legal Clerk",
            "Attorney",
          ];
          this.lawfirmOptions = [
            "Public Prosecutor",
            "Deputy Public Prosecutor",
            "Others",
          ];
          this.judiciaryOptions = [
            "Chief Justice of Malaysia",
            "President of the Court of Appeal",
            "Chief Judge of Malaya",
            "Chief Judge of Sabah and Sarawak",
            "High Court Judge",
            "Sessions Court Judge",
            "Magistrate",
            "Judge",
          ];
          break;
        default:
          // Default options when no country is selected
          this.legalTeamOptions = [
            "Lead Attorney",
            "Associate Attorney",
            "Co-Counsel",
            "Legal Consultant",
            "Paralegal",
          ];
          this.judiciaryOptions = [
            "Chief Justice of the Supreme Court",
            "Associate Justice of the Supreme Court",
            "Chief Circuit Judge",
            "Circuit Judge",
            "District Judge",
            "Magistrate Judge",
          ];
          break;
      }
    },
    addMember() {
      this.currentMember = {
        role: "",
        lawfirm: "",
        firstName: "",
        middleName: "",
        lastName: "",
      };
      this.showModal("legalTeamModal");
    },
    editMember(index) {
      this.currentMember = { ...this.legalTeam[index], index };
      this.showModal("legalTeamModal");
    },
    saveLegalTeam() {
      const first_name =
        `${this.currentMember.firstName} ${this.currentMember.middleName} `.trim();
      const last_name = `${this.currentMember.lastName}`.trim();
      const member = {
        first_name: first_name,
        last_name: last_name,
        title: this.currentMember.role,
        type: this.currentMember.role,
        lawfirm: this.currentMember.lawfirm,
      };
      if (this.currentMember.index !== undefined) {
        this.$set(this.legalTeam, this.currentMember.index, member);
      } else {
        this.legalTeam.push(member);
      }
      this.currentMember = {
        role: "",
        lawfirm: "",
        firstName: "",
        middleName: "",
        lastName: "",
      };
      this.$emit("updateLegalTeam", this.legalTeam);
      this.hideModal("legalTeamModal");
      this.alertMessage = "Legal Team member saved successfully!";
      this.teamAlert = true;
      setTimeout(() => {
        this.teamAlert = false;
      }, 2000);
    },
    removeMember(index) {
      this.legalTeam.splice(index, 1);
    },
    addJudge() {
      this.currentJudge = {
        role: "",
        firstName: "",
        middleName: "",
        lastName: "",
      };
      this.showModal("judiciaryModal");
    },
    editJudge(index) {
      this.currentJudge = { ...this.judiciary[index], index };
      this.showModal("judiciaryModal");
    },
    saveJudiciary() {
      const first_name =
        `${this.currentJudge.firstName} ${this.currentJudge.middleName} `.trim();
      const last_name = `${this.currentJudge.lastName}`.trim();
      const judge = {
        first_name: first_name,
        last_name: last_name,
        title: this.currentJudge.role,
        type: this.currentJudge.role,
      };
      if (this.currentJudge.index !== undefined) {
        this.$set(this.judiciary, this.currentJudge.index, judge);
      } else {
        this.judiciary.push(judge);
      }
      this.currentJudge = {
        role: "",
        firstName: "",
        middleName: "",
        lastName: "",
      };
      this.$emit("updateJudiciary", this.judiciary);
      this.hideModal("judiciaryModal");
      this.alertMessage = "Judiciary saved successfully!";
      this.showAlert = true;
      setTimeout(() => {
        this.showAlert = false;
      }, 2000);
    },
    removeJudge(index) {
      this.judiciary.splice(index, 1);
    },
    showModal(modalId) {
      const modalElement = document.getElementById(modalId);
      const modal = new Modal(modalElement);
      modal.show();
    },
    hideModal(modalId) {
      const modalElement = document.getElementById(modalId);
      const modal = Modal.getInstance(modalElement);
      modal.hide();
    },
    resetValues() {
      this.legalTeam = [];
      this.judiciary = [];
      this.$emit("updateLegalTeam", this.legalTeam);
      this.$emit("updateJudiciary", this.judiciary);
    },
  },
};
</script>
