import axios from "@/axios.js";
export default {
  CreateUsecase(item) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/nexa/use-cases`, item)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  GetResult(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`nexlaw/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  Summary(id, item) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexa/use-cases/${id}/summarize`, item)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  GetSummarizedDocument(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`nexlaw/link/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  GetDocuments(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`nexlaw/document/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
