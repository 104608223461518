<template>
  <div>
    <div class="breadcrumb_header">
      <div class="breadcrumb ms-2">
        <h4 class="breadcrumb-title">NeXa</h4>
        <span class="divider"></span>
        <span>Use Cases / Documents Insight</span>
      </div>
      <div>
        <button class="open_sidebar me-2" @click="toggleSidebar">
          <span class="material-symbols-rounded icon"
            >keyboard_double_arrow_left</span
          >
          <p>Open Sidebar</p>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-1 mt-3 p-0">
        <div
          class="d-flex justify-content-end cursor-pointer back"
          @click="goBack"
        >
          <span class="material-symbols-rounded" style="margin: 0"
            >arrow_back</span
          >
          <span style="margin: 0 0 0 8px">Back</span>
        </div>
      </div>
      <div class="col-md-10 mt-5 p-0">
        <PageLoader
          style="padding-top: 120px"
          v-if="loading"
          :loading="loading"
          :replace_msg="loading_message"
          :completedPercentage="duration"
          :files_uploaded="files.length"
        />
        <div v-else>
          <!-- <section class="search-container">
            <article class="search-content">
              <p class="search-text">
                Review all documents and extract confidentiality clauses or
                related terms.
              </p>
            </article>
            <button class="search-button" tabindex="0" aria-label="Search">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/10cc222bd144f1226587997faf816a8ac0fad9278d354c7c72af0ad71f1ad598?placeholderIfAbsent=true&apiKey=411e2ec61bdb45ecb3af3338badbc5df"
                alt="Search icon"
                class="search-icon"
              />
            </button>
          </section> -->

          <section
            class="claims-container"
            v-for="q in questions"
            :key="`questions-${q.id}`"
            :id="`q-${q.id}`"
          >
            <FollowUpQuestionBox :q="q" />
          </section>

          <section class="analysis-section mt-3">
            <section class="document-insight">
              <header class="insight-header">Documents Insight:</header>
              <hr class="separator" />
              <main class="insight-content">
                <section class="statement-section">
                  <div class="statement-header">
                    <span class="statement-title">Statement:</span>
                    <button
                      class="location-button"
                      aria-label="Select location"
                    >
                      <img
                        :src="countryImages[jurisdiction]"
                        :alt="jurisdiction"
                        class="location-icon"
                      />
                      <span class="location-text">{{ jurisdiction }}</span>
                    </button>
                  </div>
                  <p class="statement-description"></p>
                </section>
                <section class="upload-section">
                  <h2 class="upload-title">
                    Uploaded {{ files?.length }} Files
                  </h2>
                  <button class="view-files-button" @click="toggleSidebar">
                    View Uploaded Files
                  </button>
                  <div class="security-notice">
                    <img
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/d50070917f856be23ffdce3e47be0be9dea94d29cc227233341162558b1b9fcf?placeholderIfAbsent=true&apiKey=411e2ec61bdb45ecb3af3338badbc5df"
                      alt=""
                      class="security-icon"
                    />
                    <span class="security-text">
                      Your files will be securely stored in NeXa, accessible
                      only to you and your workspace admins, and you can delete
                      them at any time.
                    </span>
                  </div>
                </section>
                <hr class="separator" />
                <section class="action-section">
                  <div class="action-header">
                    <div class="action-title"></div>
                    <div class="action-buttons">
                      <button
                        :disabled="isCopied['doc']"
                        class="copy-button"
                        aria-label="Copy content"
                        @click="copy"
                      >
                        <img
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/b44c86e6cccfd119bd0ae151720d5bcb372679b6fd25fe58afd18b9b6a9484b5?placeholderIfAbsent=true&apiKey=411e2ec61bdb45ecb3af3338badbc5df"
                          alt=""
                          class="button-icon"
                        />
                        <span class="button-text">{{
                          isCopied["doc"] ? "Copied" : "Copy"
                        }}</span>
                      </button>

                      <b-dropdown
                        size="sm"
                        id="dropdown-right"
                        right
                        offset="-9"
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                        @click="toggleDropdown"
                      >
                        <template #button-content>
                          <button class="action-button export-button">
                            <span class="button-label">Export</span>
                            <img
                              :class="{ 'rotate-180': isDropdown }"
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/d12668b437286b44ce443817869f8a85013f194276d09cf30b58481266006963?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
                              alt="Export Icon"
                              class="icon"
                            />
                          </button>
                        </template>

                        <b-dropdown-item class="ms-1 me-1" @click="exportToPDF">
                          <img class="me-2" src="@/assets/icon/pdf-icon.svg" />
                          <span class="dropdown-text">Export as PDF</span>
                        </b-dropdown-item>

                        <b-dropdown-item
                          class="ms-1 me-1"
                          @click="exportToExcel"
                        >
                          <img
                            class="me-2"
                            src="@/assets/icon/excel-icon.svg"
                          />
                          <span class="dropdown-text">Export as Excel</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                </section>
              </main>
            </section>

            <section class="comparison-result">
              <div class="summary-wrapper marked-style">
                <span class="summary-header">Summary</span>
                <vue-marked>{{ summary }}</vue-marked>
              </div>

              <table class="table-bordered mt-4" id="doc-insight-table">
                <thead>
                  <tr>
                    <th>Document</th>
                    <th>Answer</th>
                    <th>Relevant line</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="doc in answer" :key="doc.doc_name">
                    <td>{{ doc.doc_name }}</td>
                    <td class="clause-cell">
                      <div
                        :class="{
                          'clause-content-expanded': doc.showFullClause,
                          'clause-content': !doc.showFullClause,
                        }"
                      >
                        {{ doc.answer }}
                      </div>
                      <button
                        class="mt-3"
                        v-if="isLongText(doc.answer)"
                        @click="toggleShowFullClause(doc)"
                      >
                        {{ doc.showFullClause ? "Show less" : "Show more" }}
                      </button>
                    </td>
                    <td class="lines-cell">
                      <div
                        :class="{
                          'lines-content-expanded': doc.showFullLines,
                          'lines-content': !doc.showFullLines,
                        }"
                      >
                        {{ doc.lines }}
                      </div>
                      <button
                        class="mt-3"
                        v-if="isLongText(doc.lines)"
                        @click="toggleShowFullLines(doc)"
                      >
                        {{ doc.showFullLines ? "Show less" : "Show more" }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
          </section>
        </div>
      </div>
    </div>
    <div v-if="!loading" class="wrapper-chatbox">
      <ChatBox @chat-submit="submitQuery" />
    </div>
    <sidebar
      v-clickaway="toggleSidebar"
      v-if="sidebarCollapsed"
      @toggle-sidebar="toggleSidebar"
      :uploadFiles="files"
    />
  </div>
</template>
<script>
import { ExportDataPDF } from "../../../store/utils";
import ChatBox from "../../../components/input/ChatBox.vue";
import sidebar from "../sidebar.vue";
import nexa from "@/store/nexa.js";
import countryImages from "@/assets/country/index";
import PageLoader from "../../PageLoader.vue";
import usecase from "@/store/usecase.js";
import FollowUpQuestionBox from "@/components/input/FollowUpQuestionBox.vue";
import VueMarked from "vue-marked";
import * as XLSX from "xlsx";
import marked from "marked";

export default {
  components: {
    PageLoader,
    sidebar,
    ChatBox,
    FollowUpQuestionBox,
    VueMarked,
  },
  data() {
    return {
      isComponentActive: true,
      isDropdown: false,
      loading_message: "Please wait a moment while we process it.",
      duration: 0,
      loading: true,
      isCopied: {},
      sidebarCollapsed: false,
      question: "",
      lawbotVisible: false,
      isCountry: false,
      countryImages,
      valuesFile: [],
      files: [],
      answer: [],
      data: null,
      summary: null,
      jurisdiction: "",
      questions: [],
      isFetching: false,
      collapsedQuestions: [],
      wholeJuris: {},
      countryMapping: {
        AUS: "Australia",
        USA: "USA",
        CAN: "Canada",
        NZ: "New Zealand",
        SG: "Singapore",
        MY: "Malaysia",
        UK: "United Kingdom",
      },
    };
  },
  // mounted() {
  //   setTimeout(() => {
  //     this.loading = false;
  //   }, 1000);
  // },

  created() {
    usecase.GetResult(this.$route.params.id ?? "").then((response) => {
      const DATA = response.data.data;
      if (DATA.completedPerCentage >= 100) {
        this.duration = DATA.completedPerCentage;
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }

      if (DATA.completedPerCentage < 100) {
        if (DATA.completedPerCentage >= 0) {
          this.duration = DATA.completedPerCentage;
          this.files = DATA.questionDocuments;
        }

        this.interval = setInterval(() => {
          usecase.GetResult(this.$route.params.id).then((res) => {
            if (res.data.data.completedPerCentage >= 100) {
              this.duration = res.data.data.completedPerCentage;
              clearInterval(this.interval);
              location.reload();
            } else if (res.data.data.completedPerCentage > 0) {
              this.duration = res.data.data.completedPerCentage;
            }
          });
        }, 10000);
      } else {
        const { values, questions, questionDocuments, jurisdiction, ...data } =
          DATA;
        const [first, ...rest] = questions;
        this.data = data;
        this.question = first.question;
        this.answer = JSON.parse(first.answer).map((item) => ({
          ...item,
          showFullClause: false,
          showFullLines: false,
        }));

        this.questions = rest || [];
        this.files = questionDocuments;

        this.jurisdiction = Object.keys(JSON.parse(jurisdiction))[0];

        const country =
          this.countryMapping[Object.keys(JSON.parse(jurisdiction))[0]];
        this.wholeJuris[country] = ["all"];
        this.files = questionDocuments;

        this.valuesFile =
          JSON.parse(values.find((item) => item.key === "nexa_files").value) ||
          [];

        this.summary = JSON.parse(
          values.find((item) => item.key === "nexa_conclusion")?.value
        );

        this.collapsedQuestions = this.questions.map((_, index) => index);

        const hasNullValue = this.questions.some((q) => q.answer === null);

        if (hasNullValue) {
          this.refetchAnswers();
        }
      }
    });
  },

  methods: {
    copy() {
      const tableElement = document.querySelector("table#doc-insight-table");

      if (!tableElement) {
        console.error("No table found in the provided HTML.");
        return;
      }

      let csvContent = "";
      const rows = tableElement.querySelectorAll("tr");

      rows.forEach((row) => {
        const cols = row.querySelectorAll("td, th");

        const rowArray = Array.from(cols).map((col) => col.innerText.trim());

        if (rowArray.some((cell) => cell !== "")) {
          csvContent += rowArray.join("\t") + "\n";
        }
      });

      navigator.clipboard
        .writeText(csvContent)
        .then(() => {
          this.$toast.success("Copied as plain text");
          this.isCopied = true;
        })
        .catch(() => {
          this.$toast.error("Error copying the text");
        })
        .finally(() => {
          setTimeout(() => {
            this.isCopied = false;
          }, 2000);
        });
    },
    toggleDropdown() {
      this.isDropdown = !this.isDropdown;
    },
    async refetchAnswers() {
      if (this.isFetching) return;
      this.isFetching = true;

      const checkAllAnswers = () =>
        this.questions.slice(1).every((q) => q.answer !== null); // Skip the first question

      while (!checkAllAnswers()) {
        const updatedQuestions = await Promise.all(
          this.questions.map(async (element, index) => {
            if (index === 0) return element; // Skip the first question

            if (element.answer == null) {
              try {
                const response = await usecase.GetResult(
                  this.$route.params.id ?? ""
                );

                const updatedQuestion = response.data.data.questions.find(
                  (q) => q.id === element.id
                );

                if (updatedQuestion && updatedQuestion.answer !== null) {
                  return {
                    ...element,
                    answer: updatedQuestion.answer,
                  };
                }
                return element;
              } catch (error) {
                return element;
              }
            }
            return element;
          })
        );

        this.questions = updatedQuestions;

        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      this.isFetching = false;
    },
    submitQuery(question) {
      const formData = new FormData();
      formData.append("entity_type", "campaign");
      formData.append("entity_id", this.$route.params.id);
      formData.append("language", "en");
      formData.append("location_id", 1);
      formData.append("question", question);
      formData.append("jurisdiction", JSON.stringify(this.wholeJuris));
      this.blockUser = true;

      nexa
        .DetailAsk(formData)
        .then((response) => {
          const responseData = response.data?.data;
          const responseQuestions = responseData?.questions;

          if (responseQuestions && Array.isArray(responseQuestions)) {
            // Skip the first question (index 0)
            responseQuestions.slice(1).forEach((q) => {
              // Start from index 1
              const existing = this.questions.find(
                (existing) => existing.id === q.id
              );
              if (existing) {
                existing.answer = q.answer; // Update existing question's answer
              } else {
                this.questions.push(q); // Add new question to the list
              }
            });

            this.refetchAnswers();

            if (response.data.completedPercentage === 100) {
              this.blockUser = false;
            }
          } else {
            console.error(
              "Error: responseQuestions is undefined or not an array",
              response
            );
          }
        })
        .catch((error) => {
          console.error("Error submitting query:", error);
          this.blockUser = false;
        });
    },

    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    goBack() {
      // this.$router.push({ name: "ExpertMode" });
      this.$router.go(-1);
    },

    exportToPDF() {
      let content;
      const followUpQuestion = `<h1 style="font-size: 24px; font-weight: bold;">Document Insight: ${this.$route.params.id}</h1><br><hr>`;
      const tempDiv = document.createElement("div");

      // Style the temporary div
      tempDiv.style.width = "65%";
      tempDiv.style.maxWidth = "800px";
      tempDiv.style.margin = "20px auto";
      tempDiv.style.padding = "20px";
      tempDiv.style.border = "1px solid #ccc";
      tempDiv.style.borderRadius = "10px";
      tempDiv.style.backgroundColor = "#f9f9f9";
      tempDiv.style.boxShadow = "0 2px 10px rgba(0, 0, 0, 0.1)";
      tempDiv.style.fontFamily = "Arial, sans-serif";
      content = document.querySelector("table#doc-insight-table");
      let summary = marked(this.summary);
      tempDiv.innerHTML =
        followUpQuestion +
        `<div class="content">${content.outerHTML}</div>` +
        "<br><hr>" +
        '<h3 style="font-size: 18px; ">Summary</h3>' +
        summary;

      document.body.appendChild(tempDiv);
      ExportDataPDF(
        tempDiv.innerHTML,
        false,
        `Document Insight: ${this.$route.params.id}`
      );
      this.$toast.success("Successfully downloaded PDF");
      document.body.removeChild(tempDiv);
    },
    exportToExcel() {
      const tableElement = document.querySelector("table#doc-insight-table");

      if (!tableElement) {
        console.error("No table found in the provided HTML string.");
        return;
      }

      const rows = Array.from(tableElement.querySelectorAll("tr"));
      let csv = "";

      rows.forEach((row) => {
        const cols = Array.from(row.querySelectorAll("td, th"));

        if (
          cols.length > 0 &&
          cols.some((col) => col.innerText.trim() !== "")
        ) {
          const csvRow = cols
            .map((col) => `"${col.innerText.replace(/"/g, '""')}"`)
            .join(",");
          csv += csvRow + "\n";
        }
      });

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `Document Insight : ${this.$route.params.id}.csv`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      const wb = XLSX.utils.table_to_book(tableElement, { sheet: "Sheet1" });

      XLSX.writeFile(wb, `Document Insight : ${this.$route.params.id}.xlsx`);

      this.$toast.success(
        `Exported Document Insights ${this.$route.params.id} to CSV and XLSX`
      );
    },
    toggleShowFullClause(doc) {
      this.$set(doc, "showFullClause", !doc.showFullClause);
    },
    toggleShowFullLines(doc) {
      this.$set(doc, "showFullLines", !doc.showFullLines);
    },
    isLongText(text) {
      const lineCharacterLimit = 50;
      const lineCount = Math.ceil(text.length / lineCharacterLimit);
      return lineCount > 4;
    },
  },
  beforeDestroy() {
    this.isComponentActive = false;
    clearInterval(this.interval);
  },
  beforeRouteLeave(to, from, next) {
    this.isComponentActive = false;
    next();
  },
};
</script>

<style scoped>
.marked-style ::v-deep(div > * > strong) {
  font-weight: 600 !important;
}

.marked-style ::v-deep(div > * > b) {
  font-weight: 600 !important;
}

.marked-style ::v-deep(div h1 *) {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
  font-weight: 600 !important;
}

.marked-style ::v-deep(div h2 *) {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
  font-weight: 600 !important;
}

.marked-style ::v-deep(div h3 *) {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  font-weight: 600 !important;
}

.marked-style ::v-deep(div p *) {
  font-size: 1rem !important;
  line-height: 1.75rem !important;
  font-weight: bold !important;
  color: #374151 !important;
}

.marked-style ::v-deep(div a *) {
  color: #3b82f6 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}

.marked-style ::v-deep(div ul *) {
  font-size: inherit !important;
  font-weight: bold !important;
}

.marked-style ::v-deep(div ol *) {
  font-size: inherit !important;
  font-weight: bold !important;
}

.marked-style ::v-deep(div li *) {
  font-size: inherit !important;
  font-weight: bold !important;
}

.marked-style ::v-deep(div blockquote strong) {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  font-weight: bold !important;
  color: #6b7280 !important;
}

.marked-style ::v-deep(div pre strong) {
  font-size: inherit !important;
  font-weight: bold !important;
  color: #d97706 !important;
}

.marked-style ::v-deep(div code strong) {
  font-size: 0.875rem !important;
  font-weight: bold !important;
  background-color: #f3f4f6 !important;
  color: #d97706 !important;
}

.marked-style ::v-deep(div h1) {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
  font-weight: 800 !important;
  margin-bottom: 1.5rem !important;
}

.marked-style ::v-deep(div h2) {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
  font-weight: 700 !important;
  margin-bottom: 1.25rem !important;
}

.marked-style ::v-deep(div h3) {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  font-weight: 700 !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div p) {
  font-size: 1rem !important;
  line-height: 1.75rem !important;
  margin-bottom: 1rem !important;
  color: #374151 !important;
}

.marked-style ::v-deep(div a) {
  color: #3b82f6 !important;
  text-decoration: underline !important;
}

.marked-style ::v-deep(div ul) {
  list-style-type: disc !important;
  margin-left: 1.25rem !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div ol) {
  list-style-type: decimal !important;
  margin-left: 1.25rem !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div li) {
  margin-bottom: 0.5rem !important;
}

.marked-style ::v-deep(div blockquote) {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  color: #6b7280 !important;
  border-left: 4px solid #d1d5db !important;
  padding-left: 1rem !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div pre) {
  background-color: #f3f4f6 !important;
  padding: 1rem !important;
  border-radius: 0.375rem !important;
  overflow-x: auto !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div code) {
  background-color: #f3f4f6 !important;
  padding: 0.25rem !important;
  border-radius: 0.25rem !important;
  font-size: 0.875rem !important;
  color: #d97706 !important;
}

.clause-cell,
.lines-cell {
  position: relative;
}

.clause-content,
.lines-content {
  display: -webkit-box;
  display: box;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  overflow: hidden;
  max-height: 6em; /* Limit height to approx. 4 lines */
  -webkit-line-clamp: 4; /* Limit to 4 lines for overflow */
  line-clamp: 4; /* Standard property for overflow */
  transition: max-height 0.3s ease;
}

.clause-content-expanded,
.lines-content-expanded {
  display: -webkit-box;
  display: box;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  overflow: hidden;
  transition: max-height 0.3s ease;
  max-height: none;
  line-clamp: unset;
}

.skeleton-loader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.skeleton-item {
  border-radius: 12px;
  background-color: rgba(243, 243, 243, 1);
  min-height: 28px;
}

.skeleton-item-large {
  width: 535px;
  max-width: 100%;
}

.skeleton-item-full {
  width: 100%;
  margin-top: 8px;
}

@media (max-width: 991px) {
  .skeleton-item-full {
    max-width: 100%;
  }
}

.document-insight {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.insight-header {
  color: #0e4485;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 1.75rem !important;
}

.separator {
  border: 0.0625rem solid var(--mid-grey);
  width: 100%;
}

.insight-content {
  font-family: Poppins, sans-serif;
}

.statement-section {
  border-radius: 16px;
  padding: 8px 0;
}

.statement-header {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #383a3e;
  font-weight: 500;
  flex-wrap: wrap;
}

.statement-title {
  color: var(--Neutral-Black, #383a3e);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem;
}

.location-button {
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 2px 8px;
  font-size: 14px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.location-icon,
.button-icon {
  width: 18px;
  height: 18px;
}

.statement-description {
  margin-top: 4px;
  font-size: 14px;
  color: #86888d;
  line-height: 24px;
}

.upload-section {
  margin-top: 16px;
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 0.5rem;
}

.upload-title {
  color: var(--Neutral-Black, #383a3e);
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 1.75rem !important;
  margin-bottom: 0px;
}

.view-files-button {
  color: #1890ff;
  text-decoration: underline;
  background: transparent;
  border: none;
  padding: 2px 8px;
  font-size: 14px;
  cursor: pointer;
}

.view-files-button:hover {
  background-color: #f2f3f3;
}

.security-notice {
  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 12px;
  color: #86888d;
  line-height: 24px;
}

.security-text {
  color: var(--Neutral-Dark-Grey, #86888d);

  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.action-section {
  margin-top: 16px;
}

.action-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
}

.action-buttons {
  display: flex;
  gap: 16px;
  font: 500 14px/1 Poppins, sans-serif;
}

.copy-button,
.export-button {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  padding: 2px 8px;
  border: none;
  cursor: pointer;
}

.copy-button {
  color: #0e4485;
  background: transparent;
}

.export-button {
  color: #fff;
  background: #0e4485;
}

@media (max-width: 991px) {
  .document-insight {
    padding: 0 20px;
  }

  .statement-header {
    white-space: initial;
  }

  .action-header {
    max-width: 100%;
  }
}

.case-summary {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  width: 100%;

  border-radius: 8px;
  border: 1px solid #86888d;
}

.summary-header {
  display: flex;
  width: 69.25rem;
  height: 2.625rem;
  align-items: flex-start;
  border-top: 20px;
}

.key-point,
.claim-type {
  display: flex;
  padding: 0.5625rem 1.25rem 0.5625rem 1rem;
  justify-content: start;
  align-items: start;
  gap: 0.625rem;
  flex: 1 0 0;

  color: var(--Neutral-Black, #383a3e);

  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

.table-row {
  border-bottom: 1px solid #86888d;
}

.row-title,
.row-content {
  display: flex;
  padding: 0.5625rem 1.25rem 0.5625rem 1rem;
  justify-content: start;
  align-items: start;
  gap: 0.625rem;
  flex: 1 0 0;
}

@media (max-width: 991px) {
  .summary-header,
  .table-row,
  .row-title,
  .row-content {
    max-width: 100%;
  }

  .key-point,
  .claim-type,
  .row-title,
  .row-content {
    white-space: normal;
  }
}

.claims-container {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  display: flex;
  flex-direction: column;
  font: 400 14px/1.5 Poppins, sans-serif;
  padding: 22px 24px;
  margin-bottom: 2rem;
  transition: height 0.3s ease;
  cursor: pointer;
}

.claims-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  flex-wrap: wrap;
}

.title-group {
  display: flex;
  min-width: 240px;
  align-items: center;
  gap: 8px;

  line-height: 28px;
}
.overlay-container {
  width: 25%;
  position: fixed;
  top: 8.7vh;
  right: 0;
  z-index: 1000;

  height: 100vh;
}
.primary-title {
  color: #0e4485;
  font-family: Poppins;
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem;
}

.secondary-title {
  color: #383a3e;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem;
}

.action-buttons {
  display: flex;
  align-items: center;
  gap: 16px;
}

.action-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  background: none;
}

.copy-btn {
  color: #0e4485;
}

.export-btn {
  background: #0e4485;
  color: #fff;
}

.action-icon {
  width: 18px;
  height: 18px;
}

.claims-content {
  margin: 32px 0;

  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font-family: Poppins, sans-serif !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1.5rem !important;
}

.claims-table {
  border: 1px solid #86888d;
  border-radius: 8px;
  overflow: hidden;
}

.table-header {
  display: flex;
  background: #f2f3f3;
  border-bottom: 1px solid #86888d;
}

.column-title {
  flex: 1;
  min-width: 240px;
  padding: 9px 16px;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #383a3e;
}

.column-title:first-child {
  border-right: 1px solid #86888d;
}

.table-row {
  display: flex;
  border-bottom: 1px solid #86888d;
}

.row-label {
  flex: 1;
  min-width: 240px;
  padding: 9px 16px;
  border-right: 1px solid #86888d;
}

.row-content {
  flex: 1;
  min-width: 240px;
  padding: 9px 16px;
}

.wrapper-chatbox {
  position: fixed;
  right: 2rem;

  width: 80%;
  z-index: 30;
  bottom: 2rem;
}

.overlay-container {
  width: 25%;
  position: fixed;
  top: 8.7vh;
  right: 0;
  z-index: 1000;

  height: 100vh;
}

.search-container {
  justify-content: space-between;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #f2f3f3;
  background: #fff;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  display: flex;
  gap: 40px 100px;
  overflow: hidden;
  flex-wrap: wrap;
  padding: 24px;
  margin-bottom: 2rem;
}

@media (max-width: 991px) {
  .search-container {
    padding: 0 20px;
  }
}

.summary-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: start;
}

.summary-header {
  color: var(--Primary-Blue, #0e4485);
  /* Subtitle */
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem; /* 175% */
}

.search-content {
  align-self: start;
  display: flex;
  align-items: center;
  gap: 16px;
  color: #383a3e;
  font: 400 14px/24px Poppins, sans-serif;
}

.search-text {
  align-self: stretch;
  min-width: 240px;
  margin: auto 0;
}

@media (max-width: 991px) {
  .search-text {
    max-width: 100%;
  }
}

.search-button {
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  justify-content: center;
  padding: 4px;
  background: none;
  border: none;
  cursor: pointer;
}

.search-button:focus {
  outline: 2px solid #383a3e;
  outline-offset: 2px;
}

.summary-text {
  color: var(--Neutral-Black, #383a3e);

  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.search-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  align-self: stretch;
  margin: auto 0;
}

.page-content {
  padding: 0px;
  overflow-x: hidden;
}
.breadcrumb_header {
  display: flex;
  justify-content: space-between;
  height: 68px;
  background: #fff;
  /* padding: 5px; */
  align-items: center;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin: auto 0;
  span {
    font-size: 14px;
    color: var(--dark-grey) !important;
  }
}

.breadcrumb-title {
  margin: 0px;
  padding-left: 10px;
}

.open_sidebar {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center; /* Ensure vertical alignment */
  gap: 8px;
  border-radius: 4px;
  background: var(--primary);
}

.open_sidebar span {
  color: #fff;
  display: flex; /* Ensure icon is aligned vertically */
  align-items: center; /* Align icon vertically within span */
}

.open_sidebar p {
  color: #fff !important;
  margin: 0; /* Remove any default margin to align the text properly */
  line-height: 1; /* Ensure text has the same line height as the icon */
  display: flex;
  align-items: center; /* Align text vertically */
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}
.back {
  color: var(--primary) !important;
  font-weight: 500;
}
.content {
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  box-shadow: 0 2px 4px 0 rgba(170, 171, 175, 0.6);
  display: flex;
  flex-direction: column;
  padding: 22px 24px;
}
@media (max-width: 991px) {
  .content {
    padding: 0 20px;
  }
}
</style>
<style scoped>
.analysis-section {
  align-self: stretch;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 10px rgba(227, 229, 235, 0.6);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  padding: 20px 24px;
}
@media (max-width: 991px) {
  .analysis-section {
    padding: 0 20px;
  }
}
.analysis-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.heading-title {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  line-height: 28px;
}
.tabs {
  color: #0e4485;
}
.tabs-2 {
  color: #383a3e;
}
.actions {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 14px;
}
.action-button {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
}
.copy-button {
  color: #0e4485;
}
.export-button {
  background: #0e4485;
  color: #fff;
}
.comparison-result {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}
.result-header {
  display: flex;
  font-size: 16px;
  font-weight: 600;
  background: #f2f3f3;
  padding: 12px 16px;
}
.result-section,
.result-claim,
.result-defense,
.result-comparison,
.result-comments {
  flex: 1;
  border-right: 1px solid #86888d;
  padding: 9px 16px;
}
.result-comments {
  border-right: none;
}
.result-item {
  display: flex;
  padding: 12px 16px;
  border-bottom: 1px solid #86888d;
}
.item-section,
.item-claim,
.item-defense,
.item-comparison,
.item-comments {
  flex: 1;
  padding: 9px 16px;
  border-right: 1px solid #86888d;
}
.item-comments {
  border-right: none;
}
.dropdown-container {
  display: flex;
  flex-direction: column;
  padding: 10px 8px;
  position: absolute;
  width: 150px;
  background: white;
  border: 1px solid #cacaca;
  top: 50px;
  right: 60px;
  box-shadow: 0px 4px 10px rgba(170, 171, 175, 0.6);
  border-radius: 8px;
}
.dropdown-option {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
}
.export-confirm-button {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  background: #0e4485;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}
table {
  width: 100%;
}

th,
td {
  text-align: left;
  padding: 12px;
  font-size: 14px;
}

thead th {
  background-color: #f5f5f5;
  font-weight: bold;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}
</style>
