<template>
  <div>
    <div class="d-flex justify-content-end">
      <div>
        <button class="open_sidebar me-2" @click="toggleSidebar">
          <span class="material-symbols-rounded icon"
            >keyboard_double_arrow_left</span
          >
          <p>Open Sidebar</p>
        </button>
      </div>
    </div>

    <div class="d-flex justify-content-between">
      <main class="container main-container">
        <section class="content">
          <header class="header">
            <h4 class="title">Welcome to Expert Mode</h4>
            <p class="subtitle">Hi I’m NeXa! how can I help you today?</p>
          </header>
          <form class="search-bar" role="search" @submit.prevent="submitQuery">
            <div
              style="
                display: flex;
                flex-direction: column;

                padding: 20px 20px 0 20px;
              "
            >
              <div class="input-section">
                <section
                  style="
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    gap: 5rem;
                  "
                >
                  <div
                    style="
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      gap: 1rem;
                      align-items: flex-start;
                      justify-content: space-between;
                    "
                  >
                    <textarea
                      ref="textarea"
                      id="question"
                      autocorrect="off"
                      autocomplete="off"
                      name="statement"
                      @input="onInputChange"
                      v-model="legalQuery"
                      class="input-field"
                      :disabled="blockUser"
                      placeholder="Enter your legal query or task here"
                      rows="2"
                      maxlength="3000"
                    ></textarea>

                    <button
                      class="upload-button"
                      tabindex="0"
                      :disabled="blockUser || legalQuery == ''"
                    >
                      <span
                        v-if="!blockUser"
                        class="material-symbols-rounded"
                        :style="{
                          color:
                            legalQuery.length > 0 && selectedLocationId
                              ? 'var(--primary)'
                              : '',
                        }"
                        >send</span
                      >

                      <b-spinner
                        v-else
                        variant="primary"
                        label="Nexa Thinking"
                        class="material-symbols-rounded"
                      />
                    </button>
                  </div>
                </section>
              </div>

              <div style="display: flex; flex-direction: column; gap: 0.75rem">
                <CircularLoader v-if="isFetchIntent" />
                <CardBadge
                  v-if="
                    rephrase.criteria &&
                    legalQuery != '' &&
                    rephrase.question != ''
                  "
                >
                  <Badge @click="changeQuestion">{{ rephrase.question }}</Badge>
                </CardBadge>
              </div>
              <div class="mt-2 p-2" v-if="intent.criteria">
                <IntentButton
                  :isClickable="isClickable"
                  @click="isClickable ? redirectIntent() : ''"
                  :message="intent.message"
                  :useCase="intent.use_case"
                />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: column;

                  padding-top: 1.5rem;
                "
              >
                <div v-if="files.length > 0 && files[2].length > 0">
                  <b-container fluid>
                    <b-row>
                      <b-col
                        v-for="(file, index) in displayedFiles"
                        :key="index"
                        sm="4"
                        class="align-items-center mt-2 mb-1"
                      >
                        <div class="d-flex file-background">
                          <div class="d-flex align-items-center">
                            <span
                              class="material-symbols-rounded badge-icon me-2"
                            >
                              {{
                                file.name.endsWith(".zip")
                                  ? "folder"
                                  : "description"
                              }}
                            </span>
                            <p class="badge-title" style="margin: 0">
                              {{ truncateFileName(file.name) }}
                            </p>
                          </div>
                          <div class="ms-auto d-flex align-items-center me-1">
                            <span class="badge-size mb-0 me-2"
                              >{{
                                (file.file.size / (1024 * 1024)).toFixed(2)
                              }}
                              mb</span
                            >
                            <template v-if="file.uploading">
                              <span>
                                <b-spinner
                                  small
                                  style="width: 1.15rem; height: 1.15rem"
                                  variant="primary"
                                  label="Uploading"
                                ></b-spinner>
                              </span>
                            </template>
                            <template v-else>
                              <span
                                class="material-symbols-rounded close-icon"
                                @click="removeFile(index)"
                                >close</span
                              >
                            </template>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-container>
                </div>
                <div
                  class="d-flex justify-content-center align-items-center mt-1"
                >
                  <button
                    v-if="files[2].length > maxFilesToShow"
                    type="button"
                    @click="toggleShowAll"
                    class="d-flex align-items-center"
                    style="color: #1890ff"
                  >
                    <span class="material-symbols-rounded icon">
                      {{
                        showAll
                          ? "keyboard_double_arrow_up"
                          : "keyboard_double_arrow_down"
                      }}
                    </span>
                    {{ showAll ? "Hide" : "See More" }}
                  </button>
                </div>
                <div
                  class="d-flex footer-text ps-2 mt-3"
                  v-if="files.length > 0 && files[2].length > 0"
                >
                  <span class="material-symbols-rounded" style="font-size: 18px"
                    >info</span
                  >
                  <p class="footer-text ps-1">
                    Your files will be securely stored in NeXa, accessible only
                    to you and your workspace admins, and you can delete them at
                    any time.
                  </p>
                </div>
              </div>
            </div>

            <b-tooltip target="tooltip_attach" triggers="hover">
              Upload any documents and let our AI show you what it can do!
            </b-tooltip>

            <footer class="search-bar-footer">
              <div class="d-flex">
                <button
                  class="attach-button d-flex align-items-center"
                  id="tooltip_attach"
                  type="button"
                  @click="showAttachModal"
                  :disabled="blockUser"
                  :style="{
                    color:
                      files.length && files[2].length > 0
                        ? 'var(--primary)'
                        : '',
                  }"
                >
                  <span
                    class="material-symbols-rounded icon-small me-1"
                    :style="{
                      color:
                        files.length && files[2].length > 0
                          ? 'var(--primary)'
                          : '',
                    }"
                    >attach_file</span
                  >
                  Attach
                </button>
                <span class="divider"></span>
                <button
                  class="country-button d-flex align-items-center"
                  type="button"
                  @click="toggleCountry"
                  :disabled="blockUser"
                  :style="{ color: selectedLocationId ? 'var(--primary)' : '' }"
                >
                  <span
                    class="material-symbols-rounded icon-small me-1"
                    :style="{
                      color: selectedLocationId ? 'var(--primary)' : '',
                    }"
                    >arrow_drop_down</span
                  >
                  <img
                    v-if="selectedLocationId"
                    :src="countryImages[selectedLocationId]"
                    alt="Country Flag"
                    class="country-flag"
                  />
                  {{ selectedLocationId ? selectedLocationId : "Country" }}
                </button>
              </div>
              <div class="d-flex align-items-center">
                <span class="char-count">{{ charCount }} / 3000</span>
              </div>
            </footer>
            <div
              v-if="isCountry"
              class="country-option"
              v-clickaway="toggleCountry"
            >
              <ul>
                <li
                  v-for="(flag, countryCode) in countryImages"
                  :key="countryCode"
                  @click="selectCountry(countryCode)"
                  class="countryOption"
                >
                  <img :src="flag" alt="Country Flag" class="country-flag" />
                  <span>{{ countryCode }}</span>
                </li>
              </ul>
            </div>

            <b-modal id="attachfile" hide-footer hide-header size="lg">
              <div class="d-flex justify-content-between">
                <div>
                  <h5>Upload Files</h5>
                </div>
                <div>
                  <span
                    class="material-symbols-rounded modal-icon"
                    @click="hideAttachModal"
                    >close</span
                  >
                </div>
              </div>
              <div>
                <!-- Add 'zip' for accepting zip files -->
                <FileInput
                  style="flex-grow: 1"
                  :accept="['pdf', 'docx', 'png', 'jpg', 'jpeg']"
                  @input-files="$set(files, 0, $event)"
                />
                <div class="mt-2 d-flex">
                  <span class="material-symbols-rounded mt-1 help-icon"
                    >help</span
                  >
                  <p class="help-description">
                    If you have multiple files to upload, we recommend zipping
                    them into one file to make the process quicker and easier.
                  </p>
                </div>
                <!-- Add 'zip' for accepting zip files -->
                <FileProcess
                  style="width: 100%; max-height: 200px; overflow-y: auto"
                  :accept="['pdf', 'docx', 'png', 'jpg', 'jpeg']"
                  :files="files[0]"
                  @output-files="$set(files, 1, $event)"
                />
              </div>
              <footer>
                <button class="upload" @click="uploadFile">Upload</button>
              </footer>
            </b-modal>
          </form>
          <section
            id="quick-questions"
            class="use-cases"
            v-if="files.length > 0 && files[2].length > 0"
          >
            <div class="use-case-section">
              <div class="use-case-header">
                <h4>Quick questions</h4>
                <p>Here’s what NeXa can do with your uploaded files:</p>
              </div>
            </div>
            <section class="use-case-grid">
              <article
                class="use-case-cards cursor-pointer"
                v-for="(useCase, index) in filteredPrompts"
                :key="index"
                @click="setQuery(useCase.query)"
              >
                <b>{{ useCase.title }}</b>
                <p class="use-case-description">{{ useCase.description }}</p>
              </article>
            </section>
            <div class="d-flex justify-content-center mt-3">
              <button
                @click="togglePrompts"
                class="d-flex align-items-center"
                style="color: var(--primary)"
              >
                <span class="material-symbols-rounded me-1">{{
                  showAllPrompts
                    ? "keyboard_double_arrow_up"
                    : "keyboard_double_arrow_down"
                }}</span>
                {{ showAllPrompts ? "Hide" : "Load more" }}
              </button>
            </div>
          </section>
          <section class="use-cases">
            <div class="use-case-section">
              <div class="use-case-header">
                <h4>Use Cases:</h4>
                <p>Try out NeXa's use cases below</p>
              </div>
              <!-- <div class="filter-search">
                <button class="filter-button">
                  <span class="material-symbols-rounded icon-filter">tune</span>
                  Filter
                </button>
                <div class="search-container">
                  <span class="material-symbols-rounded icon-search"
                    >search</span
                  >
                  <input
                    v-model="searchUseCase"
                    type="text"
                    placeholder="Search"
                    class="search-input"
                  />
                </div>
              </div> -->
            </div>
            <section class="use-case-grid">
              <article
                class="use-case-card"
                v-for="(useCase, index) in filteredUseCases"
                :key="index"
              >
                <h4 class="use-case-title">{{ useCase.title }}</h4>
                <p class="use-case-description">{{ useCase.description }}</p>
                <button class="create-button" @click.once="useCase.action">
                  Create
                </button>
              </article>
            </section>
          </section>
        </section>
      </main>

      <sidebar
        v-clickaway="toggleSidebar"
        v-if="sidebarCollapsed"
        @toggle-sidebar="toggleSidebar"
      ></sidebar>
    </div>
  </div>
</template>
<script>
import countryImages from "./../../../../src/assets/country/index";
import FileInput from "./../../../components/input/FileInput.vue";
import FileProcess from "./../../../components/input/FileProcess.vue";
import sidebar from "@/Pages/Mode/sidebar.vue";
import nexa from "@/store/nexa.js";
import IntentButton from "@/components/input/IntentButton.vue";
import Badge from "@/components/input/Badge.vue";
import CardBadge from "@/components/input/CardBadge.vue";
import usecase from "@/store/usecase.js";
import CircularLoader from "@/components/misc/CircularLoader.vue";
export default {
  components: {
    FileInput,
    FileProcess,
    sidebar,
    IntentButton,
    Badge,
    CardBadge,
    CircularLoader,
  },
  data() {
    return {
      isFetchIntent: false,
      sidebarCollapsed: false,
      lawbotVisible: false,
      isCountry: false,
      showAllPrompts: false,
      intent: {
        criteria: false,
        message: "",
        use_case: "general",
      },
      rephrase: {
        criteria: false,
        question: "",
      },
      countryImages,
      files: [[], [], []],
      legalQuery: "",
      recommendQuestion: "",
      debounceTimeout: null,
      blockUser: false,
      questions: [],
      questionsPrompts: [],
      qna: [],
      selectedLocationId: "USA",
      maxFilesToShow: 9,
      showAll: false,
      prompts: [
        {
          title: "What are the key takeaways from this document?",
          query: "What are the key takeaways from this document?",
          description:
            "Get a summary of the important points and critical information.",
        },
        {
          title: "Are there any potential legal issues in this contract?",
          query: "Are there any potential legal issues in this contract?",
          description: "Identify any legal risks or unclear terms.",
        },
        {
          title: "What are the main clauses in this contract?",
          query: "What are the main clauses in this contract?",
          description:
            "Highlight the key clauses, such as confidentiality or non-compete clauses.",
        },
        {
          title: "Is there a breach of contract here?",
          query: "Is there a breach of contract here?",
          description:
            "Check if there are any violations based on the terms provided.",
        },
        {
          title: "What are the main clauses in this contract?",
          query: "What are the main clauses in this contract?",
          description:
            "Highlight the key clauses, such as confidentiality or non-compete clauses.",
        },
        {
          title: "Can you draft a legal memo based on this file?",
          query: "Can you draft a legal memo based on this file?",
          description:
            "Generate a legal memo that includes analysis and recommendations.",
        },
        {
          title: "Are there any missing or unusual clauses?",
          query: "Are there any missing or unusual clauses?",
          description:
            "Detect any critical clauses that might be missing or out of the ordinary.",
        },
        {
          title: "What are the legal precedents related to this case?",
          query: "What are the legal precedents related to this case?",
          description:
            "Find relevant case law or past rulings connected to the content.",
        },
        {
          title: "Can you compare this contract with another?",
          query: "Can you compare this contract with another?",
          description:
            "Spot the differences and similarities between this and another uploaded contract.",
        },
        {
          title: "What are the possible outcomes if this goes to court?",
          query: "What are the possible outcomes if this goes to court?",
          description: "Provide potential scenarios based on the case’s facts.",
        },
      ],
      searchUseCase: "",
      countryMapping: {
        AUS: "Australia",
        USA: "USA",
        CAN: "Canada",
        NZ: "New Zealand",
        SG: "Singapore",
        MY: "Malaysia",
        UK: "United Kingdom",
      },
      useCaseList: {
        deep_research: {
          path: "DeepResearchResult",
          type: "deep_research",
        },
        build_an_argument: {
          path: "BuildArgumentResult",
          type: "build_argument",
        },
        draft_a_legal_memo: {
          path: "LegalMemo",
        },
        find_precedents: {
          path: "FindPrecedent",
        },
      },

      useCases: [
        {
          title: "Build an Argument",
          description:
            "Create strong legal arguments by analyzing relevant case law, statutes, and principles",
          action: this.BuildArgument,
        },
        {
          title: "Compare Jurisdictions",
          description:
            "Compare laws and regulations across multiple jurisdictions efficiently",
          action: this.CompareJuri,
        },
        {
          title: "Deep Research",
          description:
            "Find answers with case laws, legislation, and secondary sources",
          action: this.DeepResearch,
        },
        {
          title: "Documents Insight",
          description:
            "Uncover essential details, enhancing your understanding of complex materials",
          action: this.DocumentInsight,
        },
        {
          title: "Draft a Legal Memo",
          description:
            "Generate memos that provide legal analysis, outlining key issues and recommendations.",
          action: this.LegalMemo,
        },
        {
          title: "Find Precedents",
          description:
            "Look for precedents related to your specific case with precision and relevance.",
          action: this.FindPrecedent,
        },
        {
          title: "Draft a Contract",
          description:
            "Instantly draft contracts tailored to your unique requirements",
          action: this.DraftContract,
        },
        {
          title: "Summarize a Case",
          description:
            "Get quick insights by summarizing your case with speed and accuracy.",
          action: this.SummarizeCase,
        },
        {
          title: "Contract Due Diligence",
          description:
            "Efficiently identifies legal risks and ensures compliance with regulations while comparing key clauses.",
          action: this.ContractDiligence,
        },
        {
          title: "Analyze Pleadings",
          description:
            "In-depth analysis of pleadings helps you identify key facts, arguments, and context.",
          action: this.AnalyzePleadings,
        },
      ],
    };
  },
  computed: {
    isClickable() {
      if (
        this.intent.use_case == "find_precedents" ||
        this.intent.use_case == "draft_a_legal_memo"
      ) {
        return true;
      } else {
        return false;
      }
    },
    charCount() {
      return this.legalQuery.length;
    },
    filteredPrompts() {
      if (this.showAllPrompts) {
        return this.prompts;
      } else {
        const filtered = this.prompts.slice(0, 4);
        return filtered;
      }
    },
    filteredUseCases() {
      if (this.searchUseCase.length > 0) {
        const filtered = this.useCases.filter((item) =>
          item.title.toLowerCase().includes(this.searchUseCase.toLowerCase())
        );
        return filtered;
      } else {
        return this.useCases;
      }
    },
    displayedFiles() {
      return this.showAll
        ? this.files[2]
        : this.files[2].slice(0, this.maxFilesToShow);
    },
  },
  created() {
    nexa
      .AllResult()
      .then((response) => {
        const DATA = response.data.data.data;
        this.questions = DATA.map((item) => {
          return {
            id: item.id,
            title: item.type,
            fileCount: item.file_uploaded_count,
            createdAt: item.created_at,
          };
        });
        // console.log(this.questions);
        this.total = response.data.data.total;
      })
      .catch((error) => {
        console.error("Error fetching AllResult:", error);
      });
  },
  methods: {
    adjustHeight() {
      this.$refs.textarea.style.height = "auto";
      this.$refs.textarea.style.height =
        this.$refs.textarea.scrollHeight + "px";
    },

    changeQuestion() {
      this.legalQuery = this.rephrase.question;
      this.rephrase.question = "";
    },
    redirectIntent() {
      const payload = {
        selectedLocationId: this.selectedLocationId,
        files: this.files,
        legalQuery: this.legalQuery,
      };
      const target = this.useCaseList[this.intent.use_case].path;
      this.$store.dispatch("updateStore", payload).then(() => {
        this.$router.push({ name: target });
      });
    },
    onInputChange() {
      this.adjustHeight();
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        if (this.legalQuery.trim() === "") {
          this.intent = {
            criteria: false,
            message: "",
            use_case: "general",
          };
          this.rephrase = {
            criteria: false,
            question: "",
          };
          return;
        } else {
          this.getIntent();
        }
      }, 1000);
    },
    async getIntent() {
      if (this.legalQuery.trim() === "") return;
      this.isFetchIntent = true;
      this.intent = {
        criteria: false,
        message: "",
        use_case: "general",
      };
      this.rephrase = {
        criteria: false,
        question: "",
      };

      try {
        const response = await nexa.getIntent({
          question: this.legalQuery,
          jurisdiction: this.countryMapping[this.selectedLocationId],
        });

        const intent = response.data.data.intent.use_case;
        const rephrase = response.data.data.intent.rephrase;
        this.rephrase.criteria = rephrase.criteria;
        this.rephrase.question = rephrase.question;
        this.intent.criteria = intent.criteria;
        this.intent.message = intent.message;
        this.intent.use_case = intent.use_case;
      } catch (err) {
        this.$toast.error("Error fetching intent");
        this.isFetchIntent = false;
      } finally {
        this.isFetchIntent = false;
      }
    },
    truncateFileName(name) {
      return name.length > 25 ? name.substring(0, 20) + "..." : name;
    },
    toggleShowAll() {
      this.showAll = !this.showAll;
    },
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    formatDateTime(date) {
      return new Date(date).toISOString();
    },
    togglePrompts() {
      this.showAllPrompts = !this.showAllPrompts;
    },
    getTimeDifference(date) {
      const now = new Date();
      const createdAt = new Date(date);
      const diffInMinutes = Math.floor((now - createdAt) / (1000 * 60));

      if (diffInMinutes < 60) {
        return `${diffInMinutes} m`;
      } else {
        const diffInHours = Math.floor(diffInMinutes / 60);
        return `${diffInHours} h`;
      }
    },
    selectCountry(countryCode) {
      this.selectedLocationId = countryCode;
      this.isCountry = false;
      this.getIntent();
    },
    NexaResult(id) {
      this.$router.push({ name: "ChatNeXa", params: { id } });
    },
    lawbot() {
      this.$router.push({ name: "ChatNeXa" });
    },
    setQuery(query) {
      this.legalQuery = query;
      this.autoQuery();
    },
    autoQuery() {
      const formData = new FormData();
      formData.append("language", "en");
      formData.append("location_id", 1);
      formData.append("question", this.legalQuery);
      if (this.files[2] && this.files[2].length > 0) {
        this.files[2].forEach((file, index) => {
          console.log(file);
          formData.append(`question_doc[${index}]`, file.file);
        });
      }
      this.blockUser = true;
      nexa
        .nexaAsk(formData)
        .then((response) => {
          console.log(response);
          const responseId = response.data.data.id;
          this.$router.push({
            name: "ChatNeXa",
            params: { id: responseId },
          });
          if (response.data.completedPerCentage === 100) {
            this.blockUser = false;
          }
        })
        .catch((error) => {
          console.error("Error submitting query:", error);
          this.blockUser = false;
        });
    },
    async submitQuery() {
      const formData = new FormData();

      const country = this.countryMapping[this.selectedLocationId];
      const locations = localStorage.getItem("locations")
        ? JSON.parse(localStorage.getItem("locations"))
        : [];
      const foundLocation = locations.find((x) => x.title === country);

      formData.append("language", "en");
      formData.append("location_id", foundLocation.id);
      formData.append("question", this.legalQuery);
      if (this.files[2] && this.files[2].length > 0) {
        this.files[2].forEach((file, index) => {
          formData.append(`question_doc[${index}]`, file.file);
        });
      }

      this.blockUser = true;

      if (
        this.intent.use_case == "build_an_argument" ||
        this.intent.use_case == "deep_research"
      ) {
        try {
          const jurisdiction = {};
          const country = this.countryMapping[this.selectedLocationId];
          jurisdiction[country] = ["all"];
          formData.append("jurisdiction", JSON.stringify(jurisdiction));
          formData.append("type", this.useCaseList[this.intent.use_case].type);

          const response = await usecase.CreateUsecase(formData);
          if (response.data) {
            this.$toast.success("Successful");
            this.$router.push({
              name: this.useCaseList[this.intent.use_case].path,
              params: { id: response.data.data.id },
            });
          }
        } catch (error) {
          console.error(error);
          this.$toast.error("Failed to generate deep research");
        }
      } else if (this.intent.use_case == "general") {
        const jurisdiction = {};
        const country = this.countryMapping[this.selectedLocationId];
        jurisdiction[country] = ["all"];
        formData.append("jurisdiction", JSON.stringify(jurisdiction));
        nexa
          .nexaAsk(formData)
          .then((response) => {
            const responseId = response.data.data.id;
            this.$router.push({ name: "ChatNeXa", params: { id: responseId } });
            if (response.data.completedPerCentage === 100) {
              this.blockUser = false;
            }
          })
          .catch((error) => {
            console.error("Error submitting query:", error);
            this.blockUser = false;
          });
      } else {
        if (this.useCaseList[this.intent.use_case]) {
          this.redirectIntent();
        }
        this.blockUser = false;
      }
    },

    toggleCountry() {
      this.isCountry = !this.isCountry;
    },
    showAttachModal() {
      this.$bvModal.show("attachfile");
    },
    hideAttachModal() {
      this.$bvModal.hide("attachfile");
    },
    uploadFile() {
      this.$set(this.files, 2, this.files[1]);
      this.$set(this.files, 0, []);
      this.$set(this.files, 1, []);

      this.displayedFiles.forEach((file) => {
        this.$set(file, "uploading", true);
        const uploadTime = Math.min(
          10000,
          Math.max(1000, (file.file.size / (1024 * 1024)) * 1000)
        ); // Scale upload time

        setTimeout(() => {
          this.$set(file, "uploading", false);
        }, uploadTime + 1000);
      });

      this.hideAttachModal();
      setTimeout(() => {
        const container = document.getElementById("quick-questions");
        container.scrollIntoView({ behavior: "smooth" });
      }, 250);
    },
    removeFile(index) {
      this.files[2].splice(index, 1);
    },
    AnalyzePleadings() {
      this.$router.push({ name: "AnalyzePleadings" });
    },
    DeepResearch() {
      this.$router.push({ name: "DeepResearch" });
    },
    BuildArgument() {
      this.$router.push({ name: "BuildArgument" });
    },
    LegalMemo() {
      this.$router.push({ name: "LegalMemo" });
    },
    DraftContract() {
      this.$router.push({ name: "DraftContract" });
    },
    DocumentInsight() {
      this.$router.push({ name: "DocumentInsight" });
    },
    SummarizeCase() {
      this.$router.push({ name: "SummarizeCase" });
    },
    FindPrecedent() {
      this.$router.push({ name: "FindPrecedent" });
    },
    CompareJuri() {
      this.$router.push({ name: "CompareJuri" });
    },
    ContractDiligence() {
      this.$router.push({ name: "ContractDiligence" });
    },
  },
  mounted() {
    this.adjustHeight();
  },
};
</script>
<style scoped>
.input-section {
  display: flex;
  align-items: flex-start;
  position: relative;
  /* border: 1px solid #f2f3f3; */
  background-color: #fff;

  margin-bottom: 16px;
  border-radius: 8px 8px 0px 0px;
}

.input-label {
  color: #383a3e;
  font-size: 16px;
  font-weight: 500;
  display: block;
  margin-bottom: 8px;
}

.input-field::placeholder {
  color: #86888d;
  opacity: 1;
}

.character-count {
  display: block;
  text-align: right;
  font-size: 14px;
  color: #86888d;
  margin-top: 8px;
}

.badge {
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 2px 8px;
  border-radius: 8px;
  background: var(--Neutral-Interface-Grey, #fafbfc);
  color: grey;
}

.badge-icon {
  color: var(--primary);
}

.badge-title {
  color: var(--Neutral-Black, #383a3e);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.badge-size {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}

.close-icon {
  font-size: 18px;
  color: var(--status-red);
  cursor: pointer;
}

.file-background {
  background: #fafbfc;
  border-radius: 8px;
}
</style>
<style scoped>
.main-container {
  width: 100%;
  position: relative;
}

.overlay-container {
  width: 25%;
  position: absolute;
  top: 8.7vh;
  right: 0;
  z-index: 1000;
  height: 100vh;
}

.breadcrumb {
  display: flex;
  align-items: center;

  span {
    font-size: 14px;
    color: var(--dark-grey) !important;
  }
}

.open_sidebar {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--primary);
}

.open_sidebar span {
  color: #fff;
  display: flex;
  /* Ensure icon is aligned vertically */
  align-items: center;
  /* Align icon vertically within span */
}

.open_sidebar p {
  color: #fff !important;
  margin: 0;
  /* Remove any default margin to align the text properly */
  line-height: 1;
  /* Ensure text has the same line height as the icon */
  display: flex;
  align-items: center;
  /* Align text vertically */
}

.container {
  background: var(--Neutral-Interface-Grey, #fafbfc);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 70px 14px;
}

@media (max-width: 991px) {
  .container {
    padding: 0 20px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1076px;
}

@media (max-width: 991px) {
  .content {
    max-width: 100%;
    padding-right: 20px;
  }
}

.header {
  text-align: center;
  max-width: 100%;
}

.title {
  color: var(--Neutral-Black, #383a3e);
  font-family: Poppins, sans-serif;
  font-size: 32px !important;
  font-weight: 600;
}

.subtitle {
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.search-bar {
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  box-shadow: 0px 4px 10px 0px rgba(227, 229, 235, 0.6);
  margin-top: 24px;
  width: 100%;
}

.input-group {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  position: relative;
}

.input-field {
  color: var(--Neutral-Mid-Grey, #000);
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  border: none;
  outline: none;
  width: 95%;
  resize: none;

  max-height: 250px;
}

.upload-button {
  border-radius: 4px;
  overflow: hidden;
  padding: 4px;
  padding-right: 10px;
  right: 16px;
  position: absolute;
}

.upload-button img {
  width: 20px;
  object-fit: contain;
}

.tooltip {
  align-self: start;
  border-radius: 4px;
  color: var(--Neutral-White, #fff);
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 6px 8px;
  margin-top: -14px;
}

.tooltip-arrow {
  position: absolute;
  left: 16px;
  bottom: 8px;
  width: 11px;
  height: 6px;
  background: var(--Neutral-Black, #383a3e);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.search-bar-footer {
  background: #fafbfc;
  border-top: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  display: flex;
  justify-content: space-between;
  padding: 6px 20px;
  margin-top: -16px;
  border-radius: 0px 0px 8px 8px;
}

.attach-button,
.country-button {
  color: #86888d;
  font-size: 14px;
  font-weight: 500;
}

.attach-button:hover,
.country-button:hover {
  color: var(--primary);
  > span {
    color: var(--primary);
  }
}

.char-count {
  color: var(--Neutral-Mid-Grey, #d1d2d5);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.prompts {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.prompt-button {
  background: var(--Neutral-Light-Grey, #f2f3f3);
  border: 1px solid var(--Neutral-Mid-Grey, #d1d2d5);
  border-radius: 16px !important;
  box-shadow: 0px 4px 10px 0px rgba(227, 229, 235, 0.6);
  color: var(--Neutral-Black, #383a3e);
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  padding: 6px 12px;
}

.load-more-button {
  background: none;
  color: var(--Primary-Blue, #0e4485);
  font-size: 14px;
  font-weight: 500;
  padding: 2px 8px;
}

.use-cases {
  margin-top: 35px;
}

.use-cases-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.use-cases-title {
  max-width: 218px;
}

.use-cases-controls {
  display: flex;
  gap: 20px;
}

.filter-button {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 11px 10px;
  border-radius: 4px;
}

.search-container {
  padding: 8px 8px;
  width: 336px;
}

.search-container {
  display: flex;
  align-items: center;
  gap: 4px;
  background: var(--Neutral-White, #fff);
  border: 1px solid var(--Neutral-Mid-Grey, #d1d2d5);
  border-radius: 4px;
}

.search-container {
  padding: 8px 8px;
}

.search-field {
  border: none;
  outline: none;
}

.use-case-grid {
  margin-top: 11px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
}

.use-case-cards {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px 0px rgba(227, 229, 235, 0.6);
  padding: 24px;
  flex: 1 1 350px;
}

.use-case-card {
  grid-column: span 1 / span 1;
  height: 212px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px 0px rgba(227, 229, 235, 0.6);
  padding: 24px;
  /* flex: 1 1 240px; */
  flex: 1 1 300px;
  outline: 1px solid transparent;
}

.use-case-card:hover {
  outline: 1px solid var(--primary);
}

.use-case-title {
  color: var(--Neutral-Black, #383a3e);
  font-size: 20px;
  font-weight: 600;
}

.use-case-description {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 16px;
}

.create-button {
  display: none;
  /* width: 240px; */
  width: 300px;
  align-self: end;
  background: var(--Primary-Blue, #0e4485);
  border-radius: 4px;
  color: var(--Neutral-White, #fff);
  font-size: 16px;
  font-weight: 500;
  padding: 4px 16px;
  margin-top: 16px;
}

.use-case-card:hover .create-button {
  display: block;
  width: 100%;
}

.decorative-image {
  width: 20px;
  object-fit: contain;
  margin-top: 204px;
}

@media (max-width: 991px) {
  .decorative-image {
    margin-top: 40px;
  }
}
</style>
<style scoped>
.sidebar {
  background: var(--Neutral-White, #fff);
  box-shadow: 0px 4px 10px rgba(170, 171, 175, 0.6);
  display: flex;
  max-width: 300px;
  flex-direction: column;
  overflow: hidden;
  font-family: Poppins, sans-serif;
  padding: 24px 12px;
}

.sidebar-toggle,
.new-chat-button,
.show-all-button {
  border-radius: 4px;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 500;
  justify-content: flex-start;
  padding: 4px 16px;
  background: none;
  border: none;
}

.show-all-button {
  justify-content: center !important;
  color: var(--Primary-Blue, #0e4485) !important;
}

.show-all-button .icon-small {
  color: var(--Primary-Blue, #0e4485) !important;
}

button.sidebar-toggle {
  color: var(--Primary-Blue, #0e4485);
}

.icon,
.search-icon {
  width: 24px;
  height: 24px;
}

.icon-small {
  color: #86888d;
  margin: auto;
  font-size: 18px;
}

.icon-filter {
  margin: auto;
  font-size: 18px;
}

.sidebar-header {
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.new-chat-button {
  background-color: var(--Primary-Blue, #0e4485);
  color: var(--Neutral-White, #fff);
  padding: 6px 16px;
}

.search-form {
  margin-top: 11px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: var(--Neutral-Light-Grey, #f2f3f3);
  padding: 6px 12px 6px 16px;
  gap: 3px;
  width: 100%;
}

.search-input {
  flex: 1;
  border: none;
  background: none;
  outline: none;
  font-size: 16px;
  color: var(--Neutral-Dark-Grey, #86888d);
}

.search-icon {
  width: 24px;
  height: 24px;
}

.item-list {
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.separator {
  border: 1px solid var(--mid-grey);
  width: 100%;
  margin: 5px 0px 10px;
}

.recent-logs-title {
  margin: 0px;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: var(--Neutral-Dark-Grey, #86888d) !important;
}

.conversation-card,
.alt-card {
  border-radius: 8px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.conversation-card:hover {
  background: #f2f3f3;
}

.alt-card {
  background-color: var(--Neutral-Light-Grey, #f2f3f3);
}

.conversation-title {
  font-size: 14px !important;
  color: var(--Neutral-Black, #383a3e) !important;
  font-weight: 400 !important;
  margin: 0;
}

.conversation-details {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: var(--Neutral-Dark-Grey, #86888d);
}

.file-upload-info {
  flex: 1;
}

.sidebar-image {
  position: absolute;
  right: 77px;
  bottom: 97px;
  width: 20px;
  height: 21px;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.upload-button {
  span {
    color: #86888d;
  }
}

input::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #d1d2d5;
}

.use-case-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
}

/* Styling the header text */
.use-case-header h2 {
  font-size: 1.5rem;
  /* Adjust the size */
  font-weight: 600;
  /* Semi-bold for emphasis */
  color: #333;
  /* Darker color for readability */
  margin: 0;
}

.use-case-header p {
  color: #888888;
  /* Light gray for the subtext */
  font-size: 0.875rem;
  /* Smaller font size for description */
  margin: 0;
  margin-top: 4px;
  /* Adds slight spacing below the heading */
}

/* Styling the filter and search area */
.filter-search {
  display: flex;
  gap: 10px;
  align-items: center;
}

.filter-button {
  font-size: 0.875rem;
  color: #333;
  /* Dark text */
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filter-button:hover {
  background-color: #f0f0f0;
}

.search-input::placeholder {
  color: #888888;
}

.icon-search {
  color: #888888;
}

.country-option {
  left: 18%;
  width: 100px !important;
  background: #fafbfc;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: absolute;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.country-flag {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.countryOption {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px; /* Add padding to the inner elements */
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s ease;

  span {
    color: #86888d;
    font-size: 14px;
  }
}
.countryOption:hover {
  background-color: #f0f0f0; /* Change to your desired hover color */
}

.divider {
  display: inline-block;
  margin: 0px 8px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}

.modal-icon {
  width: 24px;
  height: 24px;
  color: #383a3e;
  cursor: pointer;
}

.upload {
  background: var(--primary);
  border-radius: 6px;
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
  float: right;
}

.swift {
  border-radius: 4px;
  background: rgba(24, 144, 255, 0.1);
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.toggle {
  display: flex;
  width: 40px;
}

.switch {
  flex-grow: 1;
  background: var(--Neutral-Mid-Grey, #d1d2d5);
  border-radius: 100px;
  padding: 2px;
  display: flex;
  align-items: center;
}

.button {
  width: 16px;
  height: 16px;
  background: var(--Neutral-White, #fff);
  border-radius: 100px;
  box-shadow: 0 2px 4px rgba(39, 39, 39, 0.1);
}

.file-list {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.footer-text {
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 20px;
}

.overflow-recent {
  max-height: 30vh;
  overflow: auto;
}

.help-icon {
  font-size: 18px;
  color: #86888d;
}

.help-description {
  font-size: 12px;
  font-weight: 400;
  margin: 0px;
  padding-left: 5px;
  color: #86888d !important;
}
</style>
